import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

function SetContact (props) {

  const {
    value,
    onChange,
    tel_required,
    address_1_required,
    address_2_required,
    address_cp_required,
    address_city_required,
    address_country_required,
    hide_tel,
    hide_address_1,
    hide_address_2,
    hide_address_cp,
    hide_address_city,
    hide_address_country,
  } = props

  return (

    <Grid container spacing={2}>

      { !hide_tel && (
      <Grid item xs={12}>
        <MyFormControl>
          <TextField
            required={tel_required}
            id="tel"
            label="Téléphone"
            type="tel"
            value={value && value.tel ? value.tel : ''}
            onChange={ (e) => onChange({...value, ...{tel: e.target.value}}) }
            autoComplete="tel"
          />
        </MyFormControl>
      </Grid>
      ) }

      { !hide_address_1 && (
      <Grid item xs={6}>
        <MyFormControl>
          <TextField
            required={address_1_required}
            id="address_1"
            label="Adresse"
            type="text"
            value={value && value.address_1 ? value.address_1 : ''}
            onChange={ (e) => onChange({...value, ...{address_1: e.target.value}}) }
            autoComplete="address-line1"
          />
        </MyFormControl>
      </Grid>
      ) }

      { !hide_address_2 && (
      <Grid item xs={6}>
        <MyFormControl>
          <TextField
            required={address_2_required}
            id="address_2"
            label="Complément d'adresse"
            type="text"
            value={value && value.address_2 ? value.address_2 : ''}
            onChange={ (e) => onChange({...value, ...{address_2: e.target.value}}) }
            autoComplete="address-line2"
          />
        </MyFormControl>
      </Grid>
      ) }

      { !hide_address_cp && (
      <Grid item xs={6}>
        <MyFormControl>
          <TextField
            required={address_cp_required}
            id="address_cp"
            label="Code postal"
            type="text"
            value={value && value.address_cp ? value.address_cp : ''}
            onChange={ (e) => onChange({...value, ...{address_cp: e.target.value}}) }
            autoComplete="postal-code"
          />
        </MyFormControl>
      </Grid>
      ) }

      { !hide_address_city && (
      <Grid item xs={6}>
        <MyFormControl>
          <TextField
            required={address_city_required}
            id="address_city"
            label="Ville"
            type="text"
            value={value && value.address_city ? value.address_city : ''}
            onChange={ (e) => onChange({...value, ...{address_city: e.target.value}}) }
            autoComplete="address-level2"
          />
        </MyFormControl>
      </Grid>
      ) }

      { !hide_address_country && (
      <Grid item xs={12}>
        <MyFormControl>
          <TextField
            required={address_country_required}
            id="address_country"
            label="Pays"
            type="text"
            value={value && value.address_country ? value.address_country : ''}
            onChange={ (e) => onChange({...value, ...{address_country: e.target.value}}) }
            autoComplete="country-name"
          />
        </MyFormControl>
      </Grid>
      ) }

    </Grid>
  )
}

SetContact.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  hide_tel: PropTypes.bool,
  hide_address_1: PropTypes.bool,
  hide_address_2: PropTypes.bool,
  hide_address_cp: PropTypes.bool,
  hide_address_city: PropTypes.bool,
  hide_address_country: PropTypes.bool,
  tel_required: PropTypes.bool,
  address_1_required: PropTypes.bool,
  address_2_required: PropTypes.bool,
  address_cp_required: PropTypes.bool,
  address_city_required: PropTypes.bool,
  address_country_required: PropTypes.bool
}

SetContact.defaultProps = {
  hide_tel: false,
  hide_address_1: false,
  hide_address_2: false,
  hide_address_cp: false,
  hide_address_city: false,
  hide_address_country: false,
  tel_required: true,
  address_1_required: true,
  address_2_required: false,
  address_cp_required: true,
  address_city_required: true,
  address_country_required: true,
}

export default SetContact
