import Notifications_API from './api/notifications'

/****************************************************
 Interface avec l'API relative aux notifications
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
   STATE_INITIAL: 'initial', // projet en préparation

  STATE_SCHEDULED: 'scheduled', // notification programmée
	STATE_UNREAD: 'unread', // notification non-lu
	STATE_READ: 'read', // notification lu
	STATE_ERROR: 'error', // notification en erreur

	TOPIC_GENERAL: 'general',
	TOPIC_INVITATION: 'invitation',
	TOPIC_DOCUMENT: 'document',
	TOPIC_SUPPORT: 'support',
	TOPIC_MONITORING: 'monitoring',
	
	LEVEL_HIGH: 'high',
	LEVEL_NORMAL: 'normal',
	LEVEL_LOW: 'low',

  /**************** notifications ************************************/
  /**
   * Récupère les notifications du serveur
   * @return Promise(data, notices) : les notifications
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Notifications_API.getAll(args).then(({data, notices}) => {
        console.log('Notifications récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une notification du serveur
   * @return Promise(data, notices) : la notification
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Notifications_API.get(id, args).then(({data, notices}) => {
        console.log('Notification récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvelle notification
   * @return Promise(data, notices) : l'identifiant de la notification créée
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Notifications_API.create(data).then(({data, notices}) => {
        console.log('Notification créée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé une nouvelle notification lorsqu'un planning de chantier a été modifié
   * @return Promise(data, notices) : true
   */
  create_for_project_planning_updated (data) {
    return new Promise((resolve, reject) => {
      Notifications_API.create_for_project_planning_updated(data).then(({data, notices}) => {
        console.log('Notifications créées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une notification
   * @return Promise(data, notices) : l'identifiant de la notification mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Notifications_API.update(id, data).then(({data, notices}) => {
        console.log('Notification mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une notification
   * @return Promise(id, data, notices) : l'identifiant de la notification supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Notifications_API.delete(id).then(({data, notices}) => {
        console.log('Notification supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },

  /**************** project state ************************************/

  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_SCHEDULED: {
        return 'programmé';
      }
      case this.STATE_UNREAD: {
        return 'non lu';
      }
      case this.STATE_READ: {
        return 'lu';
      }
      case this.STATE_ERROR: {
        return 'erreur';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du topic
   * @return string
   */
  getTopicLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.TOPIC_GENERAL: {
        return 'général';
      }
      case this.TOPIC_INVITATION: {
        return 'invitation';
      }
      case this.TOPIC_DOCUMENT: {
        return 'document';
      }
      case this.TOPIC_SUPPORT: {
        return 'SAV';
      }
      case this.TOPIC_MONITORING: {
        return 'suivi';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du niveau
   * @return string
   */
  getLevelLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.LEVEL_HIGH: {
        return 'haute';
      }
      case this.LEVEL_NORMAL: {
        return 'normale';
      }
      case this.LEVEL_LOW: {
        return 'basse';
      }
      default: {
        return slug;
      }
    }
  },
};
export default exportedObject;
