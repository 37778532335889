import PropTypes from 'prop-types'

import EditDocumentAttachment from './../edit/EditDocumentAttachment'
import Dialog from './Dialog'

function DialogEditDocumentAttachment (props) {

  const { id, open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={id ? "Modifier un fichier" : "Nouveau fichier"}
    >
      <EditDocumentAttachment
        fixedCtrl={true}
        id={id}
        {...others}
      />
    </Dialog>
  )
}

DialogEditDocumentAttachment.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DialogEditDocumentAttachment;
