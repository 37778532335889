import { useRef, useEffect, useState } from 'react'
import { useMounted } from '../../hooks'
import { getStyled } from '../../services/styles'
import PropTypes from 'prop-types'
import clonedeep from 'lodash.clonedeep'

import FormControl from '@mui/material/FormControl'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'

const ContentBox = getStyled(Box, ({theme}) => ({
  width: '100%',
  padding: theme.spacing(0, 4)
}));

const ResumeBox = getStyled(Box, ({theme}) => ({
  padding: theme.spacing(1.4, 0),
}));

const FormBox = getStyled(Box, ({theme}) => ({
  padding: theme.spacing(1, 0),
}));

const SearchFormControl = getStyled(FormControl, ({theme}) => ({
  width: '100%',
}));

const SearchInputBase = getStyled(InputBase, ({theme}) => ({
  margin: 0,
  width: '100%',
}));

function Search(props) {

  const { query, onChange, disabled, focus } = props
  const searchRef = useRef()
  const isMounted = useMounted()
  const [tempQuery, setTempQuery] = useState({})
  const [resume, setResume] = useState('')

  useEffect(() => {
    setTempQuery(query ? clonedeep(query) : {})
    setResume(query.search || 'Recherche')
  }, [query])

  const changeQuery = (queryPart) => {
    isMounted() && !disabled && setTempQuery({...tempQuery, ...queryPart})
  }

  const search = (e) => {
    e && e.preventDefault()
    e && e.stopPropagation()
    isMounted() && !disabled && onChange(tempQuery)
  }

  return (
    <ContentBox>
      { !focus && (
        <ResumeBox>{resume}</ResumeBox>
      ) }
      { focus && (
        <FormBox>
          <form onSubmit={(e) => search(e)}>
            <SearchFormControl>
              <SearchInputBase
                autoFocus={true}
                disabled={disabled}
                inputRef={searchRef}
                id="search"
                placeholder="Recherche"
                inputProps={{ 'aria-label': "Recherche" }}
                value={tempQuery.search || ''}
                onChange={ (e) => changeQuery({search: e.target.value}) }
                type="search"
              />
            </SearchFormControl>
          </form>
        </FormBox>
      ) }
    </ContentBox>
  )
}

Search.propTypes = {
  query: PropTypes.object, // critères de recherche
  onChange: PropTypes.func.isRequired, // changement de query
  focus: PropTypes.bool,
}

Search.defaultProps = {
  query: {},
  focus: false,
}

export default Search
