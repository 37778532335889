import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import supportTicketsService from './../../services/supportTickets'

import ConfirmDialog from './../commons/ConfirmDialog'
import BackdropLoading from './../commons/BackdropLoading'

function DialogSetStateSupportTicketOpened (props) {

  const { id, open, onSaved, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation((state) => {
    return supportTicketsService.setState(id, state)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['support-tickets'])
      onSaved(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title="Ré-ouverture du ticket ?"
        message="Le ticket va être ré-ouvert, les utilisateurs y étant liés seront notifiés."
        onConfirm={ (e) => {
          mutate(supportTicketsService.STATE_OPENED)
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogSetStateSupportTicketOpened.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogSetStateSupportTicketOpened;
