import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/medias/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/medias/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async upload (formData, onUploadProgress) {
    return API.post('/medias/', formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id, data) {
    return API.post('/medias/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id) {
    return API.delete('/medias/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getBase64 (id, params = {}) {
    params.base64 =  true
    return API.get('/medias/' + id + '/download', { params })
      .catch(error => Promise.reject(error.response));
  },
  async download (id, params) {
    return API.get('/medias/' + id + '/download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
