import { useCallback, useState } from 'react'
import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import projectUserRelsService from './../../services/projectUserRels'
import invitationsService from './../../services/invitations'
import projectsService from './../../services/projects'
import usersService from './../../services/users'

import BackdropLoading from './../commons/BackdropLoading'
import Loading from '../commons/Loading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormFieldDivider from './../commons/FormFieldDivider'
import FormControllers from './../commons/FormControllers'
import { CheckIcon, InfoIcon } from './../commons/Icons'
import SelectWorkerCat from '../commons/SelectWorkerCat'
import SelectUser from '../commons/SelectUser'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import SetContact from '../commons/SetContact'
import Typography from '@mui/material/Typography'
import SelectUserRole from '../commons/SelectUserRole'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

const InfoBox = getStyled(Box, ({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}))

const InfoTypography = getStyled(Typography, ({ theme }) => ({
  marginBottom: 0 + ' !important',
}))

const MyFormFieldDivider = getStyled(FormFieldDivider, ({ theme }) => ({
  margin: theme.spacing(3, 0, 2, 0) + ' !important'
}))

function EditInvitation(props) {

  const { id_project, preset, fixedCtrl, onSaved, onError } = props

  const [isNewAccount, setIsNewAccount] = useState(false)
  const [accountExistsFetching, setAccountExistsFetching] = useState(false)
  const [accountExists, setAccountExists] = useState(null)
  const [selectWorkerCatQuery, setSelectWorkerCatQuery] = useState({})
  const [selectUserQuery, setSelectUserQuery] = useState({})

  // Chargement du projet
  const { isFetching: isFetchingProject, isError: isErrorProject, refetch: refetchProject, data: project } = useQuery(['projects', id_project], async () => {
    return projectsService.get(id_project).then(([item]) => item)
  }, { enabled: !!id_project })

  const {formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    initialData: {
      email: '',
      id_user: null,
      project_role: projectUserRelsService.ROLE_CUSTOMER,
      id_worker_cat: null,
      firstname: '',
      lastname: '',
      society: '',
      civility: usersService.CIVILITY_M,
      contact: {}
    },
    presetData: preset,
    queryKey: 'invitations',
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listings des relations user/project
      queryClient.invalidateQueries(['project-user-rels'])
    },
    create: (data) => invitationsService.create(data),
    onSaved,
    onError
  })

  // Vérification si l'utilisateur (avec le mail spécifié) existe déjà
  const check = (e) => {
    e.preventDefault()
    if (formData?.id_user && formData?.id_user !== null) {
      // le compte existe déjà, on peut lancer l'invitation
      save(e)
      return false
    }
    if (!formData?.email || formData.email === '') {
      onError('Email manquant')
      return false
    }
    setAccountExistsFetching(true)
    invitationsService.accountExists({email: formData.email}).then(([exists, notices]) => {
      if (exists) {
        // le compte existe déjà, on peut lancer l'invitation
        save(e)
      } else {
        // le compte n'existe pas, on demande des informations indispensables à sa création
        setAccountExists(false)
      }
    }).catch((e) => {
      onError(e)
    }).finally(() => {
      setAccountExistsFetching(false)
    })
    return false
  }

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    if (!id_project || id_project === '') {
      onError('Projet manquant')
      return false
    }
    if (!formData?.project_role || formData.project_role === '') {
      onError('Rôle manquant')
      return false
    }
    if (accountExists === false) {
      if (!formData?.civility || formData?.civility === '') {
        onError('Civilité manquante')
        return false
      }
      if (!formData?.firstname || formData?.firstname === '') {
        onError('Prénom manquant')
        return false
      }
      if (!formData?.lastname || formData?.lastname === '') {
        onError('Nom manquant')
        return false
      }
      if (!formData?.contact?.tel || formData?.contact?.tel === '') {
        onError('Téléphone manquant')
        return false
      }
    }
    const finalFormData = {...formData, ...{id_project}}
    mutate(finalFormData)
    return false
  }
  // IMPORTANT : ici volontairement on ne passe pas en paramètre 'mergeFormData' à useCallback (d'où l'utilisation de // eslint-disable-next-line)
  // car cela génère une boucle infinie car 'mergeFormData' modifie 'formData' et donc quand 'formData' est modifié, 'mergeFormData'
  // qui est un 'useCallback' se modifie...
  const onUserSelectedLoaded = useCallback((user) => {
    // on met à jour le rôle en fonction des préférences de l'utilisateur sélectionné
    const preset_role = user?.preferences?.invitation?.role
    if (preset_role) {
      // eslint-disable-next-line
      mergeFormData({project_role: preset_role})
    }
    // on met à jour la catégorie pro. en fonction des préférences de l'utilisateur sélectionné
    const preset_id_worker_cat = user?.preferences?.invitation?.id_worker_cat
    if (preset_id_worker_cat) {
      // eslint-disable-next-line
      mergeFormData({id_worker_cat: preset_id_worker_cat})
    }
    // eslint-disable-next-line
  }, [])

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating || accountExistsFetching} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          { (isFetchingProject || isErrorProject) && <Loading error={isError} linear={true} refetch={refetchProject} /> }
          { project && (
            <Typography variant="body1">Invitation d'un utilisateur sur le chantier <b>{project.name}</b>. Si l'utilisateur n'a pas encore de compte, il est immédiatement créé. L'utilisateur est ensuite, sans délai, associé au chantier avec le <b>rôle spécifié</b>.</Typography>
          ) }

          <FormField>
            <FormControlLabel
              control={
                <Switch
                  checked={isNewAccount}
                  onChange={ (e) => {
                    mergeFormData({id_user: null, email: ''})
                    setIsNewAccount(e.target.checked)
                  } }
                  inputProps={{ 'aria-label': 'nouvel utilisateur' }}
                />
              }
              label={
                <Typography component="div" variant="body2">Nouvel utilisateur</Typography>
              }
            />
          </FormField>

          { !isNewAccount && (
            <FormField>
              <SelectUser
                title="Sélectionner un utilisateur existant"
                query={selectUserQuery}
                onQueryChange={(query) => setSelectUserQuery(query)}
                value={formData.id_user}
                onChange={(value) => mergeFormData({id_user: value})}
                onSelectedLoaded={onUserSelectedLoaded}
                onError={onError}
              />
            </FormField>
          ) }

          { isNewAccount && (
            <FormField>
              <MyFormControl>
                <MyTextField
                  disabled={accountExists !== null}
                  id="email"
                  label="Email"
                  type="email"
                  value={formData.email}
                  onChange={(e) => mergeFormData({email: e.target.value})}
                />
              </MyFormControl>
            </FormField>
          ) }

          { accountExists === true && (
            <InfoBox>
              <InfoIcon sx={{marginRight: 1}} />
              <InfoTypography variant="body1">Cet email correspond à un compte utilisateur existant.</InfoTypography>
            </InfoBox>
          ) }

          { accountExists === false && (
            <InfoBox>
              <InfoIcon sx={{marginRight: 1}} />
              <InfoTypography variant="body1">Cet email ne correspond à aucun compte utilisateur, il va automatiquement être créé lors de l'invitation.</InfoTypography>
            </InfoBox>
          ) }

          { accountExists === false && (
            <FormField>
              <MyFormControl>
                <InputLabel id="select-label-civility">Civilité</InputLabel>
                <Select
                  required
                  labelId="select-label-civility"
                  label="Civilité"
                  id="civility"
                  value={formData.civility}
                  onChange={(e) => mergeFormData({civility: e.target.value})}
                >
                  <MenuItem value={usersService.CIVILITY_M}>{usersService.getCivilityLabel(usersService.CIVILITY_M)}</MenuItem>
                  <MenuItem value={usersService.CIVILITY_MME}>{usersService.getCivilityLabel(usersService.CIVILITY_MME)}</MenuItem>
                  <MenuItem value={usersService.CIVILITY_MLLE}>{usersService.getCivilityLabel(usersService.CIVILITY_MLLE)}</MenuItem>
                </Select>
              </MyFormControl>
            </FormField>
          ) }

          { accountExists === false && (
            <FormField>
              <MyFormControl>
                <MyTextField
                  required
                  id="firstname"
                  label="Prénom"
                  type="text"
                  value={formData.firstname}
                  onChange={(e) => mergeFormData({firstname: e.target.value})}
                />
              </MyFormControl>
            </FormField>
          ) }

          { accountExists === false && (
            <FormField>
              <MyFormControl>
                <MyTextField
                  required
                  id="lastname"
                  label="Nom"
                  type="text"
                  value={formData.lastname}
                  onChange={(e) => mergeFormData({lastname: e.target.value})}
                />
              </MyFormControl>
            </FormField>
          ) }

          { accountExists === false && (
            <FormField>
              <MyFormControl>
                <MyTextField
                  id="society"
                  label="Société"
                  type="text"
                  value={formData.society}
                  onChange={(e) => mergeFormData({society: e.target.value})}
                />
              </MyFormControl>
            </FormField>
          ) }

          { accountExists === false && (
            <Box>
              <SetContact
                onChange={(value) => mergeFormData({contact: value})}
                value={formData.contact}
                tel_required={true}
                address_1_required={false}
                address_2_required={false}
                address_cp_required={false}
                address_city_required={false}
                address_country_required={false}
              />
            </Box>
          ) }

          <MyFormFieldDivider />

          <FormField>
            <SelectUserRole
              required
              value={formData.project_role}
              onChange={(value) => mergeFormData({project_role: value})}
              label="Rôle dans le projet"
            />
          </FormField>

          { formData.project_role === projectUserRelsService.ROLE_WORKER && (
            <SelectWorkerCat
              title="Catégorie pro."
              query={selectWorkerCatQuery}
              onQueryChange={(query) => setSelectWorkerCatQuery(query)}
              value={formData.id_worker_cat}
              onChange={(value) => mergeFormData({id_worker_cat: value})}
              onError={onError}
            />
          ) }

          <FormControllers fixed={fixedCtrl}>
            { accountExists === null && (
              <SubmitButton type="submit" onClick={(e) => check(e)} color="primary" variant="contained" endIcon={<CheckIcon />}>
                Inviter
              </SubmitButton>
            ) }
            { accountExists !== null && (
              <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" endIcon={<CheckIcon />}>
                Créer le compte et inviter
              </SubmitButton>
            ) }
          </FormControllers>

        </FormFields>

      </Form>

    </Box>
  )
}

EditInvitation.propTypes = {
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditInvitation.defaultProps = {
  fixedCtrl: false,
};

export default EditInvitation;
