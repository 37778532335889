import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import supportTicketsService from './../../services/supportTickets'

import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import TextFieldLimited from '../commons/TextFieldLimited'
import { SaveIcon } from './../commons/Icons'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

import Loading from '../commons/Loading'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextFieldLimited = getStyled(TextFieldLimited, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditSupportTicket(props) {

  const { id_project, id, preset, fixedCtrl, onSaved, onError } = props

  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    id,
    initialData: {
      name: '',
    },
    presetData: preset,
    queryKey: 'support-tickets',
    get: (id) => supportTicketsService.get(id),
    create: (data) => supportTicketsService.create(data),
    update: (id, data) => supportTicketsService.update(id, data),
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listings de l'entité
      queryClient.invalidateQueries(['support-tickets'])
    },
    onSaved,
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    let additionnalsData = {}
    if (isNew) {
      additionnalsData.id_project = id_project
    }
    mutate({...additionnalsData, ...formData})
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextFieldLimited
                required
                id="name"
                label="Sujet du ticket"
                type="text"
                value={formData.name}
                onChange={(value) => mergeFormData({name: value})}
                limit={50}
              />
            </MyFormControl>
          </FormField>

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              {isNew ? "Poursuivre" : "Modifier ce ticket"}
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>
    </Box>
  )
}

EditSupportTicket.propTypes = {
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditSupportTicket.defaultProps = {
  fixedCtrl: false,
  preset: {}
};

export default EditSupportTicket;
