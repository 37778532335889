import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

function ConfirmDialog (props) {

    const { open, title, message, children, cancelLabel, confirmLabel, onConfirm, onClose, confirmStartIcon, confirmEndIcon, cancelStartIcon, cancelEndIcon } = props
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ title || "Confirmation" }</DialogTitle>
            <DialogContent>
                { message && (
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                ) }
                { children }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" startIcon={cancelStartIcon} endIcon={cancelEndIcon}>
                    { cancelLabel || 'Non' }
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained" startIcon={confirmStartIcon} endIcon={confirmEndIcon}>
                    { confirmLabel || 'Oui' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmStartIcon: PropTypes.element,
  confirmEndIcon: PropTypes.element,
  cancelStartIcon: PropTypes.element,
  cancelEndIcon: PropTypes.element,
}

export default ConfirmDialog
