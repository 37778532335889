import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async getDefinition (id_project, params) {
    return API.get('/projects/' + id_project + '/monitorings/definition', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async get (id_project, slug, params) {
    return API.get('/projects/' + id_project + '/monitorings/' + slug, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (id_project, params) {
    return API.get('/projects/' + id_project + '/monitorings/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async save (id_project, slug, data) {
    return API.post('/projects/' + id_project + '/monitorings/' + slug, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id_project, slug) {
    return API.delete('/projects/' + id_project + '/monitorings/' + slug)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
