import { getStyled } from './../../services/styles'

import Stack from '@mui/material/Stack';

const MyStack = getStyled(Stack, ({theme}) => ({
  margin: theme.spacing(2, 0),
  padding: theme.spacing(0, 2),
}));

export default function FormFields (props) {

  const { children, styled, spacing, ...others } = props

  return (
      <MyStack spacing={spacing || 2} styled={{style: (styled && styled.root) ?? {}}} {...others}>
        { children }
      </MyStack>
  )
}
