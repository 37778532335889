import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useEditEntity } from '../../hooks'
import notificationsService from '../../services/notifications'

import BackdropLoading from '../commons/BackdropLoading'
import ConfirmDialog from '../commons/ConfirmDialog'
import { SendIcon } from '../commons/Icons'

function DialogNotifyForProjectPlanningUpdated (props) {

  const { id_project, planning_type, preset, onSaved, open, onClose, onError } = props

  const {formData, isMutating, mutate} = useEditEntity({
    initialData: {
      id_project,
      planning_type,
    },
    presetData: preset,
    queryKey: 'notifications',
    create: (data) => notificationsService.create_for_project_planning_updated(data),
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listings de l'entité
      queryClient.invalidateQueries(['notifications'])
    },
    onSaved,
    onError
  })

  const save = (e) => {
    e.preventDefault()

    mutate(formData)

    return false
  }

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title={"Notification - changement du planning " + (planning_type === 'long' ? 'long-terme' : 'court-terme')}
        message={"Une notification va être envoyée aux " + (planning_type === 'long' ? 'pros et clients' : 'pros') + " du chantier pour leur indiquer que le planning " + (planning_type === 'long' ? 'long-terme' : 'court-terme') + " du chantier a été modifié."}
        onConfirm={ (e) => {
          save(e)
        } }
        onClose={onClose}
        confirmLabel="Envoyer"
        cancelLabel="Annuler"
        confirmEndIcon={<SendIcon />}
      />
    </Fragment>
  )
}

DialogNotifyForProjectPlanningUpdated.propTypes = {
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  planning_type: PropTypes.string.isRequired,
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DialogNotifyForProjectPlanningUpdated;
