import { getStyled } from './../../services/styles'

const MyForm = getStyled('form', ({theme}) => ({
  width: '100%',
}));

export default function Form (props) {

    const { styled, children, ...others } = props;
    return (
        <MyForm styled={{style: (styled && styled.root) ?? {}}} {...others}>
          { children }
        </MyForm>
    );
}
