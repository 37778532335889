import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { getStyled } from './../../services/styles'
import mediasService from './../../services/medias'

import { RenewIcon } from './../commons/Icons'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

const ImgBox = getStyled(Box, ({theme}) => ({
  display: 'block',
  margin: '0 auto',
  maxWidth: '1200px',
  width: '100%',
  height: '100%',
  minHeight: '60px',
  backgroundColor: theme.palette.bg.light,
  position: 'relative',
}))

const Img = getStyled('img', ({theme, styled}) => {
  let style = {
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: 'auto',
    display: 'block',
  }
  if (styled?.size === 'thumb') {
    style.height = '100%'
  }
  return style
})

const FetchingBox = getStyled(Box, ({theme}) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}))

const ErrorBox = getStyled(Box, ({theme}) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}))

function MediasGalleryItem (props) {

  const { media, size, onClick } = props

  // Chargement
  const { isLoading, isFetching, isError, refetch, data: base64 } = useQuery(['medias-downloads', media.ID, 'size_' + size], async () => {
    return mediasService.getBase64(media.ID, {size}).then((response) => response.data.data)
  })

  let content = null

  if (isLoading || isFetching) {
    content = (
      <FetchingBox>
        <CircularProgress color="tiertiary" size={30} />
      </FetchingBox>
    )
  } else if (isError) {
    content = (
      <ErrorBox>
        <h4>Erreur de chargement</h4>
        <IconButton onClick={() => refetch()} size="large">
          <RenewIcon />
        </IconButton>
      </ErrorBox>
    )
  } else if (base64) {
    content = (<ImgBox styled={{size}} onClick={(e) => onClick && onClick(e, media)}>
      <Img
        styled={{size}}
        src={base64}
        alt={media.name}
      />
    </ImgBox>)
  }

  return (
    content
  )
}

MediasGalleryItem.propTypes = {
  media: PropTypes.object,
  size: PropTypes.string,
  onClick: PropTypes.func,
}

MediasGalleryItem.defaultProps = {
  size: 'thumb'
}

export default MediasGalleryItem
