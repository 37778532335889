import SupportTickets_API from './api/supportTickets'

/****************************************************
 Interface avec l'API relative aux tickets de support
****************************************************/

const exportedObject = {

  /**
   * Constantes
   */
  STATE_OPENED: 'opened',
  STATE_CLOSED: 'closed',

  /**************** projects ************************************/
  /**
   * Récupère les tickets de support
   * @return Promise(data, notices) : les relations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.getAll(args).then(({data, notices}) => {
        console.log('Tickets récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un ticket de support
   * @return Promise(data, notices) : le ticket de support
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.get(id, args).then(({data, notices}) => {
        console.log('Ticket récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un ticket de support
   * @return Promise(data, notices) : l'identifiant du ticket de support créé
   */
  create (data) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.create(data).then(({data, notices}) => {
        console.log('Ticket créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un ticket de support
   * @return Promise(data, notices) : l'identifiant du ticket de support mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.update(id, data).then(({data, notices}) => {
        console.log('Ticket mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un ticket de support
   * @return Promise(id, data, notices) : l'identifiant du ticket de support supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.delete(id).then(({data, notices}) => {
        console.log('Ticket supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** support ticket state ************************************/
  /**
   * Met à jour le statut d'un ticket de support
   * @return Promise(data, notices) : l'identifiant du ticket de support mis à jour
   */
  setState (id, state, state_comment = '') {
    return new Promise((resolve, reject) => {
      SupportTickets_API.setState(id, state, state_comment).then(({data, notices}) => {
        console.log('Statut de ticket de support mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** support ticket target ************************************/
  /**
   * Met à jour la cible d'un ticket de support
   * @return Promise(data, notices) : l'identifiant du ticket de support mis à jour
   */
   setTarget (id, id_user) {
    return new Promise((resolve, reject) => {
      SupportTickets_API.setTarget(id, id_user).then(({data, notices}) => {
        console.log('Cible de ticket de support mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Renvoi le libellé du statut
   * @param {string} slug 
   */
  getStateLabel(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_OPENED: {
        return 'ouvert';
      }
      case this.STATE_CLOSED: {
        return 'fermé';
      }
      default: {
        return slug;
      }
    }
  },

  /**
   * Renvoi le slug de la couleur du statut du projet
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
   getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_OPENED: {
        return 'support_ticket_state_opened';
      }
      case this.STATE_CLOSED: {
        return 'support_ticket_state_closed';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Retourne l'avatar de la relation
   * @param {object} project 
   * @returns 
   */
  getAvatar (project) {
    return null
  },
};
export default exportedObject;
