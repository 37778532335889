import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id_support_ticket, id, params) {
    return API.get('/support-tickets/' + id_support_ticket + '/messages/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (id_support_ticket, params) {
    return API.get('/support-tickets/' + id_support_ticket + '/messages/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (id_support_ticket, data) {
    return API.post('/support-tickets/' + id_support_ticket + '/messages/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id_support_ticket, id, data) {
    return API.post('/support-tickets/' + id_support_ticket + '/messages/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id_support_ticket, id) {
    return API.delete('/support-tickets/' + id_support_ticket + '/messages/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
