/**
 * Composant permettant d'ouvrir un contenu en popup
 */
import * as React from 'react';
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import { CloseIcon } from './../commons/Icons';

import MuiDialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const dialogTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyAppBar = getStyled(AppBar, ({theme}) => ({
  zIndex: theme.zIndex.drawer + 2,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,
}));

const BarContainer = getStyled(Container, ({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LeftBox = getStyled(Box, ({theme}) => ({
  minWidth: 80,
  display: 'flex',
  justifyContent: 'flex-start',
  marginLeft: theme.spacing(-1),
}));

const MiddleBox = getStyled(Box, ({theme}) => ({
  maxWidth: 'calc(100% - ' + 160 + ')',
  overflow: 'hidden',
}));

const RightBox = getStyled(Box, ({theme}) => ({
  minWidth: 80,
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: theme.spacing(-1),
}));

const TitleTypography = getStyled(Typography, ({theme}) => ({
  textAlign: 'center',
  margin: 0,
}));

const MainContainer = getStyled(Container, ({theme}) => ({
  padding: 0,
  paddingTop: theme.spacing(6.4),
  paddingBottom: theme.spacing(6.4),
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(5.5),
    paddingBottom: theme.spacing(5.5),
  },
}));

function Dialog (props) {
  const { styled, open, onClose, fullScreen, keepMounted, id, title, children, contentMaxWidth, disableGutters, leftComponents, ...others } = props;

  let diag_id = ""+id;
  if (diag_id === "") {
    diag_id = "dialog";
  }

  return (
    <MuiDialog
      keepMounted={keepMounted}
      PaperProps={ {id: diag_id} }
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      TransitionComponent={dialogTransition}
      {...others}
    >
      { fullScreen && (
        <>
          <MyAppBar color="inherit" position="fixed">
            <Toolbar>
              <BarContainer disableGutters={true} maxWidth="md">
                <LeftBox>{leftComponents}</LeftBox>
                <MiddleBox>
                  <TitleTypography variant="h3" component="h1" noWrap>{title}</TitleTypography>
                </MiddleBox>
                <RightBox>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    size="large">
                    <CloseIcon />
                  </IconButton>
                </RightBox>
              </BarContainer>
            </Toolbar>
          </MyAppBar>
          <MainContainer maxWidth={contentMaxWidth} disableGutters={disableGutters}>
            { children }
          </MainContainer>
        </>
      ) }
      { !fullScreen && children }
    </MuiDialog>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  keepMounted: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  contentMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  leftComponents: PropTypes.node,
};

Dialog.defaultProps = {
  fullScreen: true,
  keepMounted: false,
  disableGutters: false,
  contentMaxWidth: "sm"
};

export default Dialog;
