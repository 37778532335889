import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'
import { useMessage } from '../../hooks'
import utilsService from '../../services/utils'

import Dialog from './Dialog'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { ArrowLeftIcon, ArrowRightIcon } from '../commons/Icons'

import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

const CtrlBox = getStyled(Box, ({theme}) => ({
  display: 'flex',
  alignItems: 'center',
}))

const CustomDocument = getStyled(Document, ({theme}) => ({
  width: '100%',
  height: '100%',
}))

const OpenBox = getStyled(Box, ({theme}) => ({
  display: 'flex',
  justifyContent: 'center'
}))

const OpenButton = getStyled(Button, ({theme}) => ({
  margin: theme.spacing(2)
}))

function DialogFilePDF (props) {

  const { open, onClose, fullScreen, keepMounted, data } = props
  const message = useMessage()

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect (() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, [])

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const onError = (e) => {
    console.error(e)
    message.error(e)
  }

  const fileData = useMemo(() => {
    if (data?.base64) {
      var raw = atob(data.base64)
      var uint8Array = new Uint8Array(raw.length);
      for (var i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i);
      }
      return {data: uint8Array}
    }
    return data;
  }, [data])

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={pageNumber + " / " + numPages}
      contentMaxWidth={false}
      disableGutters={true}
      leftComponents={
        <CtrlBox>
          <IconButton disabled={pageNumber <= 1} color="inherit" onClick={() => setPageNumber(pageNumber - 1)} aria-label="previous page" size="large">
            <ArrowLeftIcon />
          </IconButton>
          <IconButton disabled={pageNumber >= numPages} color="inherit" onClick={() => setPageNumber(pageNumber + 1)} aria-label="next page" size="large">
            <ArrowRightIcon />
          </IconButton>
        </CtrlBox>
      }
    >
      { fileData && utilsService.iOS() && (
        <OpenBox>
          <OpenButton variant="outlined" href={data.url ? data.url : `data:${data.mimeType};base64,${data.base64}`} target="_blank" rel="noreferrer">Ouvrir le PDF dans un nouvel onglet</OpenButton>
        </OpenBox>
      ) }
      { fileData && !utilsService.iOS() && (
        <CustomDocument key={data} file={fileData} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onError} onSourceError={onError} loading="chargement du pdf...">
          <Page pageNumber={pageNumber} width={window.innerWidth} loading="chargement de la page..." />
        </CustomDocument>
      ) }
    </Dialog>
  )
}

DialogFilePDF.propTypes = {
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default DialogFilePDF
