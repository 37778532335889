import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/users/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/users/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id, data) {
    return API.post('/users/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id) {
    return API.delete('/users/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
