import Medias_API from './api/medias'

/****************************************************
 Interface avec l'API relative aux medias
****************************************************/

const exportedObject = {

  /**************** medias ************************************/
  /**
   * Récupère les medias du serveur
   * @return Promise(data, notices) : les medias
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Medias_API.getAll(args).then(({data, notices}) => {
        console.log('Medias récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un media du serveur
   * @return Promise(data, notices) : le media
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Medias_API.get(id, args).then(({data, notices}) => {
        console.log('Media récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Téléverse un nouveau média
   * @return Promise(data, notices) : le média téléversé
   */
  upload (formData, onUploadProgress) {
    return new Promise((resolve, reject) => {
      Medias_API.upload(formData, onUploadProgress).then(({data, notices}) => {
        console.log('Média téléversé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un media
   * @return Promise(data, notices) : l'identifiant du media mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Medias_API.update(id, data).then(({data, notices}) => {
        console.log('Media mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un media
   * @return Promise(id, data, notices) : l'identifiant du media supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Medias_API.delete(id).then(({data, notices}) => {
        console.log('Media supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** media download ************************************/
  /**
   * Télécharge un media
   * @return Promise(data, notices) : le media
   */
  getBase64 (id, params) {
    return new Promise((resolve, reject) => {
      Medias_API.getBase64(id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Télécharge un media
   * @return Promise(data, notices) : le media
   */
  download (id, params) {
    return new Promise((resolve, reject) => {
      Medias_API.download(id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default exportedObject;
