import { getStyled } from '../../services/styles'
import PropTypes from 'prop-types'

import Query from './Query'
import MuiImageList from '@mui/material/ImageList'
import MuiImageListItem from '@mui/material/ImageListItem'

const ImageList = getStyled(MuiImageList, ({theme}) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
}))

const ImageListItem = getStyled(MuiImageListItem, ({theme}) => ({
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '5px',
}))

function ImagesGallery (props) {

  const { items, renderItem, cols, gap, rowHeight, ...others } = props

  const renderList = (items) => {
    return (
      <ImageList cols={cols} gap={gap} rowHeight={rowHeight}>
        {items.map((item, index) => {
          return (
            <ImageListItem key={index}>
              { renderItem({item, first: index === 0, last: index === items.length - 1}) }
            </ImageListItem>
          )
        })}
      </ImageList>
    )
  }

  if (items) {
    return renderList(items)
  }

  return (
    <Query
      {...others}
      renderItems={(items) => {
        if (!items || items.length < 1) {
          return null
        }
        return renderList(items)
      }}
    />
  )
}

ImagesGallery.propTypes = {
  items: PropTypes.array, // dans le cas d'une liste statique, sinon Query est utilisé
  renderItem: PropTypes.func.isRequired,
  cols: PropTypes.number,
  gap: PropTypes.number,
  rowHeight: PropTypes.number,
};

ImagesGallery.defaultProps = {
  cols: 4,
  gap: 10,
};

export default ImagesGallery