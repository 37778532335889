import PropTypes from 'prop-types'

import File from './../file/File'
import Dialog from './Dialog'
import DialogFilePDF from './DialogFilePDF'

function DialogFile (props) {

  const { open, onClose, fullScreen, keepMounted, data, ...others } = props

  // on attend une URI base 64 : "data:#FileMimeType:string;base64,#Base64EncodedFileData:string"

  const base64Parts = data.split(';base64,')
  const mimeType = base64Parts[0].replace('data:', '')
  const base64 = base64Parts[1]

  if (mimeType === 'application/pdf') {
    return (
      <DialogFilePDF
        keepMounted={keepMounted}
        fullScreen={fullScreen}
        open={open}
        onClose={onClose}
        data={{mimeType, base64}}
        {...others}
      />
    )
  }

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title="Fichier"
      contentMaxWidth={false}
      disableGutters={true}
    >
      <File
        data={`data:${mimeType};base64,${base64}`}
        {...others}
      />
    </Dialog>
  )
}

DialogFile.propTypes = {
  data: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default DialogFile
