import { getStyled } from './../../services/styles'
import Typography from '@mui/material/Typography';

const MainTypography = getStyled(Typography, ({theme}) => ({
  fontWeight: 600,
  wordBreak: 'break-word',
  margin: theme.spacing(0 , 0, 0.5, 0)
}))

function ListItemContentSummary1 (props) {

  const { children, style, ...others } = props;
  const innerProps = { styled: {style}, component: "h3", variant: "body1" };

  return (
    <MainTypography {...{...innerProps, ...others}} sx={{}}>{children}</MainTypography>
  )
}

export default ListItemContentSummary1;
