import { store } from './store';
import { setApiMinimalVersion } from './store/features/appSlice';

const exportedObject = {

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  },
  isEqual (a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  },
  isTrue (a) {
    return a === true || a === 1 || a === '1' || a === 'true' || a === 'yes' || a === 'vrai' || a === 'oui'
  },
  isObject (item) {
    return typeof item === 'object';
  },
  getErrorMessage (error) {
    if (Array.isArray(error) && error.length > 0) {
      error = error.join(', ');
    } else if (typeof error === 'object' && error !== null) {
      if (error.data && error.data.message) {
        error = error.data.message;
      } else if (error.response) {
        error = error.response;
      } else if (error.message) {
        error = error.message;
      } else {
        error = JSON.stringify(error);
      }
    } else if (!error || error === undefined) {
      error = "Erreur inconnue";
    }
    return error
  },
  stripHtmlTags (str) {
    if (str) {
      return str.replace(/<\/?[^>]+(>|$)/g, "");
    }
    return str;
  },
  truncate(str, limit, end = '...') {
    let words = str.split(" ");
    if (words.length > limit) {
      return words.splice(0, limit).join(" ") + end;
    }
    return str;
  },
  dateStringToDate (dateString) {
    return new Date(this.dateStringToDateTime(dateString));
  },
  dateStringToDateTime (dateString) {
    return Date.parse(dateString);
  },
  dateStringToLocalString (dateString, short = true, hours = true) {
    let datetime = this.dateStringToDateTime(dateString);
    if (isNaN(datetime)) {
      return 'date incorrecte';
    } else {
      return this.dateTimeToLocalString(datetime, short, hours);
    }
  },
  dateTimeToLocalString (datetime, short = true, hours = true) {
    return this.dateToLocalString(new Date(datetime), short, hours);
  },
  dateToLocalString (date, short = true, hours = true) {
    let options = {
      weekday: short ? 'short' : 'long',
      day: 'numeric',
      month: short ? 'short' : 'long',
      year: 'numeric',
      timeZone: 'Europe/Paris',
    };
    if (hours) {
      options.hour = '2-digit';
      options.minute = '2-digit';
    }
    return date.toLocaleString('fr-FR', options);
  },
  dateToMysqlDateTime (date) {
    return date.getFullYear() + "-"
     + this.twoDigits(1 + date.getMonth())
      + "-" + this.twoDigits(date.getDate())
       + " " + this.twoDigits(date.getHours())
        + ":" + this.twoDigits(date.getMinutes())
         + ":" + this.twoDigits(date.getSeconds());
  },
  dateToMysqlDateTimeUTC (date) {
    return date.getUTCFullYear() + "-"
     + this.twoDigits(1 + date.getUTCMonth())
      + "-" + this.twoDigits(date.getUTCDate())
       + " " + this.twoDigits(date.getUTCHours())
        + ":" + this.twoDigits(date.getUTCMinutes())
         + ":" + this.twoDigits(date.getUTCSeconds());
  },
  dateDayName (date) {
    var days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    return days[date.getDay()];
  },
  getHours2Digits(hours) {
    return ("0" + hours).slice(-2);
  },
  getMinutes2Digits(minutes) {
    return ("0" + minutes).slice(-2);
  },
  twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
  },
  stringSearch(string, search) {
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return string.search(search) > -1;
  },
  clearAppCache() {
    if (!window.caches) {
      console.warn("Impossible de vider les window.caches");
    }
    caches.keys().then((names) => {
        for (var index = 0; index < names.length; index++) {
            var name = names[index];
            caches.delete(name);
        }
    });
  },
  /**
   * Reload current application with this actions :
   *  - clear window.caches
   *  - clear api_minimal_version from store (it will be set in next API request)
   *  - notifiy SW if update is waiting
   *  - reload current location
   */
  reloadApp() {
    // clear window.caches
    this.clearAppCache();
    // clear api_minimal_version from store
    store.dispatch(setApiMinimalVersion(null));
    // notifiy SW if update is waiting
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({type: 'SKIP_WAITING'});
        } else {
          console.warn("Aucune mise à jour de SW en attente");
        }
      });
    }
    // reload current location after 2s waiting
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  },
  getBaseURL() {
    return window.location.protocol + '//' + window.location.host;
  },
  /**
   * Renvoie une date prochaine en fonction de la date de départ spécifiée et
   * du prochain jour spécifié
   */
  getNextDate(date_from, next_day = "P1D") {
    if (next_day.startsWith('P') && next_day.endsWith('D')) {
      date_from.setDate(date_from.getDate() + parseInt(next_day.replaceAll('P', '').replaceAll('D', '')));
    } else {
      let next_day_num = 1;
      switch (next_day) {
        case "next-mon": {
          next_day_num = 1;
          break;
        }
        case "next-tue": {
          next_day_num = 2;
          break;
        }
        case "next-wed": {
          next_day_num = 3;
          break;
        }
        case "next-thu": {
          next_day_num = 4;
          break;
        }
        case "next-fri": {
          next_day_num = 5;
          break;
        }
        case "next-sat": {
          next_day_num = 6;
          break;
        }
        case "next-sun": {
          next_day_num = 0;
          break;
        }
        default: {
          next_day_num = 1;
          break;
        }
      }
      // calcul du prochain jour
      date_from.setDate(date_from.getDate() + (next_day_num + 7 - date_from.getDay()) % 7);
    }
    return date_from;
  },
  getDaySlugFromIndex (index) {
    let day = '';
    switch (parseInt(index)) {
      case 1: {
        day = 'mon';
        break;
      }
      case 2: {
        day = 'tue';
        break;
      }
      case 3: {
        day = 'wed';
        break;
      }
      case 4: {
        day = 'thu';
        break;
      }
      case 5: {
        day = 'fri';
        break;
      }
      case 6: {
        day = 'sat';
        break;
      }
      case 0: {
        day = 'sun';
        break;
      }
      default: {
        day = null;
        break;
      }
    }
    return day;
  },
  getDayLabelFromIndex (index) {
    let day = '';
    switch (parseInt(index)) {
      case 1: {
        day = 'Lundi';
        break;
      }
      case 2: {
        day = 'Mardi';
        break;
      }
      case 3: {
        day = 'Mercredi';
        break;
      }
      case 4: {
        day = 'Jeudi';
        break;
      }
      case 5: {
        day = 'Vendredi';
        break;
      }
      case 6: {
        day = 'Samedi';
        break;
      }
      case 0: {
        day = 'Dimanche';
        break;
      }
      default: {
        day = 'Inconnu';
        break;
      }
    }
    return day;
  },
  getDayLabelFromSlug (slug) {
    let day = '';
    switch (slug) {
      case "mon": {
        day = 'Lundi';
        break;
      }
      case "tue": {
        day = 'Mardi';
        break;
      }
      case "wed": {
        day = 'Mercredi';
        break;
      }
      case "thu": {
        day = 'Jeudi';
        break;
      }
      case "fri": {
        day = 'Vendredi';
        break;
      }
      case "sat": {
        day = 'Samedi';
        break;
      }
      case "sun": {
        day = 'Dimanche';
        break;
      }
      default: {
        day = 'Inconnu';
        break;
      }
    }
    return day;
  },
  /**
   * Renvoie les initiales d'un nom
   */
  getInitials (name) {
    let initials = '';
    if (name) {
      const name_parts = name.replace(/[^a-z0-9\s]/gi,' ').replace(/\s\s/gi, ' ').split(' ');
      if (name_parts && name_parts.length > 0) {
        initials = name_parts[0].charAt(0).toUpperCase() + (name_parts.length > 1 ? name_parts[1].charAt(0).toUpperCase() : '');
      }
    }
    return initials;
  },
  /**
   * Renvoi la chaine de caractère avec une majuscule au premier mot
   */
  capitalizeFirstWord(str) {
    return str.split(' ').map((word, index) => index === 0 ? this.capitalizeFirstLetter(word) : word).join(' ');
  },
  /**
   * Renvoi la chaine de caractère avec la première lettre en majuscule
   */
  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  /**
   * Renvoi le numéro de département à partir du code postal
   */
  getCpCode (cp) {
    if (cp && cp.length > 1) {
       return cp.charAt(0) + cp.charAt(1);
    }
    return null;
  },
};
export default exportedObject;
