import axios from 'axios';
import { store } from './../store';
import { setApiMinimalVersion, setApiMaintenanceMode } from './../store/features/appSlice';
import authService from './../auth';
import packageJson from './../../package.alias.json';
import Cookies from 'js-cookie';

/**
 * DOC : https://github.com/axios/axios
 */
let axios_instance = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_BASE_URL,
  withCredentials: true // enable cookies sending
});

axios_instance.interceptors.request.use((config) => {
  const authToken = authService.getToken();
  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  const csrf_token = Cookies.get('csrf-token');
  if (!csrf_token) {
    console.warn('CSRF Token not sent by server');
  } else {
    config.headers['X-CSRF-Token'] = `${csrf_token}`;
  }
  config.headers['X-App-Version'] = `${packageJson.version}`;
  return config;
});

axios_instance.interceptors.response.use((response) => {
  // Le serveur répond, s'il était en maintenance, il ne l'est plus
  store.dispatch(setApiMaintenanceMode(false));
  // On récupère le numéro de version minimale de l'App compatible avec l'API
  if (response.headers['X-App-Compat-Version']) {
    store.dispatch(setApiMinimalVersion(response.headers['X-App-Compat-Version']));
  } else if (response.headers['x-app-compat-version']) {
    store.dispatch(setApiMinimalVersion(response.headers['x-app-compat-version']));
  }
  // make some verification, like 'sameorigine', by checking this tuto : https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
  return response;
}, async (error) => {
  const originalRequest = error.config;
  if (error.response) {
    // Service temporairement indisponible
    if (error.response.status === 503) {
      error.response = "Service en maintenance";
      store.dispatch(setApiMaintenanceMode(true));
    }
    // Authentification échouée
    if (error.response.status === 403 && !originalRequest._retry) {
      // Le token est expiré - on tente de le rafraichir
      originalRequest._retry = true;
      try {
        const authToken = await authService.refreshToken();
        // le token est rafraichi, on relance la requête initiale avec ce nouveau token
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        return axios_instance(originalRequest);
      } catch(e) {
        // une erreur est survenue lors de la tentative de rafraichissement du token
        // NOTE : authService.refreshToken() doit avoir déconnecté l'utilisateur de l'UI
        error.response = "Erreur d'authentification";
        // console.error(e);
      }
    }
  } else {
    // lorsqu'il n'y a pas de reponse, c'est surement que le serveur n'est pas accessible
    error.response = "Erreur de connexion";
    console.error(error);
  }
  return Promise.reject(error);
});

export default axios_instance;
