import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'
import ListItem from '@mui/material/ListItem'

const MyListItem = getStyled(ListItem, ({ theme, styled }) => {
  let style = {
    flexDirection: 'column',
    alignItems: 'normal',
    backgroundColor: '#ffffff',
  }
  if (styled && styled.clickable) {
    style = {...style, ...{
      cursor: 'pointer',
    }}
  }
  if (styled && styled.selected) {
    style = {...style, ...{
      backgroundColor: '#f9f9f9',
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    }}
  }
  return style
})

function ListItemComponent (props) {

  const { selected, divider, onClick, disableGutters, children } = props;

  return (
    <MyListItem
      divider={divider}
      disableGutters={disableGutters}
      styled={{clickable: onClick ? true : false, selected: selected}}
      onClick={onClick}
    >
      { children }
    </MyListItem>
  )
}

ListItemComponent.propTypes = {
  disableGutters: PropTypes.bool,
  selected: PropTypes.bool,
  divider: PropTypes.bool,
  onClick: PropTypes.func,
};

ListItemComponent.defaultProps = {
  selected: false,
  divider: false,
  disableGutters: true,
};

export default ListItemComponent;
