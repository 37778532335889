import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const RootBox = getStyled(Box, ({theme}) => ({
  position: 'relative',
}))

const InfoTypography = getStyled(Typography, ({theme}) => ({
  position: 'absolute',
    bottom: 6,
    right: 6,
    color: '#999999',
}))

function TextFieldLimited (props) {

  const { classes, value, onChange, limit, ...others } = props

  return (
    <RootBox>
      <TextField
        value={value}
        onChange={ (e) => onChange(e.target.value.slice(0, limit)) }
        {...others}
      />
      <InfoTypography component="span" variant="body3">{ value ? value.length : '0' }/{ limit }</InfoTypography>
    </RootBox>
  )
}

TextFieldLimited.propTypes = {
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number,
}

TextFieldLimited.defaultProps = {
  limit: 200,
}

export default TextFieldLimited
