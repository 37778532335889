import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null, // user authentication token
	refresh_token: null, // user authentication refresh token
  user: null, // authenticated user
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refresh_token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    clearRefreshToken: (state) => {
      state.refresh_token = null;
    },
    clearUser: (state) => {
      state.user = null;
    }
  },
});

// export actions
export const { setToken, setRefreshToken, setUser, clearToken, clearRefreshToken, clearUser } = authSlice.actions;

// export reducers
export default authSlice.reducer;
