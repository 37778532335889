import DialogFilePDF from './DialogFilePDF'

function DialogCharteQualite (props) {

  return (
    <DialogFilePDF
      {...props}
      title="Charte qualité"
      data={{ url: process.env.REACT_APP_PDF_CHARTE_QUALITE_URL }}
    />
  )
}

export default DialogCharteQualite
