import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import usersService from '../../services/users'

import BackdropLoading from '../commons/BackdropLoading'
import ConfirmDeleteDialog from '../commons/ConfirmDeleteDialog'

function DialogDeleteUser (props) {

  const { id, open, onDeleted, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation(() => {
    return usersService.delete(id)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['users'])
      onDeleted(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDeleteDialog
        open={open}
        title="Suppression de l'utilisateur ?"
        message="Les invitations rattachées à cet utilisateur vont être supprimées. Cette action est irreversible."
        onConfirm={ (e) => {
          mutate()
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogDeleteUser.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogDeleteUser;
