import DocumentsAttachments_API from './api/documents-attachments'

/****************************************************
 Interface avec l'API relative aux documentsAttachments
****************************************************/

const exportedObject = {

  /**************** documentsAttachments ************************************/
  /**
   * Récupère les documentsAttachments du serveur
   * @return Promise(data, notices) : les documentsAttachments
   */
  getAll (id_document, args) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.getAll(id_document, args).then(({data, notices}) => {
        console.log('DocumentsAttachments récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un documentsAttachment du serveur
   * @return Promise(data, notices) : le documentsAttachment
   */
  get (id_document, id, args) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.get(id_document, id, args).then(({data, notices}) => {
        console.log('DocumentsAttachment récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Téléverse un nouveau média
   * @return Promise(data, notices) : le média téléversé
   */
  upload (id_document, formData, onUploadProgress) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.upload(id_document, formData, onUploadProgress).then(({data, notices}) => {
        console.log('DocumentsAttachment téléversé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un documentsAttachment
   * @return Promise(data, notices) : l'identifiant du documentsAttachment mis à jour
   */
  update (id_document, id, data) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.update(id_document, id, data).then(({data, notices}) => {
        console.log('DocumentsAttachment mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un documentsAttachment
   * @return Promise(id, data, notices) : l'identifiant du documentsAttachment supprimée
   */
  delete (id_document, id) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.delete(id_document, id).then(({data, notices}) => {
        console.log('DocumentsAttachment supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** documentsAttachment download ************************************/
  getBase64 (id_document, id, params = {}) {
    return new Promise((resolve, reject) => {
      console.log("getBase64...");
      DocumentsAttachments_API.getBase64(id_document, id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Télécharge un documentsAttachment
   * @return Promise(data, notices) : le documentsAttachment
   */
  download (id_document, id, params) {
    return new Promise((resolve, reject) => {
      console.log("download...");
      DocumentsAttachments_API.download(id_document, id, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  bulk_download (id_document, params) {
    return new Promise((resolve, reject) => {
      DocumentsAttachments_API.bulk_download(id_document, params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Renvoi l'avatar
   * @param {object} documentAttachment 
   * @returns 
   */
  getAvatar (documentAttachment) {
    return null
  },
};
export default exportedObject;
