import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'
import projectUserRelsService from './../../services/projectUserRels'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

/**
 * Sélecteur de rôle d'utilisateur
 */
function SelectUserRole (props) {

  const { value, onChange, label, required } = props

  return (
    <MyFormControl>
      <InputLabel id="select-label-role">{(label || "Rôle") + (required && " *")}</InputLabel>
      <Select
        required={required}
        labelId="select-label-role"
        label={(label || "Rôle") + (required && " *")}
        id="role"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value={projectUserRelsService.ROLE_CUSTOMER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_CUSTOMER)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_WORKER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_WORKER)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_CONSULTANT}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_CONSULTANT)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_COMMERCIAL}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_COMMERCIAL)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_MANAGER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_MANAGER)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_ASSISTANT}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_ASSISTANT)}</MenuItem>
        <MenuItem value={projectUserRelsService.ROLE_ADMINISTRATOR}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_ADMINISTRATOR)}</MenuItem>
      </Select>
    </MyFormControl>
  )
}

SelectUserRole.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
}

export default SelectUserRole
