import { useState, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'

import Dialog from './../dialog/Dialog'
import SelectAsyncChip from './SelectAsyncChip'
import { RenewIcon, ArrowBottomIcon } from './../commons/Icons'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useQuery } from '@tanstack/react-query'

const TitleLegend = getStyled('legend', ({ theme, styled }) => ({
  padding: theme.spacing(0, 0.5),
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '0.75rem',
}))

const HelperTypography = getStyled(Typography, ({ theme, styled }) => ({
  display: 'block',
  width: '100%',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '0.75rem',
}))

/**
 * Sélecteur Asynchrone, permet de gérer une sélection d'élement à partir d'une source asynchrone
 */
function SelectAsync (props) {

  const { value, query, queryKey, queryLoad, onChange, onSelectedLoaded, onError, title, helperText, label, labelError, labelIcon, renderSelect, renderSelected, renderFieldCustom } = props

  const [opened, setOpened] = useState(false)
  const [alreadyOpened, setAlreadyOpened] = useState(false)

  // si une valeur est passée, on tente de charger l'objet depuis l'API
  const { isLoading, isFetching, isError, data: valueObject } = useQuery([queryKey, 'selectAsync', query, value], async () => {
    return queryLoad(query, value).catch((e) => {
      onError && onError(e)
      onSelect(null)
    })
  }, {
    enabled: !!value,
    onError
  })

  // quand l'élement sélectionné chargé de l'API change, on informe le parent
  useEffect(() => {
    onSelectedLoaded && onSelectedLoaded(valueObject)
  }, [valueObject, onSelectedLoaded])

  const onSelect = (value) => {
    onChange && onChange(value)
    close()
  }

  const open = () => {
    setOpened(true)
    setAlreadyOpened(true)
  }

  const close = () => {
    setOpened(false)
  }

  const renderField = () => {

    if (value && (isLoading || isFetching)) {
      return (
        <SelectAsyncChip
          icon={<CircularProgress size={20} color="secondary" />}
        />
      )
    } else if (isError) {
      return (
        <SelectAsyncChip
          icon={<RenewIcon />}
          label={ labelError || "Erreur de chargement" }
          onClick={() => open() }
        />
      )
    } else if (value) {
      return (
        <SelectAsyncChip
          icon={labelIcon}
          label={renderSelected && renderSelected(value, valueObject)}
          onClick={() => open() }
          onDelete={() => onSelect(null) }
          variant="outlined"
        />
      )
    }
    return (
      <SelectAsyncChip
        icon={labelIcon}
        label={ label || "Choisir" }
        iconDelete={<ArrowBottomIcon />}
        onClick={() => open() }
        onDelete={() => open() }
      />
    )
  }

  return (
    <Box>
      { alreadyOpened && (
        <Dialog
          title={title}
          keepMounted={true}
          open={opened}
          onClose={() => close()}
        >
          <Box>
            { renderSelect && renderSelect(onSelect, value) }
          </Box>
        </Dialog>
      ) }
      { renderFieldCustom && renderFieldCustom(opened, value, valueObject, isLoading, isError) }
      { !renderFieldCustom && (
        <Fragment>
          <fieldset>
            { title && (<TitleLegend>{title}</TitleLegend>) }
            { renderField() }
          </fieldset>
          { helperText && (
            <HelperTypography>{ helperText }</HelperTypography>
          ) }
        </Fragment>
      ) }
    </Box>
  )

  
}

SelectAsync.propTypes = {
  title: PropTypes.string,
  renderSelect: PropTypes.func.isRequired, // affiche la liste des éléments à sélectionner dans une Dialog
  renderField: PropTypes.func, // Surcharge le rendu du composant
  value: PropTypes.oneOfType([
    PropTypes.number, // 12
    PropTypes.string, // '12'
  ]),
  onChange: PropTypes.func.isRequired, // La sélection est mise à jour
  onSelectedLoaded: PropTypes.func, // L'élement de la sélection est chargé depuis l'API
  onError: PropTypes.func.isRequired,
  query: PropTypes.object,
  disabled: PropTypes.bool,
}

export default SelectAsync;
