import { getStyled } from './../../services/styles'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const MyBackdrop = getStyled(Backdrop, ({theme}) => ({
  zIndex: theme.zIndex.drawer + 1000,
  color: '#fff'
}));

export default function BackdropLoading (props) {
  const {open, ...others} = props
  return (
    <MyBackdrop open={open} {...others}>
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress color="inherit" />
      </Box>
    </MyBackdrop>
  )
}
