import PropTypes from 'prop-types'

import EditSupportTicketTarget from './../edit/EditSupportTicketTarget'
import Dialog from './Dialog'

function DialogEditSupportTicketTarget (props) {

  const { open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title="Cible du ticket"
    >
      <EditSupportTicketTarget
        fixedCtrl={true}
        {...others}
      />
    </Dialog>
  )
}

DialogEditSupportTicketTarget.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default DialogEditSupportTicketTarget
