import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import Box from '@mui/material/Box'

const Iframe = getStyled('iframe', ({theme}) => ({
  height: '95vh',
  border: 'none',
  marginBottom: theme.spacing(2),
  borderBottom: '1px solid ' + theme.palette.bg.light,
}))

function File (props) {

  const { data } = props

  const iframe = useMemo(() => {
    if (data && data !== '') {
      return <Iframe key={data} src={data} width="100%" height="100%" />
    }
    return null
  }, [data])

  return (
    <Box>
      { data && iframe }
    </Box>
  )
}

File.propTypes = {
  data: PropTypes.string.isRequired,
}

export default File;
