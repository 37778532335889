import PropTypes from 'prop-types'

import EditSupportTicket from './../edit/EditSupportTicket'
import Dialog from './Dialog'

function DialogEditSupportTicket (props) {

  const { id, open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={id ? "Modifier un ticket SAV" : "Nouveau ticket SAV"}
    >
      <EditSupportTicket
        fixedCtrl={true}
        id={id}
        {...others}
      />
    </Dialog>
  )
}

DialogEditSupportTicket.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default DialogEditSupportTicket
