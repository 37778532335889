import { useState } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'
import { useQueryClient } from '@tanstack/react-query'
import { useEditEntity, useEditMediasEntity } from '../../hooks'
import supportTicketsServiceMessage from '../../services/supportTicketMessages'
import mediasService from '../../services/medias'

import BackdropLoading from '../commons/BackdropLoading'
import Form from '../commons/Form'
import FormFields from '../commons/FormFields'
import FormField from '../commons/FormField'
import FormFieldDivider from '../commons/FormFieldDivider'
import FormControllers from '../commons/FormControllers'
import TextFieldLimited from '../commons/TextFieldLimited'
import SetFiles from '../commons/SetFiles'
import { SaveIcon, PhotoIcon } from '../commons/Icons'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'

import Loading from '../commons/Loading'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextFieldLimited = getStyled(TextFieldLimited, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditSupportTicket(props) {

  const { id_support_ticket, id, preset, fixedCtrl, onSaved, onError } = props

  const queryClient = useQueryClient()
  const queryKey = 'support-tickets-' + id_support_ticket + '-messages'

  const [ medias, setMedias ] = useState([])

  // Gestion du message
  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, mutate, entity} = useEditEntity({
    id,
    initialData: {
      message: '',
    },
    presetData: preset,
    queryKey,
    get: (id) => supportTicketsServiceMessage.get(id_support_ticket, id),
    create: (data) => supportTicketsServiceMessage.create(id_support_ticket, data),
    update: (id, data) => supportTicketsServiceMessage.update(id_support_ticket, id, data),
    onSaved: (id, isNew) => {
      // Mise à jour de la liste de médias, on le fait maintenant que le message est créé
      mutateMedias({medias, args: {id_support_ticket_message: id, isNew}})
    },
    onError
  })


  // Gestion des médias du message
  const mediasQuery = entity ? {id_support_ticket_message: entity.ID} : undefined
  const mediasQueryKeys = ['medias', mediasQuery]
  const { mutate: mutateMedias, isMutating: isMutatingMedias, isFetching: isFetchingMedias, isError: isErrorMedias, refetch: refetchMedias } = useEditMediasEntity({
    query: mediasQuery,
    queryKeys: mediasQueryKeys,
    getAll: (query) => query ? mediasService.getAll(query) : new Promise((resolve) => resolve([[], []])),
    upload: (data) => mediasService.upload(data),
    delete: (id) => mediasService.delete(id),
    onGetAll: (items) => setMedias(items),
    onSuccess: ({medias, args}) => {

      // Les médias sont enregistrés, on peut invalider les queries
      queryClient.invalidateQueries(['medias', {id_support_ticket_message: args.id_support_ticket_message}])

      if (args.isNew) {
        // NOTE: lorsque que c'est un nouveau message, on invalide les queries des listing des tickets
        // pour mettre à jour le count_messages
        // queryClient.invalidateQueries(['support-tickets'])
      }

      // NOTE : cas particulier, on charge toujours en une seule requête les messages d'un ticket, donc il est inutile d'invalider
      // les queries du message seul
      queryClient.invalidateQueries(['support-tickets-' + id_support_ticket + '-messages'])

      mediasQuery && queryClient.invalidateQueries([mediasQueryKeys])

      // Les médias sont enregistrés, on peut terminer
      onSaved(args.id_support_ticket_message, entity ? false : true)

    },
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    mutate(formData)
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating || isMutatingMedias} />

      <Form onSubmit={(e) => save(e)} noValidate>

      <FormFields>

        <FormField>
          <MyFormControl>
            <MyTextFieldLimited
              required
              id="name"
              label="Votre message..."
              type="text"
              value={formData.message}
              onChange={(value) => mergeFormData({message: value})}
              limit={2000}
              multiline={true}
              minRows={6}
            />
          </MyFormControl>
        </FormField>

        <FormFieldDivider />

        <FormField>
          <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            { mediasQuery && (isFetchingMedias || isErrorMedias) && <Loading linear={true} error={isErrorMedias} refetch={refetchMedias} /> }
            <SetFiles
              value={medias || []}
              onChange={(value) => setMedias(value)}
              onError={onError}
              label="Ajouter une photo"
              labelDrag="Glisser une photo"
              accept="image/*"
              sx={{ width: '100%' }}
              startIcon={<PhotoIcon />}
            />
          </Box>
        </FormField>

        <FormControllers fixed={fixedCtrl}>
          <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
            {isNew ? "Déposer mon message" : "Modifier ce message"}
          </SubmitButton>
        </FormControllers>

      </FormFields>

      </Form>
    </Box>
  )
}

EditSupportTicket.propTypes = {
  id_support_ticket: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditSupportTicket.defaultProps = {
  fixedCtrl: false,
  preset: {}
};

export default EditSupportTicket;
