import PropTypes from 'prop-types'

import EditMedia from './../edit/EditMedia'
import Dialog from './Dialog'

function DialogEditMedia (props) {

  const { id, open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={id ? "Modifier un média" : "Nouveau média"}
    >
      <EditMedia
        fixedCtrl={true}
        id={id}
        {...others}
      />
    </Dialog>
  )
}

DialogEditMedia.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DialogEditMedia;
