import { Box, Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'

const RootBox = getStyled(Box, ({ theme, styled }) => ({
    flexDirection: 'column',
    alignItems: 'normal',
    backgroundColor: '#ffffff',
}))

function ListItemSelect (props) {

  const { item, itemKey, selectedItemsKeys, ...others } = props;

  !item[itemKey] && console.warn("List item must have '"+[itemKey]+"' key.")
  const checked = selectedItemsKeys.filter((selectedItemKey) => JSON.stringify(selectedItemKey) === JSON.stringify(item[itemKey])).length > 0

  return (
    <RootBox>
      <Checkbox checked={checked} {...others} />
    </RootBox>
  )
}

ListItemSelect.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  itemKey: PropTypes.string.isRequired,
  selectedItemsKeys: PropTypes.array.isRequired,
};

ListItemSelect.defaultProps = {
  selected: false,
};

export default ListItemSelect;
