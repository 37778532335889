import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id_document, id, params) {
    return API.get('/documents/' + id_document + '/attachments/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (id_document, params) {
    return API.get('/documents/' + id_document + '/attachments/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async upload (id_document, formData, onUploadProgress) {
    return API.post('/documents/' + id_document + '/attachments/', formData, { headers: { "Content-Type": "multipart/form-data" }, onUploadProgress})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id_document, id, data) {
    return API.post('/documents/' + id_document + '/attachments/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id_document, id) {
    return API.delete('/documents/' + id_document + '/attachments/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getBase64 (id_document, id, params = {}) {
    params.base64 =  true
    return API.get('/documents/' + id_document + '/attachments/' + id + '/download', { params })
      .catch(error => Promise.reject(error.response));
  },
  async download (id_document, id, params) {
    return API.get('/documents/' + id_document + '/attachments/' + id + '/download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
  async bulk_download (id_document, params) {
    return API.get('/documents/' + id_document + '/attachments/bulk-download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
