import OneSignal from 'react-onesignal';

/****************************************************
 Interface avec l'API relative aux notifications
****************************************************/

const exportedObject = {
  /**
   * Demande à l'utilisateur s'il veut être notifié par WebPush
   */
  showPrompt () {
    OneSignal && OneSignal.showNativePrompt();
  },
  /**
   * Indique au service de notification l'ID de notre utilisateur
   */
  onUserLoggedIn (id_user) {
    if (id_user) {
      OneSignal && OneSignal.setExternalUserId(id_user.toString()).then(() => {
        console.log("OneSignal.setExternalUserId to : ", id_user);
      }).catch((e) => {
        console.error("OneSignal.setExternalUserId Error : ", e);
      });
    } else {
      console.error("OneSignal.setExternalUserId Error : id_user is null");
    }
  },
  /**
   * Retire du service de notification l'ID de notre utilisateur
   */
  onUserLoggedOut () {
    OneSignal && OneSignal.removeExternalUserId().then(() => {
      console.log("OneSignal.setExternalUserId removed");
    }).catch((e) => {
      console.error("OneSignal.setExternalUserId Error : ", e);
    });
  }
};
export default exportedObject;
