import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/invitations/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getByToken (token, params) {
    return API.get('/invitations/tk/' + token, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (id, params) {
    return API.get('/invitations/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (data) {
    return API.post('/invitations/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id, data) {
    return API.post('/invitations/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async accountExists (params) {
    return API.get('/invitations/accountExists', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async accept (token, data) {
    return API.post('/invitations/accept/' + token, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async refuse (token) {
    return API.post('/invitations/refuse/' + token, null)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async request (data) {
    return API.post('/invitations/request/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  }
}

export default exportedObject;
