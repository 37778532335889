import ProjectUserRels_API from './api/projectUserRels'

/****************************************************
 Interface avec l'API relative aux projets
****************************************************/

const exportedObject = {

  /**
   * Constantes
   */
  ROLE_ADMINISTRATOR: 'administrator', // administrateur du project
  ROLE_ASSISTANT: 'assistant', // assistant du project
  ROLE_MANAGER: 'manager', // conducteur du project
  ROLE_CONSULTANT: 'consultant', // consultant du project
  ROLE_COMMERCIAL: 'commercial', // commercial du project
  ROLE_WORKER: 'worker', // professionnel du project
  ROLE_CUSTOMER: 'customer', // client du project

  /**************** projects ************************************/
  /**
   * Récupère les relations entre un projet et un utilisateur du serveur
   * @return Promise(data, notices) : les relations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      ProjectUserRels_API.getAll(args).then(({data, notices}) => {
        console.log('Relations récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une relation entre un projet et un utilisateur du serveur
   * @return Promise(data, notices) : la relation
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      ProjectUserRels_API.get(id, args).then(({data, notices}) => {
        console.log('Relation récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime une relation entre un projet et un utilisateur
   * @return Promise(id, data, notices) : l'identifiant de la relation supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      ProjectUserRels_API.delete(id).then(({data, notices}) => {
        console.log('Relation supprimée : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Renvoi le libellé du role
   * @param {string} slug 
   */
  getRoleLabel(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.ROLE_ADMINISTRATOR: {
        return 'Administeur/trice';
      }
      case this.ROLE_ASSISTANT: {
        return 'Assistant/e';
      }
      case this.ROLE_MANAGER: {
        return 'Conducteur/trice';
      }
      case this.ROLE_COMMERCIAL: {
        return 'Commercial/e';
      }
      case this.ROLE_CONSULTANT: {
        return 'Consultant/e';
      }
      case this.ROLE_WORKER: {
        return 'Professionnel/le';
      }
      case this.ROLE_CUSTOMER: {
        return 'Client/e';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Retourne l'avatar de la relation
   * @param {object} project 
   * @returns 
   */
  getAvatar (project) {
    return null
  },
};
export default exportedObject;
