import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import projectsService from './../../services/projects'

import ConfirmDialog from './../commons/ConfirmDialog'
import BackdropLoading from './../commons/BackdropLoading'

function DialogSetStateProjectValidated (props) {

  const { id, open, onSaved, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation((state) => {
    return projectsService.setState(id, state)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['projects'])
      onSaved(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title="Validation du projet ?"
        message="Les utilisateurs associés au projet et les utilisateurs cibles des documents du projet vont être notifiés. Une fois validé, vous allez pouvoir associer des professionnels au projet. Cette action est irréversible. NOTE : seuls les documents publiés sont traités."
        onConfirm={ (e) => {
          mutate(projectsService.STATE_VALIDATED)
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogSetStateProjectValidated.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogSetStateProjectValidated;
