import Auth_API from './api/auth';
import webpushService from './webpush';
import { store } from './store';
import { setToken, clearToken, setRefreshToken, clearRefreshToken, setUser, clearUser } from './store/features/authSlice';

const exportedObject = {
  refreshToken () {
    return new Promise((resolve, reject) => {
      let refresh_token = this.getRefreshToken();
      if (refresh_token && refresh_token !== '') {
        // try to refresh token
        Auth_API.refreshToken(refresh_token).then(({data, notices}) => {
          console.log('refreshToken - data : ', data, 'avertissements : ', notices);
          if (data.token && data.refresh_token && data.user) {
            this.setTokens(data.token, data.refresh_token, data.user);
            resolve();
          } else {
            this.logOut();
          }
        }).catch((error) => {
          reject(Error('Authentification impossible'));
          this.logOut();
        });
      } else {
        reject(Error('Authentification impossible'));
        this.logOut();
      }
    });
  },
  logIn (login, password) {
    return new Promise((resolve, reject) => {
      Auth_API.login(login, password).then(({data, notices}) => {
        console.log('logIn - data : ', data, 'avertissements : ', notices);
        if (data.token && data.refresh_token && data.user) {
          this.setTokens(data.token, data.refresh_token, data.user);
          webpushService.onUserLoggedIn(data.user.ID);
          resolve();
        }
        reject(Error('Identifiants incorrects'));
      }).catch((error) => {
        reject(error);
      });
    });
  },
  logInByInvitation (invitationToken) {
    return new Promise((resolve, reject) => {
      Auth_API.logInByInvitation(invitationToken).then(({data, notices}) => {
        console.log('logInByInvitation - data : ', data, 'avertissements : ', notices);
        if (data.token && data.refresh_token && data.user) {
          this.setTokens(data.token, data.refresh_token, data.user);
          webpushService.onUserLoggedIn(data.user.ID);
          resolve();
        }
        reject(Error('Token d\'authentification incorrect'));
      }).catch((error) => {
        reject(error);
      });
    });
  },
  logOut () {
    this.removeTokens();
    // Important, lorsque l'utilisateur se déconnecte, on retire sa souscription aux notifications push.
    webpushService.onUserLoggedOut();
  },
  lostPassword(login) {
    return new Promise((resolve, reject) => {
      Auth_API.lostPassword(login).then(({data, notices}) => {
        console.log('lostPassword - data : ', data, 'avertissements : ', notices);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  renewPassword(token, password, password_confirm) {
    return new Promise((resolve, reject) => {
      Auth_API.renewPassword(token, password, password_confirm).then(({data, notices}) => {
        console.log('renewPassword - data : ', data, 'avertissements : ', notices);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  getToken () {
    let storeState = store.getState();
    return storeState.auth.token;
  },
  getRefreshToken () {
    let storeState = store.getState();
    return storeState.auth.refresh_token;
  },
  setUser (user) {
    store.dispatch(setUser(user));
  },
  setTokens (token, refresh_token, user) {
    this.setUser(user);
    store.dispatch(setRefreshToken(refresh_token));
    store.dispatch(setToken(token));
  },
  removeTokens () {
    store.dispatch(clearUser());
    store.dispatch(clearRefreshToken());
    store.dispatch(clearToken());
  }
};

export default exportedObject;
