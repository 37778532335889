import PropTypes from 'prop-types'

import EditProjectMonitoring from './../edit/EditProjectMonitoring'
import Dialog from './Dialog'

function DialogEditProjectMonitoring (props) {

  const { title, open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={title}
    >
      <EditProjectMonitoring
        fixedCtrl={true}
        {...others}
      />
    </Dialog>
  )
}

DialogEditProjectMonitoring.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
}

DialogEditProjectMonitoring.defaultProps = {
  title: "Entrée du suivi de chantier",
}

export default DialogEditProjectMonitoring
