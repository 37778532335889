import { Fragment, useState, useEffect } from 'react';
import * as serviceWorkerRegistration from './../../serviceWorkerRegistration';
import utilsService from './../../services/utils';
import packageJson from './../../package.alias.json';
import compareVersions from 'compare-versions';
import { useSelector } from 'react-redux';
import BackdropLoading from './../commons/BackdropLoading';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { RenewIcon } from './../commons/Icons';

export default function AppUpdateController (props) {

  const [loading, setLoading] = useState(false)
  const [waiting, setWaiting] = useState(false)
  const [showUpdateBanner, setShowUpdateBanner] = useState(false)

  const { apiMinimalVersion } = useSelector(state => ({
    apiMinimalVersion: state.app.api_minimal_version
  }))

  const update = () => {
    /*
     * On reload l'app en vidant les caches et en notifiant le service worker
     * si il a une mise à jour en attente.
     */
    setLoading(true)
    utilsService.reloadApp()
  }

  const wait = () => {
    setWaiting(true)
    setTimeout(() => {
      setWaiting(false)
    }, 15000)
  }

  useEffect(() => {

    let swRegUpdateInterval = null;
    let swRegUpdateFound = false;

    const survey = () => {
      if (!swRegUpdateInterval) {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistration().then(registration => {
            if (registration) {
              swRegUpdateInterval = setInterval(() => {
                if (!swRegUpdateFound) {
                  registration.update()
                }
              }, 30000);
              // On écoute si une mise à jour du Service Worker est trouvée
              registration.addEventListener('updatefound', () => {
                // console.log("SW update found !!!")
                // On stoppe la recherche de mise à jour
                swRegUpdateFound = true
                clearInterval(swRegUpdateInterval)
                setShowUpdateBanner(true)
              });
              // On écoute si une mise à jour du Service Worker est faite
              registration.addEventListener('updated', () => {
                // console.log("SW updated !!!")
              });
            }
          });
        }
      }
    }

    serviceWorkerRegistration.register({
      onSuccess: (registration) => {
        // SW installé (ou mis à jour), on surveille les nouvelles mise à jours
        survey()
      },
    });

    survey()

    return () => {
      if (swRegUpdateInterval) {
        clearInterval(swRegUpdateInterval)
      }
      swRegUpdateFound = false
    }

  }, [])

  /**
   * À chaque appel à l'API, le serveur nous renvoit une version minimale de compatibilité de l'application.
   * Si cette version de l'application n'est pas compatible, on demande à l'utilisateur de mettre à jour l'app.
   * NOTE : bien entendu, il est convenu que Bridger propose toujours une version compatible de l'app avec son API,
   */
  let appVersionDeprecated = false
  if (apiMinimalVersion && apiMinimalVersion !== undefined && apiMinimalVersion !== null && apiMinimalVersion !== '') {
    if (compareVersions(packageJson.version, apiMinimalVersion) < 0) {
      appVersionDeprecated = true
    }
  }

  const openUpdateBanner = (showUpdateBanner || appVersionDeprecated) && !waiting

  return (
    <Fragment>
      <BackdropLoading open={loading} />
      {props.children}
      <Dialog
        open={openUpdateBanner}
        onClose={ (e, reason) => {
          if (reason !== 'backdropClick') {
            this.wait()
          }
        } }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Une mise à jour est disponible..."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Si certaines actions en cours ne sont pas sauvegardées, vous pouvez repousser la mise à jour. Sinon il est conseillé de mettre à jour l'application pour obtenir les dernières nouveautés et éviter tout bug.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => wait() } color="primary">
            Plus tard
          </Button>
          <Button variant="contained" color="primary" endIcon={<RenewIcon />} onClick={ () => update() }>
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
