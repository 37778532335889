import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import projectsService from './../../services/projects'

import ConfirmDialog from './../commons/ConfirmDialog'
import BackdropLoading from './../commons/BackdropLoading'

function DialogSetStateProjectClosed (props) {

  const { id, open, onSaved, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation((state) => {
    return projectsService.setState(id, state)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['projects'])
      onSaved(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title="Fermeture du projet ?"
        message="Le projet va être fermé, le système de tickets de support aussi. Cette action est irréversible."
        onConfirm={ (e) => {
          mutate(projectsService.STATE_CLOSED)
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogSetStateProjectClosed.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogSetStateProjectClosed;
