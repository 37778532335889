import Projects_API from './api/projects';
import logoBrandHelloMaison from './../static/img/logo-hello-maison.svg';
import logoBrandVillaCreative from './../static/img/logo-villa-creative.svg';
import logoBrandMaisonStily from './../static/img/logo-maison-stily.svg';
import logoBrandLexa from './../static/img/logo-lexa.svg';
import logoBrandPanosolR from './../static/img/logo-panosol-r.svg';
import logoBrandParkimao from './../static/img/logo-parkimao.svg';
import logoBrandRivaom from './../static/img/logo-rivaom.svg';

/****************************************************
 Interface avec l'API relative aux projets
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_INITIAL: 'initial', // projet en préparation
  STATE_VALIDATED: 'validated', // projet validé
  STATE_PROCESSING: 'processing', // projet en production
  STATE_DONE: 'done', // projet terminé
  STATE_CLOSED: 'closed', // projet fermé
   
  BRAND_HELLO_MAISON: 'hello-maison', // marque Hello Maison
  BRAND_VILLA_CREATIVE: 'villa-creative', // marque Villa Creative
  BRAND_MAISON_STILY: 'maison-stily', // marque Maison stily
  BRAND_LEXA: 'lexa', // marque Lexa
  BRAND_PANOSOL_R: 'panosol-r', // marque Panosol'R
  BRAND_PARKIMAO: 'parkimao', // marque Parkimao
  BRAND_RIVAOM: 'rivaom', // marque Rivaom

  /**************** projects ************************************/
  /**
   * Récupère les projets du serveur
   * @return Promise(data, notices) : les projets
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Projects_API.getAll(args).then(({data, notices}) => {
        console.log('Projets récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un projet du serveur
   * @return Promise(data, notices) : le projet
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Projects_API.get(id, args).then(({data, notices}) => {
        console.log('Projet récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau projet
   * @return Promise(data, notices) : l'identifiant du projet créé
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Projects_API.create(data).then(({data, notices}) => {
        console.log('Projet créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un projet
   * @return Promise(data, notices) : l'identifiant du projet mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Projects_API.update(id, data).then(({data, notices}) => {
        console.log('Projet mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un projet
   * @return Promise(id, data, notices) : l'identifiant du projet supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Projects_API.delete(id).then(({data, notices}) => {
        console.log('Projet supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** project state ************************************/
  /**
   * Met à jour le statut d'un projet
   * @return Promise(data, notices) : l'identifiant du projet mis à jour
   */
  setState (id, state, state_comment = '') {
    return new Promise((resolve, reject) => {
      Projects_API.setState(id, state, state_comment).then(({data, notices}) => {
        console.log('Statut de projet mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_INITIAL: {
        return 'initial';
      }
      case this.STATE_VALIDATED: {
        return 'validé';
      }
      case this.STATE_PROCESSING: {
        return 'en production';
      }
      case this.STATE_DONE: {
        return 'terminé';
      }
      case this.STATE_CLOSED: {
        return 'fermé';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Affiche le libellé du statut d'un projet
   * @return string
   */
  getProjectStateLabel (project) {
    if (!project) {
      return 'aucun';
    }
    return this.getStateLabel(project.state);
  },
  /**
   * Renvoi le slug de la couleur du statut du projet
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_INITIAL: {
        return 'project_state_initial';
      }
      case this.STATE_VALIDATED: {
        return 'project_state_validated';
      }
      case this.STATE_PROCESSING: {
        return 'project_state_processing';
      }
      case this.STATE_DONE: {
        return 'project_state_done';
      }
      case this.STATE_CLOSED: {
        return 'project_state_closed';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut du projet
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * @return string
   */
  getProjectStateColor (project) {
    if (!project) {
      return null;
    }
    return this.getStateColor(project.state);
  },
  /**
   * Indique si la projet est en statut 'initial'
   * @return boolean
   */
  isStateInitial(project) {
    if (project && !project.state) {
      console.warn("isStateInitial - la projet n'a pas de statut");
    }
    return project && project.state && project.state === this.STATE_INITIAL ? true : false;
  },
  /**
   * Indique si la projet est en statut 'validé'
   * @return boolean
   */
  isStateValidated(project) {
    if (project && !project.state) {
      console.warn("isStateValidated - la projet n'a pas de statut");
    }
    return project && project.state && project.state === this.STATE_VALIDATED ? true : false;
  },
  /**
   * Indique si la projet est en statut 'en traitement'
   * @return boolean
   */
  isStateProcessing(project) {
    if (project && !project.state) {
      console.warn("isStateProcessing - la projet n'a pas de statut");
    }
    return project && project.state && project.state === this.STATE_PROCESSING ? true : false;
  },
  /**
   * Indique si la projet est en statut 'terminé'
   * @return boolean
   */
  isStateDone(project) {
    if (project && !project.state) {
      console.warn("isStateDone - la projet n'a pas de statut");
    }
    return project && project.state && project.state === this.STATE_DONE ? true : false;
  },
  /**
   * Indique si la projet est en statut 'fermé'
   * @return boolean
   */
  isStateClosed(project) {
    if (project && !project.state) {
      console.warn("isStateClosed - la projet n'a pas de statut");
    }
    return project && project.state && project.state === this.STATE_CLOSED ? true : false;
  },
  /**
   * Retourne l'avatar du projet
   * @param {object} project 
   * @returns 
   */
  getAvatar (project) {
    return null
  },
  /**
   * Renvoi le libellé de la marque de projet
   * @param {string} slug 
   */
  getBrandLabel(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.BRAND_HELLO_MAISON: {
        return 'Hello Maison';
      }
      case this.BRAND_VILLA_CREATIVE: {
        return 'Villa Créative';
      }
      case this.BRAND_MAISON_STILY: {
        return 'Maison Stily';
      }
      case this.BRAND_LEXA: {
        return 'Lexa';
      }
      case this.BRAND_PANOSOL_R: {
        return 'Panosol\'R';
      }
      case this.BRAND_PARKIMAO: {
        return 'Parkimao';
      }
      case this.BRAND_RIVAOM: {
        return 'Rivaom';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoi le logo (le 'src') de la marque de projet
   * @param {string} slug 
   */
  getBrandLogoSrc(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.BRAND_HELLO_MAISON: {
        return logoBrandHelloMaison;
      }
      case this.BRAND_VILLA_CREATIVE: {
        return logoBrandVillaCreative;
      }
      case this.BRAND_MAISON_STILY: {
        return logoBrandMaisonStily;
      }
      case this.BRAND_LEXA: {
        return logoBrandLexa;
      }
      case this.BRAND_PANOSOL_R: {
        return logoBrandPanosolR;
      }
      case this.BRAND_PARKIMAO: {
        return logoBrandParkimao;
      }
      case this.BRAND_RIVAOM: {
        return logoBrandRivaom;
      }
      default: {
        return slug;
      }
    }
  }
};
export default exportedObject;
