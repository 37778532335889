import React from 'react'
import clsx from 'clsx'

import MuiAddIcon from '@mui/icons-material/Add'
import MuiArrowDropDown from '@mui/icons-material/ArrowDropDown'
import MuiArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import MuiArrowRightIcon from '@mui/icons-material/ArrowRight'
import MuiArrowTopIcon from '@mui/icons-material/ArrowDropUp'
import MuiArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import MuiArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import MuiAssignmentIcon from '@mui/icons-material/Assignment'
import MuiAssistantDirectionIcon from '@mui/icons-material/AssistantDirection'
import MuiAttachFileIcon from '@mui/icons-material/AttachFile'
import MuiAutorenewIcon from '@mui/icons-material/Autorenew'
import MuiCalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import MuiCheckIcon from '@mui/icons-material/Check'
import MuiClearIcon from '@mui/icons-material/Clear'
import MuiCloseIcon from '@mui/icons-material/Close'
import MuiCollectionsIcon from '@mui/icons-material/Collections'
import MuiContentCopyIcon from '@mui/icons-material/ContentCopy'
import MuiDeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MuiDownloadIcon from '@mui/icons-material/Download'
import MuiEditIcon from '@mui/icons-material/Edit'
import MuiEmailIcon from '@mui/icons-material/Email'
import MuiEngineeringIcon from '@mui/icons-material/Engineering'
import MuiErrorIcon from '@mui/icons-material/Error'
import MuiGestureOutlinedIcon from '@mui/icons-material/GestureOutlined'
import MuiGroupIcon from '@mui/icons-material/Group'
import MuiHomeIcon from '@mui/icons-material/Home'
import MuiInfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MuiInsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import MuiLaunchIcon from '@mui/icons-material/Launch'
import MuiLocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
import MuiLogoutIcon from '@mui/icons-material/Logout'
import MuiListIcon from '@mui/icons-material/List'
import MuiNoteAltIcon from '@mui/icons-material/NoteAlt'
import MuiMessageOutlinedIcon from '@mui/icons-material/MessageOutlined'
import MuiMoreVertIcon from '@mui/icons-material/MoreVert'
import MuiPersonIcon from '@mui/icons-material/Person'
import MuiPhoneIcon from '@mui/icons-material/Phone'
import MuiPhotoIcon from '@mui/icons-material/Photo'
import MuiHouseSidingIcon from '@mui/icons-material/HouseSiding'
import MuiNotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import MuiRefreshIcon from '@mui/icons-material/Refresh'
import MuiSaveIcon from '@mui/icons-material/Save'
import MuiSearchIcon from '@mui/icons-material/Search'
import MuiSendIcon from '@mui/icons-material/Send'
import MuiSettingsIcon from '@mui/icons-material/Settings'
import MuiSubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import MuiSupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import MuiTrackChangesIcon from '@mui/icons-material/TrackChanges'
import MuiClassIcon from '@mui/icons-material/Class'
import MuiVerifiedIcon from '@mui/icons-material/Verified'
import MuiVisibilityIcon from '@mui/icons-material/Visibility'
import MuiWarningAmberIcon from '@mui/icons-material/WarningAmber'
import MuiWifiOffIcon from '@mui/icons-material/WifiOff'
import MuiVideogameAssetIcon from '@mui/icons-material/VideogameAsset'

export function AddIcon (props) { return <MuiAddIcon { ...{...props, ...{className: clsx('icon-plus', props.className)}} } /> }
export function AlertIcon (props) { return <MuiWarningAmberIcon { ...{...props, ...{className: clsx('icon-alert', props.className)}} } /> }
export function ArrowBottomIcon (props) { return <MuiArrowDropDown { ...{...props, ...{className: clsx('icon-arrow-bottom', props.className)}} } /> }
export function ArrowLeftIcon (props) { return <MuiArrowLeftIcon { ...{...props, ...{className: clsx('icon-arrow-left', props.className)}} } /> }
export function ArrowRightIcon (props) { return <MuiArrowRightIcon { ...{...props, ...{className: clsx('icon-arrow-right', props.className)}} } /> }
export function ArrowTopIcon (props) { return <MuiArrowTopIcon { ...{...props, ...{className: clsx('icon-arrow-top', props.className)}} } /> }
export function ArrowEnterIcon (props) { return <MuiSubdirectoryArrowLeftIcon { ...{...props, ...{className: clsx('icon-arrow-enter', props.className)}} } /> }
export function AssistantDirectionIcon (props) { return <MuiAssistantDirectionIcon { ...{...props, ...{className: clsx('icon-arrow-top', props.className)}} } /> }
export function AttachmentIcon (props) { return <MuiAttachFileIcon { ...{...props, ...{className: clsx('icon-attach', props.className)}} } /> }
export function BellIcon (props) { return <MuiNotificationsNoneIcon { ...{...props, ...{className: clsx('icon-bell', props.className)}} } /> }
export function CalendarIcon (props) { return <MuiCalendarMonthIcon { ...{...props, ...{className: clsx('icon-check-1', props.className)}} } /> }
export function CheckIcon (props) { return <MuiCheckIcon { ...{...props, ...{className: clsx('icon-check-1', props.className)}} } /> }
export function ChevronLeftIcon (props) { return <MuiArrowBackIosNewIcon { ...{...props, ...{className: clsx('icon-chevron-left', props.className)}} } /> }
export function ChevronRightIcon (props) { return <MuiArrowForwardIosIcon { ...{...props, ...{className: clsx('icon-chevron-right', props.className)}} } /> }
export function ClearIcon (props) { return <MuiClearIcon { ...{...props, ...{className: clsx('icon-clear', props.className)}} } /> }
export function CloseIcon (props) { return <MuiCloseIcon { ...{...props, ...{className: clsx('icon-close', props.className)}} } /> }
export function CopyIcon (props) { return <MuiContentCopyIcon { ...{...props, ...{className: clsx('icon-copy', props.className)}} } /> }
export function DeleteIcon (props) { return <MuiDeleteForeverIcon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DocumentIcon (props) { return <MuiAssignmentIcon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DocumentEndorsmentIcon (props) { return <MuiNoteAltIcon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DocumentEndorsmentMemberIcon (props) { return <MuiGestureOutlinedIcon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DocumentEndorsmentFileIcon (props) { return <MuiAttachFileIcon { ...{...props, ...{className: clsx('icon-delete', props.className)}} } /> }
export function DownloadIcon (props) { return <MuiDownloadIcon { ...{...props, ...{className: clsx('icon-download', props.className)}} } /> }
export function EditIcon (props) { return <MuiEditIcon { ...{...props, ...{className: clsx('icon-edit', props.className)}} } /> }
export function EngineeringIcon (props) { return <MuiEngineeringIcon { ...{...props, ...{className: clsx('icon-engineering', props.className)}} } /> }
export function EmailIcon (props) { return <MuiEmailIcon { ...{...props, ...{className: clsx('icon-email', props.className)}} } /> }
export function ErrorIcon (props) { return <MuiErrorIcon { ...{...props, ...{className: clsx('icon-error', props.className)}} } /> }
export function EyeIcon (props) { return <MuiVisibilityIcon { ...{...props, ...{className: clsx('icon-eye', props.className)}} } /> }
export function FileIcon (props) { return <MuiInsertDriveFileIcon { ...{...props, ...{className: clsx('icon-file', props.className)}} } /> }
export function GalleryIcon (props) { return <MuiCollectionsIcon { ...{...props, ...{className: clsx('icon-filters-x3', props.className)}} } /> }
export function HomeIcon (props) { return <MuiHomeIcon { ...{...props, ...{className: clsx('icon-home', props.className)}} } /> }
export function InfoIcon (props) { return <MuiInfoOutlinedIcon { ...{...props, ...{className: clsx('icon-info', props.className)}} } /> }
export function LogoutIcon (props) { return <MuiLogoutIcon { ...{...props, ...{className: clsx('icon-logout', props.className)}} } /> }
export function MonitoringIcon (props) { return <MuiLocalPoliceOutlinedIcon { ...{...props, ...{className: clsx('icon-more-horizontal', props.className)}} } /> }
export function MoreVertIcon (props) { return <MuiMoreVertIcon { ...{...props, ...{className: clsx('icon-more-vertical', props.className)}} } /> }
export function OfflineIcon (props) { return <MuiWifiOffIcon { ...{...props, ...{className: clsx('icon-offline', props.className)}} } /> }
export function OpenIcon (props) { return <MuiLaunchIcon { ...{...props, ...{className: clsx('icon-open', props.className)}} } /> }
export function PhotoIcon (props) { return <MuiPhotoIcon { ...{...props, ...{className: clsx('icon-pdf', props.className)}} } /> }
export function PhoneIcon (props) { return <MuiPhoneIcon { ...{...props, ...{className: clsx('icon-phone', props.className)}} } /> }
export function PlusIcon (props) { return <MuiAddIcon { ...{...props, ...{className: clsx('icon-plus', props.className)}} } /> }
export function ProjectIcon (props) { return <MuiHouseSidingIcon { ...{...props, ...{className: clsx('icon-printer', props.className)}} } /> }
export function RefreshIcon (props) { return <MuiAutorenewIcon { ...{...props, ...{className: clsx('icon-refresh-1', props.className)}} } /> }
export function RenewIcon (props) { return <MuiRefreshIcon { ...{...props, ...{className: clsx('icon-refresh-2', props.className)}} } /> }
export function SaveIcon (props) { return <MuiSaveIcon { ...{...props, ...{className: clsx('icon-save', props.className)}} } /> }
export function SearchIcon (props) { return <MuiSearchIcon { ...{...props, ...{className: clsx('icon-search', props.className)}} } /> }
export function SelectionIcon (props) { return <MuiListIcon { ...{...props, ...{className: clsx('icon-selection', props.className)}} } /> }
export function SendIcon (props) { return <MuiSendIcon { ...{...props, ...{className: clsx('icon-send', props.className)}} } /> }
export function SettingsIcon (props) { return <MuiSettingsIcon { ...{...props, ...{className: clsx('icon-settings', props.className)}} } /> }
export function SupportTicketIcon (props) { return <MuiSupportOutlinedIcon { ...{...props, ...{className: clsx('icon-support-ticket', props.className)}} } /> }
export function SupportTicketMessageIcon (props) { return <MuiMessageOutlinedIcon { ...{...props, ...{className: clsx('icon-support-ticket-message', props.className)}} } /> }
export function TargetIcon (props) { return <MuiTrackChangesIcon { ...{...props, ...{className: clsx('icon-target', props.className)}} } /> }
export function UserIcon (props) { return <MuiPersonIcon { ...{...props, ...{className: clsx('icon-user', props.className)}} } /> }
export function UsersIcon (props) { return <MuiGroupIcon { ...{...props, ...{className: clsx('icon-users', props.className)}} } /> }
export function VerifiedIcon (props) { return <MuiVerifiedIcon { ...{...props, ...{className: clsx('icon-verified', props.className)}} } /> }
export function WorkerCatIcon (props) { return <MuiClassIcon { ...{...props, ...{className: clsx('icon-woodkit', props.className)}} } /> }
export function TestIcon (props) { return <MuiVideogameAssetIcon { ...{...props, ...{className: clsx('icon-test', props.className)}} } /> }
