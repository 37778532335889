import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import OneSignal from 'react-onesignal';
import { setWebPushNotificationsSupported, setWebPushNotificationsEnabled } from './../../services/store/features/appSlice';

/**
 * WebPush Notifications service controller - based on OneSignal service
 */
export default function WebPushNotificationsController(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * Action lorsque l'utilisateur click sur une notification
   */
  const onNotificationClicked = (notification) => {
    if (notification && notification.url) {
      if (notification.url.startsWith(process.env.REACT_APP_MAIN_BASE_URL)) {
        let route = notification.url.replace(process.env.REACT_APP_MAIN_BASE_URL, "");
        if (!route.startsWith("/")) {
          route = "/" + route; // important to navigate from root location
        }
        navigate(route);
      } else {
        console.warn("Une notification demande à naviguer vers une URL externe à l'application : ", notification.url);
      }
    }
  }

  /**
   * Ecoute l'ouverture d'une notification reçue par l'utilisateur
   */
  function handler_notificationOpened (notification) {
    onNotificationClicked(notification);

    // https://documentation.onesignal.com/docs/web-push-sdk#addlistenerfornotificationopened-event
    // Note: This event occurs once only. If you would this to occur continuously every time a notification is clicked,
    // please call this method again after your callback fires.
    OneSignal.addListenerForNotificationOpened(handler_notificationOpened);
  };
  OneSignal.addListenerForNotificationOpened(handler_notificationOpened);

  useEffect(() => {

    /**
     * Ecoute la souscription de l'utilisateur au service de notifications WebPush
     */
    function handler_subscriptionChange (isSubscribed) {
      dispatch(setWebPushNotificationsEnabled(isSubscribed));
    };

    /**
     * ComponentDidMount
     */
    if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
      OneSignal.on('subscriptionChange', handler_subscriptionChange);
      OneSignal.init({
        // IMPORTANT : le path vers le SW ne doit jamais changer - https://documentation.onesignal.com/docs/onesignal-service-worker-faq
        // documentation d'init : https://documentation.onesignal.com/docs/web-push-sdk#init
        serviceWorkerParam: { scope: '/permanent-path/onesignal/' },
        serviceWorkerPath: 'permanent-path/onesignal/OneSignalSDKWorker.js',
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
        autoResubscribe: true,
        notificationClickHandlerMatch: 'origin',
        notificationClickHandlerAction: 'focus'
      }).then(() => {
        if ('Notification' in window) {
          dispatch(setWebPushNotificationsSupported(true));
          OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
            dispatch(setWebPushNotificationsEnabled(isEnabled));
          });
        } else {
          dispatch(setWebPushNotificationsSupported(false));
        }
      });
    }

    /**
     * ComponentWillUnmount
     */
    return () => {
      if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
        OneSignal.off('subscriptionChange', handler_subscriptionChange);
      }
    }
  });

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}
