import { getStyled } from '../../services/styles'
import PropTypes from 'prop-types'

import Query from './../commons/Query'
import MuiListItem from './ListItem'
import MuiList from '@mui/material/List'

const ListItems = getStyled(MuiList, ({theme}) => ({
  padding: theme.spacing(0),
}))

const ListItem = getStyled(MuiListItem, ({theme}) => ({}))

function List (props) {

  const { items, renderItem, divider, ...others } = props

  const renderList = (items) => {
    return (
      <ListItems>
        {items.map((item, index) => {
          const withDivider = divider && index < (items.length - 1)
          return (
            <ListItem key={index} disableGutters={true} divider={withDivider}>
              { renderItem({item, first: index === 0, last: index === items.length - 1}) }
            </ListItem>
          )
        })}
      </ListItems>
    )
  }

  if (items) {
    return renderList(items)
  }

  return (
    <Query
      {...others}
      renderItems={(items) => {
        if (!items || items.length < 1) {
          return null
        }
        return renderList(items)
      }}
    />
  )

}

List.propTypes = {
  items: PropTypes.array, // dans le cas d'une liste statique, sinon Query est utilisé
  renderItem: PropTypes.func.isRequired,
  divider: PropTypes.bool,
};

List.defaultProps = {
  divider: true,
};

export default List;