import Invitations_API from './api/invitations';

/****************************************************
 Interface avec l'API relative aux utilisateurs
****************************************************/

const exportedObject = {

  /**
   * Constantes
   */
  TYPE_USER: 'user', // invitation d'un utilisateur collaborateur
 	TYPE_CUSTOMER: 'customer', // invitation d'un client
 	TYPE_PROVIDER: 'provider', // invitation d'un fournisseur

 	STATE_ACCEPTED: 'accepted', // invitation acceptée
 	STATE_PENDING: 'pending', // invitation en cours
 	STATE_ERROR: 'error', // invitation en erreur

  /**
   * Récupère une invitation du serveur
   * @return Promise(data) : l'invitation'
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Invitations_API.get(id, args).then(({data, notices}) => {
        console.log('Invitation récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
 getByToken (token, args) {
   return new Promise((resolve, reject) => {
     Invitations_API.getByToken(token, args).then(({data, notices}) => {
       console.log('Invitation récupérée par token : ', data, 'avertissements : ', notices);
       resolve([data, notices]);
     }).catch((error) => {
       reject(error);
     });
   });
 },
  /**
   * Récupère les invitations du serveur
   * @return Promise(data) : les invitations
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Invitations_API.getAll(args).then(({data, notices}) => {
        console.log('Invitations récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée une invitation
   * @return Promise()
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Invitations_API.create(data).then(({data, notices}) => {
        console.log('Invitation créée', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour une invitation
   * @return Promise(data) : l'invitation mise à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Invitations_API.update(id, data).then(({data, notices}) => {
        console.log('Invitation mise à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Vérifie si l'utilisateur avec cet email existe
   * @return Promise(data) : true/false
   */
   accountExists (args) {
    return new Promise((resolve, reject) => {
      Invitations_API.accountExists(args).then(({data, notices}) => {
        console.log('Compte existe : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  accept (token, data) {
    return new Promise((resolve, reject) => {
      Invitations_API.accept(token, data).then(({data, notices}) => {
        console.log('Invitation acceptée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  refuse (token) {
    return new Promise((resolve, reject) => {
      Invitations_API.refuse(token).then(({data, notices}) => {
        console.log('Invitation refusée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  request (data) {
    return new Promise((resolve, reject) => {
      Invitations_API.request(data).then(({data, notices}) => {
        console.log('Demande d\'invitation : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  }
};
export default exportedObject;
