import { getStyled } from './../../services/styles'
import PropTypes from 'prop-types'

import { RenewIcon } from './Icons'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import IconButton from '@mui/material/IconButton'

const MyBox = getStyled(Box, ({theme, styled}) => ({
  padding: styled.gutter ? theme.spacing(2) : '',
  textAlign: 'center',
  color: '#555555'
}));

function Loading (props) {
  const { loading, refetch, error, gutter, linear, ...others } = props
  if (error) {
    return (
      <MyBox styled={{gutter}} {...others}>
        { error && error !== true ? error : <Typography variant="body1">Erreur de chargement</Typography> }
        <IconButton onClick={() => refetch && refetch()} size="large">
          <RenewIcon />
        </IconButton>
      </MyBox>
    )
  }
  return (
    <MyBox styled={{gutter}} {...others}>
      { loading || (linear ? <LinearProgress color="tiertiary" /> : <CircularProgress color="tiertiary" size={30} />) }
    </MyBox>
  )
}

Loading.propTypes = {
  loading: PropTypes.element,
  error: PropTypes.any,
  refetch: PropTypes.func,
  gutter: PropTypes.bool,
  linear: PropTypes.bool,
}

Loading.defaultProps = {
  gutter: false,
  linear: false,
}

export default Loading;
