import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const RootBox = getStyled(Box, ({theme, styled}) => {
  let style = {
    width: '100%',
    padding: theme.spacing(3, 2),
    border: '1px dashed #cccccc',
    borderRadius: '12px',
    position: 'relative',
  }
  if (styled?.dragActive) {
    style.borderColor = '#999999'
    style.backgroundColor = 'rgba(0,0,0,0.05)'
  }
  return style;
})

const DragAndDropLabel = getStyled(Typography, ({theme}) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  display: 'block',
  width: '100%',
  color: '#999999',
}))

const DragAndDropSepLabel = getStyled(Typography, ({theme}) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  display: 'block',
  width: '100%',
  color: '#999999',
}))

const DropBox = getStyled(Box, ({theme}) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  borderRadius: '1rem',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}))

function SetFileUpload (props) {

  const { onChange, onError, label, labelDrag, labelDrop, labelSep, accept, ...others } = props

  const buttonRef = useRef()
  const inputRef = useRef()

  // Drag&Drop - try https://www.codemzy.com/blog/react-drag-drop-file-upload

  // drag state
  const [dragActive, setDragActive] = useState(false)
    
  // handle drag events
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  // triggers when file is dropped
  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleFiles(e.dataTransfer.files);
  }

  // triggers when file is selected with click
  const handleChange = function(e) {
    e.preventDefault();
    e.stopPropagation();
    handleFiles(e.target.files);
  }

  // triggers when files are choosen
  const handleFiles = function (files) {
    if (!files || files.length < 1) {
      return
    }
    const file = files[0]
    if (!file) {
      return
    }

    // Le drag and drop ne permet pas de profiter de la vérification que le MimeType du fichier déposé est bien cohérent avec l'attribut 'accept' du input
    // On doit donc le faire manuellement
    // NOTE : l'attribut 'accept' d'un input accepte le format 'image/*' on le parse donc pour obtenir une expression régulière
    // console.log("file.type : ", file.type);
    // console.log("accept : ", accept);
    const accepRegexp = accept ? accept.replace('*', '.*') : '';
    if (file.type && file.type.match(new RegExp(accepRegexp))) {
      onChange(file)
    } else {
      onError("Format invalide")
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  }

  return (

    <RootBox onDragEnter={handleDrag} styled={{dragActive}}>

      <input ref={inputRef} type="file" hidden accept={accept} onChange={handleChange} />
      
      <DragAndDropLabel component="label">{dragActive ? labelDrop : labelDrag}</DragAndDropLabel>

      <DragAndDropSepLabel component="div">{labelSep}</DragAndDropSepLabel>

      <Button disabled={dragActive} ref={buttonRef} variant="outlined" component="label" {...others} onClick={onButtonClick}>
        { label }
      </Button>

      { dragActive && <DropBox onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></DropBox> }

    </RootBox>
  )
}

SetFileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelDrag: PropTypes.string,
  labelDrop: PropTypes.string,
  labelSep: PropTypes.string,
  accept: PropTypes.string,
}

SetFileUpload.defaultProps = {
  label: "Choisir un fichier",
  labelDrag: "Glisser un fichier",
  labelDrop: "Lâcher",
  labelSep: "ou",
}

export default SetFileUpload
