import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import documentsService from './../../services/documents'

import ConfirmDialog from './../commons/ConfirmDialog'
import BackdropLoading from './../commons/BackdropLoading'

function DialogSetStateDocumentValidated (props) {

  const { id, open, onSaved, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation((state) => {
    return documentsService.setState(id, state)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['documents'])
      onSaved(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title="Publication du document ?"
        message="Il ne sera plus modifiable et les utilisateurs ciblés seront notifiés. NOTE : en fonction du statut actuel du chantier, il se peut que les notifications ne soient envoyées qu'au changement de statut."
        onConfirm={ (e) => {
          mutate(documentsService.STATE_VALIDATED)
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogSetStateDocumentValidated.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onSaved: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogSetStateDocumentValidated;
