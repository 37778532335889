import PropTypes from 'prop-types'

import EditInvitation from './../edit/EditInvitation'
import Dialog from './Dialog'

function DialogEditInvitation (props) {

  const { id, open, onClose, fullScreen, keepMounted, ...others } = props;

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title="Invitation"
    >
      <EditInvitation
        fixedCtrl={true}
        id={id}
        {...others}
      />
    </Dialog>
  )
}

DialogEditInvitation.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DialogEditInvitation;
