import PropTypes from 'prop-types'

import SupportTicketMessageMedias from './../support-ticket-message/SupportTicketMessageMedias'
import Dialog from './Dialog'

function DialogSupportTicketMessageMedias (props) {

  const { open, onClose, fullScreen, keepMounted, ...others } = props

  return (
    <Dialog
      keepMounted={keepMounted}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title="Galerie"
      contentMaxWidth={false}
      disableGutters={true}
    >
      <SupportTicketMessageMedias
        {...others}
      />
    </Dialog>
  )
}

DialogSupportTicketMessageMedias.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default DialogSupportTicketMessageMedias
