import ProjectMonitorings_API from './api/projectMonitorings'

/****************************************************
 Interface avec l'API relative aux suivi de projets
****************************************************/

const exportedObject = {

  /**************** projectMonitorings ************************************/
  /**
   * Récupère la définition des suivis de projet du serveur
   * @return Promise(data, notices) : la définition
   */
  getDefinition (id_project, args) {
    return new Promise((resolve, reject) => {
      ProjectMonitorings_API.getDefinition(id_project, args).then(({data, notices}) => {
        console.log('Définition de suivis de projet récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les suivis de projet du serveur
   * @return Promise(data, notices) : les suivis de projet
   */
  getAll (id_project, args) {
    return new Promise((resolve, reject) => {
      ProjectMonitorings_API.getAll(id_project, args).then(({data, notices}) => {
        console.log('Suivis de projet récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un suivi de projet du serveur
   * @return Promise(data, notices) : le suivi de projet
   */
  get (id_project, slug, args) {
    return new Promise((resolve, reject) => {
      ProjectMonitorings_API.get(id_project, slug, args).then(({data, notices}) => {
        console.log('Suivi de projet récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau un suivi de projet
   * @return Promise(data, notices) : l'ID et le slug du suivi de projet créé
   */
   save (id_project, slug, data) {
    return new Promise((resolve, reject) => {
      ProjectMonitorings_API.save(id_project, slug, data).then(({data, notices}) => {
        console.log('Suivi de projet créé : ', slug, data, 'avertissements : ', notices);
        resolve([slug, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un suivi de projet
   * @return Promise(slug, data, notices) : l'ID et le slug du suivi de projet
   */
  delete (id_project, slug) {
    return new Promise((resolve, reject) => {
      ProjectMonitorings_API.delete(id_project, slug).then(({data, notices}) => {
        console.log('Suivi de projet supprimé : ', slug, data, 'avertissements : ', notices);
        resolve([slug, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Retourne l'avatar du projet
   * @param {object} project 
   * @returns 
   */
  getAvatar (project) {
    return null
  },
};
export default exportedObject;
