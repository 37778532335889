import { getStyled } from './../../services/styles'

import Box from '@mui/material/Box'

const MyBox = getStyled(Box, ({theme}) => ({
  width: '100%',
}));

export default function FormField (props) {

  const { children, styled, ...others } = props;

  return (
      <MyBox styled={{style: (styled && styled.root) ?? {}}} {...others}>
        { children }
      </MyBox>
  )
}
