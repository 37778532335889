import { useState } from 'react'
import PropTypes from 'prop-types'
import workerCatsService from './../../services/workerCats'

import List from './../commons/List'
import SearchController from '../commons/SearchController'
import Search from '../commons/Search'
import WorkerCatsListItem from './WorkerCatsListItem'
import Box from '@mui/material/Box'
import ListItemSelect from '../commons/ListItemSelect'

function WorkerCatsList(props) {

  const { query, onQueryChange, limit, searchEnabled, listItemRight, onClick, onSelect, selectedItemsKeys, itemKey, ...others } = props

  const queryKey = ['workerCats', query || 'all', `limit_${limit}`]
  const queryLoad = (pageParam) => workerCatsService.getAll({ ...query, ...{ limit, offset: (limit * pageParam) } }).then(([items]) => items)

  const [searchFocus, setSearchFocus] = useState(false)
  const renderSearch = (items, isFetching, isError, isRefetching) => {
    const searchQuery = {search: query.search, state: query.state} // IMPORTANT : on limite ici aux champs du moteur de recherche
    const disabled = isFetching || isError || isRefetching
    return (
      <SearchController
        query={searchQuery}
        onChange={(searchQuery) => {
          setSearchFocus(false)
          onQueryChange({...query, ...searchQuery})
        }}
        disabled={disabled}
        focus={searchFocus}
        onFocus={(focus) => setSearchFocus(focus)}
      >
        <Search
          query={searchQuery}
          onChange={(searchQuery) => {
            setSearchFocus(false)
            onQueryChange({...query, ...searchQuery})
          }}
          disabled={disabled}
          focus={searchFocus}
        />
      </SearchController>
    )
  }

  return (
    <Box>
      <List
        queryLoad={queryLoad}
        queryKey={queryKey}
        queryLimit={limit}
        renderItem={({item}) => {
          return (
            <WorkerCatsListItem
              workerCat={item}
              onClick={(e) => onClick ? onClick(e, item) : (onSelect && onSelect(e, item))}
              left={onSelect && <ListItemSelect onClick={(e) => onSelect(e, item)} item={item} itemKey={itemKey} selectedItemsKeys={selectedItemsKeys} />}
              right={listItemRight}
            />
          )
        }}
        renderSearch={searchEnabled ? renderSearch : undefined}
        {...others}
      />
    </Box>
  )

}

WorkerCatsList.propTypes = {
  // query
  query: PropTypes.object,
  onQueryChange: PropTypes.func,
  limit: PropTypes.number,
  // list items
  listItemRight: PropTypes.node,
  // search
  searchEnabled: PropTypes.bool,
  // click
  onClick: PropTypes.func,
  // selection
  onSelect: PropTypes.func,
  selectedItemsKeys: PropTypes.array,
  itemKey: PropTypes.string,
}

WorkerCatsList.defaultProps = {
  query: {},
  limit: 25,
  searchEnabled: true,
  selectedItemsKeys: [],
  itemKey: 'ID',
}

export default WorkerCatsList;
