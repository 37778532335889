import { getStyled } from './../../services/styles'
import { useState } from 'react'
import PropTypes from 'prop-types'
import projectUserRelsService from './../../services/projectUserRels'

import SelectUserRole from './SelectUserRole'
import SelectWorkerCat from './SelectWorkerCat'

import Grid from '@mui/material/Grid'
import { FormControl, TextField } from '@mui/material'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

function SetUserPreferences (props) {

  const { value, onChange, onError } = props
  const [selectWorkerCatQuery, setSelectWorkerCatQuery] = useState({})

  return (

    <Grid container spacing={2}>

      <Grid item xs={12}>
        <SelectUserRole
          required
          value={value?.invitation?.role || ''}
          onChange={ (selected) => onChange({...value, invitation: {...{...value?.invitation, ...{role: selected}}}}) }
          label="Rôle par défaut lors d'une invitation"
        />
      </Grid>

      { value?.invitation?.role === projectUserRelsService.ROLE_WORKER && (
        <Grid item xs={12}>
          <SelectWorkerCat
            title="Catégorie pro. par défaut lors d'une invitation"
            query={selectWorkerCatQuery}
            onQueryChange={(query) => setSelectWorkerCatQuery(query)}
            value={value?.invitation?.id_worker_cat || null}
            onChange={ (selected) => onChange({...value, invitation: {...{...value?.invitation, ...{id_worker_cat: selected}}}}) }
            onError={onError}
          />
        </Grid>
      ) }

      <Grid item xs={12}>
        <MyFormControl>
          <MyTextField
            id="planning"
            label="Planning (URL d'intégration)"
            type="url"
            value={value?.planning?.url || ''}
            onChange={ (e) => onChange({...value, planning: {...{...value?.planning, ...{url: e.target.value}}}}) }
          />
        </MyFormControl>
      </Grid>

    </Grid>
  )
}

SetUserPreferences.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
}

SetUserPreferences.defaultProps = {}

export default SetUserPreferences
