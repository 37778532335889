import usersService from './../../services/users'

import SelectAsync from './SelectAsync'
import UsersList from './../user/UsersList'
import { UserIcon } from './../commons/Icons'

/**
 * Sélecteur d'utilisateur
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 */
function SelectUser (props) {

  const { classes, ...other } = props

  const renderSelect = (onSelect, selectedItemKey) => {
    return (
      <UsersList
        onSelect={(e, item) => onSelect(item.ID)}
        onError={props.onError}
        query={props.query}
        onQueryChange={props.onQueryChange}
        limit={25}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }

  return (
    <SelectAsync
      { ...{...other, ...{
        title: props.title || "Utilisateur",
        renderSelect: renderSelect,
        label: props.label || "Aucun",
        labelIcon: props.labelIcon || <UserIcon />,
        renderSelected: (selectedItemKey, selectedObject) => selectedObject?.name || selectedItemKey,
      }} }
      queryKey="users"
      queryLoad={(query, value) => usersService.get(value).then(([item]) => item)}
    />
  )
}

export default SelectUser
