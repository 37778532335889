import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import usersService from './../../services/users'

import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import SetContact from './../commons/SetContact'
import SetUserPreferences from './../commons/SetUserPreferences'
import { SaveIcon } from './../commons/Icons'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Loading from '../commons/Loading'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

const PrefCard = getStyled(Card, ({ theme }) => ({
  width: '100%',
}))

const PrefTitleTypography = getStyled(Typography, ({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2)
}))

function EditUser(props) {

  const { id, preset, fixedCtrl, onSaved, onError } = props

  const {formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    id,
    initialData: {
      email: '',
      firstname: '',
      lastname: '',
      society: '',
      civility: usersService.CIVILITY_M,
      contact: {},
      preferences: {},
    },
    presetData: preset,
    queryKey: 'users',
    get: (id) => usersService.get(id),
    update: (id, data) => usersService.update(id, data),
    onSaved,
    onError,
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listing de l'entité
      queryClient.invalidateQueries(['users'])
    },
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    mutate(formData)
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="email"
                label="Email"
                type="email"
                value={formData.email || ''}
                onChange={(e) => mergeFormData({email: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <InputLabel id="select-label-civility">Civilité</InputLabel>
              <Select
                required
                labelId="select-label-civility"
                label="Civilité"
                id="civility"
                value={formData.civility || ''}
                onChange={(e) => mergeFormData({civility: e.target.value})}
              >
                <MenuItem value={usersService.CIVILITY_M}>{usersService.getCivilityLabel(usersService.CIVILITY_M)}</MenuItem>
                <MenuItem value={usersService.CIVILITY_MME}>{usersService.getCivilityLabel(usersService.CIVILITY_MME)}</MenuItem>
                <MenuItem value={usersService.CIVILITY_MLLE}>{usersService.getCivilityLabel(usersService.CIVILITY_MLLE)}</MenuItem>
              </Select>
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextField
                required
                id="firstname"
                label="Prénom"
                type="text"
                value={formData.firstname || ''}
                onChange={(e) => mergeFormData({firstname: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextField
                required
                id="lastname"
                label="Nom"
                type="text"
                value={formData.lastname || ''}
                onChange={(e) => mergeFormData({lastname: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="society"
                label="Société"
                type="text"
                value={formData.society || ''}
                onChange={(e) => mergeFormData({society: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <Box>
            <SetContact
              onChange={(value) => mergeFormData({contact: value})}
              value={formData.contact || {}}
              tel_required={true}
              address_1_required={false}
              address_2_required={false}
              address_cp_required={false}
              address_city_required={false}
              address_country_required={false}
            />
          </Box>

          <PrefCard>
            <CardContent>
              <PrefTitleTypography variant="h3">Préférences</PrefTitleTypography>
              <SetUserPreferences
                onChange={(value) => mergeFormData({preferences: value})}
                value={formData.preferences || {}}
                onError={onError}
              />
            </CardContent>
          </PrefCard>

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>Modifier</SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>
    </Box>
  )
}

EditUser.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditUser.defaultProps = {
  fixedCtrl: false,
};

export default EditUser;
