import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'

import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'

const MyAvatar = getStyled(Avatar, ({theme}) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  fontSize: '1rem',
  fontWeight: 400,
}))

const MyBadge = getStyled(Badge, ({theme}) => ({}))

const WrapperBox = getStyled(Box, ({theme, styled}) => {
  let style = {
    cursor: 'pointer'
  }
  let breakDownSm = {}
  if (styled && styled.gutter_right) {
    style = {...style, ...{
      paddingRight: theme.spacing(2),
    }}
    breakDownSm.paddingRight = theme.spacing(1)
  }
  if (styled && styled.gutter_left) {
    style = {...style, ...{
      paddingLeft: theme.spacing(2),
    }}
    breakDownSm.paddingLeft = theme.spacing(1)
  }
  style[theme.breakpoints.down('sm')] = breakDownSm
  return style
})

const ContentBox = getStyled(Box, ({theme, styled}) => {
  let style = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
  return style
})

const SummaryBox = getStyled(Box, ({theme, styled}) => {
  let style = {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  }
  return style
})

const SummaryAvatarBox = getStyled(Box, ({theme}) => ({
  marginRight: theme.spacing(1),
}))

const SummaryContentBox = getStyled(Box, ({theme}) => ({
  width: '100%',
}))

const LeftBox = getStyled(Box, ({theme}) => ({
  width: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const RightBox = getStyled(Box, ({theme}) => ({
  width: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const DetailsBox = getStyled(Box, ({theme}) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(5),
  width: 'calc(100% - '+theme.spacing(5)+')',
}))

function ListItemContent (props) {

  const { avatar, avatarProps, badge, badgeProps, left, right, summary, details, gutterRight, gutterLeft, onClick } = props;

  const innerAvatarProps = {};
  let summary_avatar = avatar ? (<MyAvatar {...{...innerAvatarProps, ...avatarProps}}>{ avatar !== true && avatar }</MyAvatar>) : null;

  const innerBadgeProps = { color: "primary", overlap: "circular", badgeContent: " " };
  summary_avatar = badge ? (<MyBadge {...{...innerBadgeProps, ...badgeProps}}>{ summary_avatar }</MyBadge>) : summary_avatar;

  return (
    <WrapperBox styled={{gutter_left: gutterLeft, gutter_right: gutterRight}}>
      <ContentBox styled={{hasRight: right ? true : false}} onClick={(e) => onClick && onClick(e)}>
        { left && (
          <LeftBox>
            { left }
          </LeftBox>
        ) }
        <SummaryBox styled={{}}>
          { summary_avatar && (
            <SummaryAvatarBox>
              { summary_avatar }
            </SummaryAvatarBox>
          ) }
          <SummaryContentBox>
            { summary }
          </SummaryContentBox>
        </SummaryBox>
        { right && (
          <RightBox>
            { right }
          </RightBox>
        ) }
      </ContentBox>
      { details && (
        <DetailsBox>
          { details }
        </DetailsBox>
      )}
    </WrapperBox>
  )
}

ListItemContent.propTypes = {
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  avatarProps: PropTypes.object,
  left: PropTypes.node,
  right: PropTypes.node,
  summary: PropTypes.node.isRequired,
  details: PropTypes.node,
  gutterRight: PropTypes.bool,
  gutterLeft: PropTypes.bool,
  onClick: PropTypes.func,
};

ListItemContent.defaultProps = {
  gutterRight: true,
  gutterLeft: true,
};

export default ListItemContent;
