import WorkerCats_API from './api/workerCats'

/****************************************************
 Interface avec l'API relative aux projets
****************************************************/

const exportedObject = {

  /**
   * Constantes
   */
  STATE_ACTIVE: 'active', // catégorie active
  STATE_INACTIVE: 'inactive', // catégorie inactive (ne peut être listée dans le workflow d'un projet)

  /**************** projects ************************************/
  /**
   * Récupère les catégories professionnelles du serveur
   * @return Promise(data, notices) : les catégories professionnelles
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      WorkerCats_API.getAll(args).then(({data, notices}) => {
        console.log('Catégories professionnelles récupérées : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère une catégorie professionnelle du serveur
   * @return Promise(data, notices) : la catégorie professionnelle
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      WorkerCats_API.get(id, args).then(({data, notices}) => {
        console.log('Catégorie professionnelle récupérée : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Renvoi le libellé du statut
   * @param {string} slug 
   */
  getStateLabel(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_ACTIVE: {
        return 'Active';
      }
      case this.STATE_INACTIVE: {
        return 'Inactive';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Retourne l'avatar de la catégorie professionnelle
   * @param {object} workerCat 
   * @returns 
   */
  getAvatar (workerCat) {
    return null
  },
};
export default exportedObject;
