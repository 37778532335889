import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import SetFileUpload from './SetFileUpload'
import { CloseIcon } from './Icons'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

const FileBox = getStyled(Box, ({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))

const RemoveButton = getStyled(IconButton, ({ theme }) => ({}))

function SetFile (props) {

  const { value, onChange, ...others } = props

  const addFile = (file) => {
    // On ajoute le nouveau fichier à uploader
    // on génère un ID unique pour permettre la suppression avant enregistrement,
    // on ajoute la clé 'upload' qui permettra au hook d'enregistrement de savoir que c'est un nouveau fichier à uploader
    onChange({ID: 'upload-' + Date.now(), upload: true, file})
  }

  const removeFile = (ID) => {
    onChange(null)
  }

  // un item peut être existant (c'est un objet qui n'a pas la clé 'upload')
  // un item peut être en cours de création (c'est un objet qui a la clé 'upload')

  return (

    <Grid container spacing={1}>

      { value && (
        <Grid item xs={12}>
          <FileBox>
            <RemoveButton onClick={() => removeFile(value.ID)}><CloseIcon /></RemoveButton>
            { !value.upload && (<Typography variant="body2" component="div">{value.name}</Typography>) }
            { value.upload && (<Typography variant="body2" component="div">{value.file.name} <em style={{ fontSize: 10 }}>(visible après enregistrement)</em></Typography>) }
          </FileBox>
        </Grid>
      ) }

      { !value && (
        <Grid item xs={12}>
          <SetFileUpload
            onChange={(file) => addFile(file)}
            {...others}
          />
        </Grid>
      ) }

    </Grid>
  )
}

SetFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  value: PropTypes.object,
}

export default SetFile
