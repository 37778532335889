import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import supportTicketMessagesService from './../../services/supportTicketMessages'

import BackdropLoading from './../commons/BackdropLoading'
import ConfirmDeleteDialog from '../commons/ConfirmDeleteDialog'

function DialogDeleteSupportTicket (props) {

  const { id_support_ticket, id, open, onDeleted, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation(() => {
    return supportTicketMessagesService.delete(id_support_ticket, id)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)

      // NOTE : cas particulier, on charge toujours en une seule requête les messages d'un ticket, donc il est inutile d'invalider
      // les queries du message seul
      queryClient.invalidateQueries(['support-tickets-' + id_support_ticket + '-messages'])
      
      onDeleted(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDeleteDialog
        open={open}
        title="Suppression du message ?"
        message="Les médias rattachés à ce message vont être supprimés. Cette action est irreversible."
        onConfirm={ (e) => {
          mutate()
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogDeleteSupportTicket.propTypes = {
  id_support_ticket: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogDeleteSupportTicket;
