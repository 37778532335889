import { getStyled } from './../../services/styles'
import Typography from '@mui/material/Typography';

const MainTypography = getStyled(Typography, ({theme}) => ({
  wordBreak: 'break-word',
  margin: theme.spacing(0 , 0, 0.5, 0)
}))

function ListItemContentSummary3 (props) {

  const { children, style, ...others } = props;
  const innerProps = { styled: {style}, component: "div", variant: "body3" };

  return (
    <MainTypography {...{...innerProps, ...others}}>{children}</MainTypography>
  )
}

export default ListItemContentSummary3;
