import React, {Suspense, lazy} from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import routes from './../services/routes';
import { Routes, Route } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import BackdropLoading from './../components/commons/BackdropLoading';
import BackdropOffline from './../components/commons/BackdropOffline';
import AppShortActionsController from './../components/main/AppShortActionsController';
import MaintenanceDialog from './../components/commons/MaintenanceDialog';

const Hello = lazy(() => import('./Hello'));

export default function Main (props) {

  const { userLoggedIn, appOnline } = useSelector(state => ({
    userLoggedIn: state.auth.user,
    appOnline: state.app.online
  }));

  const queryClient = useQueryClient()
  if (!userLoggedIn) {
    // Important : lorsque l'utilisateur est déconnecté, on supprime toutes les queries en cache de react-query
    queryClient.removeQueries()
  }

  return (
    <SnackbarProvider maxSnack={5}>

      <main>

        { /*---- App OffLine ------------------------------------------------------*/ }
        <BackdropOffline open={!appOnline} />

        { /*---- Api maintenance mode ---------------------------------------------*/ }
        <MaintenanceDialog />

        { /*---- App Short Actions Controller -------------------------------------*/ }
        <AppShortActionsController />

        <Suspense fallback={<BackdropLoading open={true} />}>
          <Routes>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={ route.loggedIn !== true ? route.component : ( userLoggedIn ? route.component : <Hello /> ) }
                />
              )}
            )}
          </Routes>
        </Suspense>
      </main>

    </SnackbarProvider>
  )
}
