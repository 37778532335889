import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/project-user-rels/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/project-user-rels/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (data) {
    throw new Error('l\'API ne permet pas de créer une relation, il faut passer par une invitation')
  },
  async update (id, data) {
    throw new Error('l\'API ne permet pas de modifier une relation, il faut la supprimer et passer par une invitation')
  },
  async delete (id) {
    return API.delete('/project-user-rels/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
