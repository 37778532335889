import { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useEditMediasEntity, useFormData } from '../../hooks'
import { getStyled } from './../../services/styles'
import projectMonitoringsService from '../../services/projectMonitorings'
import mediasService from '../../services/medias'
import utilsService from '../../services/utils'

import BackdropLoading from './../commons/BackdropLoading'
import SetFiles from './../commons/SetFiles'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormFieldDivider from './../commons/FormFieldDivider'
import FormControllers from './../commons/FormControllers'
import { SaveIcon, PhotoIcon } from './../commons/Icons'
import Loading from '../commons/Loading'
import EditProjectMonitoringField from './EditProjectMonitoringField'
import TextFieldLimited from './../commons/TextFieldLimited'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextFieldLimited = getStyled(TextFieldLimited, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditProjectMonitoring(props) {

  const { id_project, slug, section_entry, fixedCtrl, onSaved, onError } = props

  const queryClient = useQueryClient()
  const queryKey = 'projects-' + id_project + '-monitorings'

  const [ isNew, setIsNew ] = useState(false)
  const [ isAlert, setIsAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ medias, setMedias ] = useState([])

  const {formData, mergeFormData} = useFormData({
    initialData: {},
    presetData: {},
    existingKeysConstraint: false
  })

  // Récupération du project-monitoring par son slug
  const { isFetching, isError, refetch, data: entity } = useQuery([queryKey, slug], async () => {
    return projectMonitoringsService.get(id_project, slug).then(([item]) => item)
  }, {
    enabled: !!slug,
    staleTime: 0, // IMPORTANT : on garde toujours un staleTime à 0 car en édition on doit avoir des données fraiches (de plus sinon cela génère un bug de rafraichissement des données dans react-query)
    retry: false, // NOTE : si on obtient une 404 c'est que l'entrée de ce suivi n'existe pas encore, inutile de relancer la requête
    onSuccess: (item) => {
      if (item) {
        setIsAlert(utilsService.isTrue(item.worker_cat_alert))
        setAlertMessage(item.worker_cat_alert_message || '')
        mergeFormData(item.form_data)
      }
    },
    onError: () => {
      // NOTE : si on obtient une 404 c'est que l'entrée de ce suivi n'existe pas encore, tout va bien nous allons le créer
      setIsNew(true)
    }
  })

  // Gestion des médias du suivi
  const mediasQuery = entity ? {id_project_monitoring: entity.ID} : undefined
  const mediasQueryKeys = ['medias', mediasQuery]
  const { mutate: mutateMedias, isMutating: isMutatingMedias, isFetching: isFetchingMedias, isError: isErrorMedias, refetch: refetchMedias } = useEditMediasEntity({
    query: mediasQuery,
    queryKeys: mediasQueryKeys,
    getAll: (query) => query ? mediasService.getAll(query) : new Promise((resolve) => resolve([[], []])),
    upload: (data) => mediasService.upload(data),
    delete: (id) => mediasService.delete(id),
    onGetAll: (items) => setMedias(items),
    onSuccess: ({medias, args}) => {

      // Les médias sont enregistrés, on peut invalider les queries
      queryClient.invalidateQueries([queryKey])
      mediasQuery && queryClient.invalidateQueries(mediasQueryKeys)

      // Les médias sont enregistrés, on peut terminer
      onSaved(args.id_project_monitoring, entity ? false : true)

    },
    onError
  })

  // Mutation (create/update)
  const { isLoading: isMutating, mutate } = useMutation((data) => {
    return projectMonitoringsService.save(id_project, slug, data)
  }, {
    onSuccess: ([slug, id, notices]) => {
      notices && notices.length > 0 && onError(notices)

      // Mise à jour de la liste de médias, on le fait maintenant que le suivi est créé
      mutateMedias({medias, args: {id_project_monitoring: id}})

    },
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    /*
    if (isEmpty(formData) && !isAlert) {
      onError("Aucune donnée à enregistrer")
      return false
    }
    */
    mutate({
      name: section_entry.title,
      form_data: formData,
      id_worker_cat: section_entry.alert, // NOTE on envoi un slug (accepté par l'API pour référencer une catégorie professionnelle)
      worker_cat_alert: isAlert,
      worker_cat_alert_message: alertMessage
    })
    return false
  }

  if ((isFetching || isError) && !isNew) {
    return <Loading linear={true} error={isError} refetch={refetch} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating || isMutatingMedias} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields spacing={1}>

          { section_entry.fields && section_entry.fields.map((field, index) => (
            <EditProjectMonitoringField key={index} field={field}  formData={formData} mergeFormData={mergeFormData} />
          )) }

          { section_entry.alert && (
            <>
              <FormFieldDivider />
              <FormField>
                <MyFormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAlert}
                        onChange={() => setIsAlert(!isAlert)}
                      />
                    }
                    label={"Alerter les artisans ("+section_entry.alert+")"}
                  />
                </MyFormControl>
              </FormField>
              { isAlert && (
                <FormField>
                  <MyFormControl>
                    <MyTextFieldLimited
                      required
                      id="worker_cat_alert_message"
                      label="Message à envoyer aux artisans"
                      type="text"
                      value={alertMessage}
                      onChange={(value) => setAlertMessage(value)}
                      limit={1000}
                      multiline={true}
                    />
                  </MyFormControl>
                </FormField>
              ) }
            </>
          ) }

          { section_entry.medias && utilsService.isTrue(section_entry.medias) && (
            <>
              <FormFieldDivider />
              <FormField>
                <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                  { mediasQuery && (isFetchingMedias || isErrorMedias) && <Loading linear={true} error={isErrorMedias} refetch={refetchMedias} /> }
                  <SetFiles
                    value={medias || []}
                    onChange={(value) => setMedias(value)}
                    onError={onError}
                    label="Ajouter une photo"
                    labelDrag="Glisser une photo"
                    accept="image/*"
                    sx={{ width: '100%' }}
                    startIcon={<PhotoIcon />}
                  />
                </Box>
              </FormField>
            </>
          ) }

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>Enregistrer</SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>
    </Box>
  )
}

EditProjectMonitoring.propTypes = {
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  slug: PropTypes.string.isRequired,
  section_entry: PropTypes.object.isRequired,
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
}

export default EditProjectMonitoring
