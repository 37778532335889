import { getStyled } from './../../services/styles'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { OfflineIcon } from './../commons/Icons';

const MyBackdrop = getStyled(Backdrop, ({theme}) => ({
  zIndex: theme.zIndex.drawer + 1000,
  color: '#fff'
}));

export default function BackdropOffline (props) {
  const {open, ...others} = props
  return (
    <MyBackdrop open={open} {...others}>
      <Box sx={{ textAlign: 'center' }}>
        <OfflineIcon color="inherit" />
        <Box>
          <Typography>pas de connexion</Typography>
        </Box>
      </Box>
    </MyBackdrop>
  )
}
