import { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { getStyled } from '../../services/styles'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Loading from './Loading'

const SearchBox = getStyled(Box, ({theme}) => ({
  padding: 0,
}))

const FetchingBox = getStyled(Box, ({theme}) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 0),
}))

const NextPageBox = getStyled(Box, ({theme}) => ({
  textAlign: 'center',
  padding: theme.spacing(1, 0),
}))

const NoResultBox = getStyled(Box, ({theme}) => ({
  padding: theme.spacing(2),
}))

function Query (props) {

  const { queryLoad, queryKey, queryLimit, queryStaleTime, renderItems, renderSearch, noResult, disableInfinite } = props

  const { ref, inView } = useInView()

  const { data, isFetching, isError, isRefetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
    queryKey,
    async ({pageParam}) => queryLoad(!pageParam ? 0 : pageParam - 1), // la première page est undefined, la deuxième est 2
    {
      getNextPageParam: (lastPage, allPages) => lastPage.length < queryLimit ? undefined : allPages.length + 1,
      ...(queryStaleTime && { staleTime: queryStaleTime }),
    }
  )
  const items = data?.pages?.flat() || []

  /**
   * Lorsque l'utilisateur scroll, on charge la suite des données
   */
   useEffect(() => {
    if (inView && !disableInfinite) {
      fetchNextPage()
    }
  }, [inView, disableInfinite, fetchNextPage])

  return (
    <Box>

      {renderSearch && (
        <SearchBox>
          { renderSearch(items, isFetching, isError, isRefetching) }
        </SearchBox>
      )}

      { (isFetching || isError) && (
        <Loading linear={true} error={isError} refetch={refetch} />
      ) }

      { items && renderItems && renderItems(items) }

      { hasNextPage && !disableInfinite && !isFetching && !isError && (
        <NextPageBox>
          <Button ref={ref} onClick={fetchNextPage}>Charger la suite...</Button>
        </NextPageBox>
      ) }

      { !hasNextPage && !isFetching && !isError && items.length === 0 && (
        <Box>
          { noResult || <NoResultBox>Aucun résultat</NoResultBox> }
        </Box>
      ) }

      { isFetchingNextPage && (
        <FetchingBox>
          <CircularProgress color="tiertiary" size={30} />
        </FetchingBox>
      ) }

    </Box>
  )

}

Query.propTypes = {
  // infinite
  disableInfinite: PropTypes.bool,
  // query
  queryLoad: PropTypes.func.isRequired,
  queryKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  queryLimit: PropTypes.number.isRequired,
  queryStaleTime: PropTypes.number,
  // render
  renderItems: PropTypes.func.isRequired,
  renderSearch: PropTypes.func,
  // others
  noResult: PropTypes.element,
  scrollableTarget: PropTypes.string,
}

Query.defaultProps = {
  disableInfinite: false,
}

export default Query