import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import mediasService from './../../services/medias'

import ConfirmDialog from './../commons/ConfirmDialog'
import BackdropLoading from './../commons/BackdropLoading'

function DialogDeleteMedia (props) {

  const { id, open, onDeleted, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation(() => {
    return mediasService.delete(id)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['medias'])
      onDeleted(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDialog
        open={open}
        title="Suppression de ce média ?"
        message="Cette action est irreversible."
        onConfirm={ (e) => {
          mutate()
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogDeleteMedia.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogDeleteMedia;
