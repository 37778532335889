import Users_API from './api/users'
import utilsService from './utils'

/****************************************************
 Interface avec l'API relative aux utilisateurs
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  SHOP_USER_ROLE__CUSTOMER: 'customer',
  SHOP_USER_ROLE__MANAGER: 'manager',
	CIVILITY_M: 'm',
	CIVILITY_MME: 'mme',
	CIVILITY_MLLE: 'mlle',

  /**
   * Récupère un utilisateur du serveur
   * @return Promise(data, notices) : l'utilisateur
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Users_API.get(id, args).then(({data, notices}) => {
        console.log('Utilisateur récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère les utilisateurs du serveur
   * @return Promise(data, notices) : les utilisateurs
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Users_API.getAll(args).then(({data, notices}) => {
        console.log('Utilisateurs récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un utilisateur
   * @return Promise(data, notices) : l'utilisateur mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Users_API.update(id, data).then(({data, notices}) => {
        console.log('Utilisateur mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un utilisateur
   * @return Promise(id, data, notices) : l'identifiant de l'utilisateur supprimé
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Users_API.delete(id).then(({data, notices}) => {
        console.log('Utilisateur supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /***************************** Extras ************************/

  /**
   * Renvoi le libellé de la civilité
   * @param {string} slug 
   */
   getCivilityLabel(slug, short = true) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.CIVILITY_M: {
        return short ? 'M.' : 'Monsieur';
      }
      case this.CIVILITY_MME: {
        return short ? 'Mme.' : 'Madame';
      }
      case this.CIVILITY_MLLE: {
        return short ? 'Mlle.' : 'Mademoiselle';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoi l'avatar de l'utilisateur
   */
  getAvatar (user) {
    return { content: this.getInitials(user), props: {} };
  },
  /**
   * Renvoie les initiales de l'utilisateur
   */
  getInitials (user) {
    let initials = '';
    if (user && user.name) {
      initials = utilsService.getInitials(user.name);
    }
    return initials;
  },
};
export default exportedObject;
