import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import SetFileUpload from './SetFileUpload'
import MediasGalleryItem from '../media/MediasGalleryItem'
import { CloseIcon } from './Icons'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

const FileBox = getStyled(Box, ({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center'
}))

const MediaBox = getStyled(Box, ({ theme }) => ({
  maxWidth: '400px',
  width: '100%',
}))

const RemoveButton = getStyled(IconButton, ({ theme }) => ({}))

function SetFiles (props) {

  const { value, onChange, ...others } = props

  const addFile = (file) => {
    // On ajoute le nouveau fichier à uploader parmis les médias existants,
    // on génère un ID unique pour permettre la suppression avant enregistrement,
    // on ajoute la clé 'upload' qui permettra au hook d'enregistrement de savoir que c'est un nouveau fichier à uploader
    onChange([...value, ...[{ID: 'upload-' + Date.now(), upload: true, file}]])
  }

  const removeFile = (ID) => {
    // eslint-disable-next-line
    onChange(value.filter((item) => item.ID != ID))
  }

  return (

    <Grid container spacing={1}>

      { value && Array.isArray(value) && value.length > 0 && value.map((item, index) => {
        // un item peut être existant (c'est un objet qui n'a pas la clé 'upload')
        // un item peut être en cours de création (c'est un objet qui a la clé 'upload')
        return (
          <Grid item xs={12} key={index}>
            <FileBox>
              <RemoveButton onClick={() => removeFile(item.ID)}><CloseIcon /></RemoveButton>
              { !item.upload && (
                <MediaBox>
                  <MediasGalleryItem
                    media={item}
                  />
                </MediaBox>
              ) }
              { item.upload && (<Typography variant="body2" component="div">{item.file.name} <em style={{ fontSize: 10 }}>(visible après enregistrement)</em></Typography>) }
            </FileBox>
          </Grid>
        )
      }) }

      <Grid item xs={12}>
        <SetFileUpload
          onChange={(file) => addFile(file)}
          {...others}
        />
      </Grid>

    </Grid>
  )
}

SetFiles.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
}

export default SetFiles
