import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/notifications/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/notifications/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (data) {
    return API.post('/notifications/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create_for_project_planning_updated (data) {
    return API.post('/notifications/project-planning-updated', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id, data) {
    return API.post('/notifications/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id) {
    return API.delete('/notifications/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
