import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  online: true,
  api_maintenance_mode: false,
  api_minimal_version: null,
  notifications_count: 0,
  webpush_notifications_supported: false,
  webpush_notifications_enabled: false,
  shortAction: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOnline: (state, action) => {
      state.online = action.payload;
    },
    setApiMinimalVersion: (state, action) => {
      state.api_minimal_version = action.payload;
    },
    setApiMaintenanceMode: (state, action) => {
      state.api_maintenance_mode = action.payload;
    },
    setNotificationsCount: (state, action) => {
      state.notifications_count = action.payload;
    },
    setWebPushNotificationsSupported: (state, action) => {
      state.webpush_notifications_supported = action.payload;
    },
    setWebPushNotificationsEnabled: (state, action) => {
      state.webpush_notifications_enabled = action.payload;
    },
    setShortAction: (state, action) => {
      state.shortAction = action.payload;
    },
  },
});

// export actions
export const {
  setOnline,
  setApiMaintenanceMode,
  setApiMinimalVersion,
  setNotificationsCount,
  setWebPushNotificationsSupported,
  setWebPushNotificationsEnabled,
  setShortAction
} = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.app.value)`
export const selectOnline = (state) => state.app.online;
export const selectApiMaintenanceMode = (state) => state.app.api_maintenance_mode;
export const selectApiMinimalVersion = (state) => state.app.api_minimal_version;
export const selectNotificationsCount = (state) => state.app.notifications_count;
export const selectWebPushNotificationsSupported = (state) => state.app.webpush_notifications_supported;
export const selectWebPushNotificationsEnabled = (state) => state.app.webpush_notifications_enabled;
export const selectShortAction = (state) => state.app.shortAction;

// export reducers
export default appSlice.reducer;
