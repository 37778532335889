import PropTypes from 'prop-types'
import mediasService from '../../services/medias'

import ImagesGallery from '../commons/ImagesGallery'
import Search from '../commons/Search'
import MediasGalleryItem from './MediasGalleryItem'
import Box from '@mui/material/Box'

function MediasGallery (props) {

  const { sx, query, onQueryChange, limit, searchEnabled, size, onClick, ...others } = props

  const queryKey = ['medias', query || 'all', `limit_${limit}`]
  const queryLoad = (pageParam) => mediasService.getAll({ ...query, ...{ limit, offset: (limit * pageParam) } }).then(([items]) => items)

  const renderSearch = (items, isFetching, isError, isRefetching) => {
    return (
      <Search
        query={query}
        onChange={onQueryChange}
        disabled={isFetching || isError || isRefetching}
      />
    )
  }

  return (
    <Box sx={sx}>
      <ImagesGallery
        queryLoad={queryLoad}
        queryKey={queryKey}
        queryLimit={limit}
        renderItem={({item}) => (
          <MediasGalleryItem
            size={size}
            media={item}
            onClick={onClick}
          />
        )}
        renderSearch={searchEnabled ? renderSearch : undefined}
        {...others}
      />
    </Box>
  )

}

MediasGallery.propTypes = {
  // query
  query: PropTypes.object,
  onQueryChange: PropTypes.func,
  limit: PropTypes.number,
  // search
  searchEnabled: PropTypes.bool,
  // presentation
  size: PropTypes.string,
}

MediasGallery.defaultProps = {
  query: {},
  limit: 25,
  searchEnabled: false,
}

export default MediasGallery
