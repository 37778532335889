import PropTypes from 'prop-types'
import { useDownload } from './../../hooks'
import mediasServices from './../../services/medias'

import { DownloadIcon, CheckIcon, ErrorIcon } from './../commons/Icons'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress'

function DialogActionsSupportTicketMessageMedia (props) {

  const { media, open, onClose, onError } = props

  const downloadMedia = async () => {
    return mediasServices.download(media.ID)
  }

  const { download, isDownloaded, ref: downloadRef, url: downloadUrl, isDownloading, isError: isDownloadingError } = useDownload({
    onDownload: downloadMedia,
    onError
  })

  let actions = []
  actions.push(
    <ListItem key="download" button onClick={() => download && download()}>
      <ListItemAvatar>
        <Avatar>
          { isDownloading && <CircularProgress color="white" size={20} /> }
          { isDownloadingError && <ErrorIcon /> }
          { isDownloaded && <CheckIcon /> }
          { !isDownloading && !isDownloadingError && !isDownloaded && <DownloadIcon /> }
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Télécharger" />
    </ListItem>
  )

  if (!actions || actions.length < 1) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="form-dialog-title">Vous souhaitez ?</DialogTitle>
      <List sx={{ pt: 0 }}>
        { actions.map((action) => action) }
      </List>
      <a href={downloadUrl} download={media.original_name + '.' + media.file_ext} style={{visibility: 'hidden', height: 0}} ref={downloadRef}>Télécharger le média</a>
    </Dialog>
  )
}

DialogActionsSupportTicketMessageMedia.propTypes = {
  media: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

export default DialogActionsSupportTicketMessageMedia
