import workerCatsService from './../../services/workerCats'

import SelectAsync from './SelectAsync'
import WorkerCatsList from './../worker-cat/WorkerCatsList'
import { WorkerCatIcon } from './Icons'

/**
 * Sélecteur de catégorie professionnelle
 * Ce composant est asynchrone, il charge les valeurs à sélectionner depuis une API
 */
function SelectWorkerCat (props) {

  const { classes, ...other } = props

  const renderSelect = (onSelect, selectedItemKey) => {
    return (
      <WorkerCatsList
        onSelect={(e, item) => onSelect(item.ID)}
        onError={props.onError}
        query={props.query}
        onQueryChange={props.onQueryChange}
        limit={25}
        selectedItemsKeys={[selectedItemKey]}
      />
    )
  }

  return (
    <SelectAsync
      { ...{...other, ...{
        title: props.title || "Catégorie pro.",
        renderSelect: renderSelect,
        label: props.label || "Aucun",
        labelIcon: props.labelIcon || <WorkerCatIcon />,
        renderSelected: (selectedItemKey, selectedObject) => selectedObject?.name || selectedItemKey,
      }} }
      queryKey="workerCats"
      queryLoad={(query, value) => workerCatsService.get(value).then(([item]) => item)}
    />
  )
}

export default SelectWorkerCat
