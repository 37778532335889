import DocumentsScopes_API from './api/documents-scopes'

/****************************************************
 Interface avec l'API relative aux documentsScopes
****************************************************/

const exportedObject = {

  /**************** documentsScopes ************************************/
  /**
   * Récupère les documentsScopes du serveur
   * @return Promise(data, notices) : les documentsScopes
   */
  getAll (id_document, args) {
    return new Promise((resolve, reject) => {
      DocumentsScopes_API.getAll(id_document, args).then(({data, notices}) => {
        console.log('DocumentsScopes récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un documentsScope du serveur
   * @return Promise(data, notices) : le documentsScope
   */
  get (id_document, id, args) {
    return new Promise((resolve, reject) => {
      DocumentsScopes_API.get(id_document, id, args).then(({data, notices}) => {
        console.log('DocumentsScope récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Crée un documentsScope
   * @return Promise(data, notices) : l'identifiant du documentsScope créé
   */
  create (id_document, data) {
    return new Promise((resolve, reject) => {
      DocumentsScopes_API.create(id_document, data).then(({data, notices}) => {
        console.log('DocumentsScope créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un documentsScope
   * @return Promise(data, notices) : l'identifiant du documentsScope mis à jour
   */
  update (id_document, id, data) {
    return new Promise((resolve, reject) => {
      DocumentsScopes_API.update(id_document, id, data).then(({data, notices}) => {
        console.log('DocumentsScope mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un documentsScope
   * @return Promise(id, data, notices) : l'identifiant du documentsScope supprimée
   */
  delete (id_document, id) {
    return new Promise((resolve, reject) => {
      DocumentsScopes_API.delete(id_document, id).then(({data, notices}) => {
        console.log('DocumentsScope supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Renvoi l'avatar
   * @param {object} documentScope 
   * @returns 
   */
  getAvatar (documentScope) {
    return null
  },
};
export default exportedObject;
