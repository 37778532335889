import { useState } from 'react'
import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import documentsAttachmentsService from './../../services/documents-attachments'

import Loading from '../commons/Loading'
import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import SetFile from '../commons/SetFile'
import { FileIcon, SaveIcon } from './../commons/Icons'

import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditDocumentAttachment(props) {

  const { id, preset, fixedCtrl, onSaved, onDeleted, onError, id_document } = props

  const [file, setFile] = useState()

  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    initialData: {
      name: '',
    },
    id,
    queryKey: 'documents-' + id_document + '-attachments',
    presetData: preset,
    get: (id) => documentsAttachmentsService.get(id_document, id),
    create: (data) => documentsAttachmentsService.upload(id_document, data),
    update: (id, data) => documentsAttachmentsService.update(id_document, id, data),
    onSaved,
    onDeleted,
    onError,
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries du document parent
      queryClient.invalidateQueries(['documents-' + id_document + '-attachments'])
      queryClient.invalidateQueries(['documents', id_document])
    },
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    // cas d'une modification
    if (!isNew) {
      mutate(formData)
      return false
    }
    // cas d'une création (on upload le fichier)
    if (!file) {
      onError('fichier manquant')
      return false
    }
    const finalFormData = new FormData()
    finalFormData.append('file', file.file)
    for (const property in formData) {
      finalFormData.append(property, formData[property])
    }
    mutate(finalFormData)
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="name"
                label="Nom"
                type="text"
                name="file-name"
                value={formData.name}
                onChange={(e) => mergeFormData({name: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          { isNew && (
            <FormField>
              <SetFile
                value={file}
                onChange={(file) => setFile(file)}
                onError={onError}
                label="Choisir un fichier PDF"
                labelDrag="Glisser un fichier PDF"
                accept="application/pdf"
                sx={{ width: '100%' }}
                startIcon={<FileIcon />}
              />
            </FormField>
          ) }

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>

    </Box>
  )
}

EditDocumentAttachment.propTypes = {
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_document: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

EditDocumentAttachment.defaultProps = {
  fixedCtrl: false,
};

export default EditDocumentAttachment;
