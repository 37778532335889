import { useState } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import Form from './Form'
import FormFields from './FormFields'
import FormField from './FormField'

import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
    width: '100%',
  }))
  
  const MyTextField = getStyled(TextField, ({ theme }) => ({
    width: '100%',
  }))

function ConfirmDeleteDialog (props) {

    const { open, title, message, children, cancelLabel, confirmLabel, onConfirm, onClose } = props

    const [confirmMessage, setConfirmMessage] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()
        if (confirmMessage && confirmMessage.toLowerCase() === 'supprimer') {
            onConfirm()
        }
        return false
    }

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{ title || "Suppression" }</DialogTitle>
            <DialogContent>
                { message && (
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                ) }
                { children }
                <Form onSubmit={onSubmit} noValidate>
                    <FormFields>
                        <FormField>
                            <MyFormControl>
                                <MyTextField
                                    required
                                    id="name"
                                    label="Saisissez 'supprimer'"
                                    type="text"
                                    value={confirmMessage}
                                    onChange={(e) => setConfirmMessage(e.target.value)}
                                    helperText="En saisissant le mot 'supprimer', vous confirmer votre action à caractère sensible."
                                />
                            </MyFormControl>
                        </FormField>
                    </FormFields>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    { cancelLabel || 'Annuler' }
                </Button>
                <Button disabled={(!confirmMessage || confirmMessage.toLowerCase() !== 'supprimer')} onClick={onSubmit} color="error" variant="contained">
                    { confirmLabel || 'Supprimer' }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmDeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
}

export default ConfirmDeleteDialog
