import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setShortAction } from '../../services/store/features/appSlice'

import DialogEditProject from '../dialog/DialogEditProject'
import DialogEditProjectMonitoring from '../dialog/DialogEditProjectMonitoring'
import DialogEditSupportTicket from '../dialog/DialogEditSupportTicket'
import DialogEditSupportTicketMessage from '../dialog/DialogEditSupportTicketMessage'
import DialogEditSupportTicketTarget from '../dialog/DialogEditSupportTicketTarget'
import DialogEditUser from '../dialog/DialogEditUser'
import DialogEditInvitation from '../dialog/DialogEditInvitation'
import DialogSetStateProjectValidated from '../dialog/DialogSetStateProjectValidated'
import DialogSetStateProjectProcessing from '../dialog/DialogSetStateProjectProcessing'
import DialogSetStateProjectDone from '../dialog/DialogSetStateProjectDone'
import DialogSetStateProjectClosed from '../dialog/DialogSetStateProjectClosed'
import DialogEditMedia from '../dialog/DialogEditMedia'
import DialogEditDocument from '../dialog/DialogEditDocument'
import DialogEditDocumentAttachment from '../dialog/DialogEditDocumentAttachment'
import DialogEditDocumentScope from '../dialog/DialogEditDocumentScope'
import DialogSetStateDocumentValidated from '../dialog/DialogSetStateDocumentValidated'
import DialogDeleteProject from '../dialog/DialogDeleteProject'
import DialogDeleteDocument from '../dialog/DialogDeleteDocument'
import DialogDeleteDocumentAttachment from '../dialog/DialogDeleteDocumentAttachment'
import DialogDeleteDocumentScope from '../dialog/DialogDeleteDocumentScope'
import DialogDeleteProjectUserRel from '../dialog/DialogDeleteProjectUserRel'
import DialogDeleteMedia from '../dialog/DialogDeleteMedia'
import DialogDeleteSupportTicket from '../dialog/DialogDeleteSupportTicket'
import DialogDeleteSupportTicketMessage from '../dialog/DialogDeleteSupportTicketMessage'
import DialogDeleteUser from '../dialog/DialogDeleteUser'
import DialogCharteQualite from '../dialog/DialogCharteQualite'
import DialogFile from '../dialog/DialogFile'
import DialogLogOut from '../dialog/DialogLogOut'
import DialogSetStateSupportTicketClosed from '../dialog/DialogSetStateSupportTicketClosed'
import DialogSetStateSupportTicketOpened from '../dialog/DialogSetStateSupportTicketOpened'
import DialogSupportTicketMessageMedias from '../dialog/DialogSupportTicketMessageMedias'
import DialogFilePDF from '../dialog/DialogFilePDF'
import DialogNotifyForProjectPlanningUpdated from '../dialog/DialogNotifyForProjectPlanningUpdated'

/**
 * Manage in-app short actions in Dialogs.
 */
export default function AppShortActionsController () {

  const { shortAction } = useSelector(state => ({
    shortAction: state.app.shortAction
  }))
  const dispatch = useDispatch();
  const dispatchShortAction = (shortAction) => {
    dispatch(setShortAction(shortAction));
  }

  const shortActionName = shortAction ? shortAction.name : null;
  const shortActionArgs = shortAction ? shortAction.args : null;

  const onSuccess = (...params) => {
    const shortActionOnSuccess = shortAction && shortAction.callbacks ? shortAction.callbacks.onSuccess : null;
    if (shortActionOnSuccess) {
      shortActionOnSuccess(...params);
    }
  }

  const onError = (...params) => {
    const shortActionOnError = shortAction && shortAction.callbacks ? shortAction.callbacks.onError : null;
    if (shortActionOnError) {
      shortActionOnError(...params);
    }
  }

  const onClose = (...params) => {
    const shortActionOnClose = shortAction && shortAction.callbacks ? shortAction.callbacks.onClose : null;
    dispatchShortAction(null);
    if (shortActionOnClose) {
      shortActionOnClose(...params);
    }
  }

  const onEnd = (...params) => {
    const shortActionOnEnd = shortAction && shortAction.callbacks ? shortAction.callbacks.onEnd : null;
    if (shortActionOnEnd) {
      shortActionOnEnd(...params);
    }
  }

  return (
    <React.Fragment>

      { shortActionName === 'editProject' && (
        <DialogEditProject
          open={shortActionName === 'editProject'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editProjectMonitoring' && (
        <DialogEditProjectMonitoring
          open={shortActionName === 'editProjectMonitoring'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editSupportTicket' && (
        <DialogEditSupportTicket
          open={shortActionName === 'editSupportTicket'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editSupportTicketMessage' && (
        <DialogEditSupportTicketMessage
          open={shortActionName === 'editSupportTicketMessage'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editSupportTicketTarget' && (
        <DialogEditSupportTicketTarget
          open={shortActionName === 'editSupportTicketTarget'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateSupportTicketClosed' && (
        <DialogSetStateSupportTicketClosed
          open={shortActionName === 'setStateSupportTicketClosed'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateSupportTicketOpened' && (
        <DialogSetStateSupportTicketOpened
          open={shortActionName === 'setStateSupportTicketOpened'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editUser' && (
        <DialogEditUser
          open={shortActionName === 'editUser'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateProjectValidated' && (
        <DialogSetStateProjectValidated
          open={shortActionName === 'setStateProjectValidated'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateProjectProcessing' && (
        <DialogSetStateProjectProcessing
          open={shortActionName === 'setStateProjectProcessing'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateProjectDone' && (
        <DialogSetStateProjectDone
          open={shortActionName === 'setStateProjectDone'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateProjectClosed' && (
        <DialogSetStateProjectClosed
          open={shortActionName === 'setStateProjectClosed'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editMedia' && (
        <DialogEditMedia
          open={shortActionName === 'editMedia'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editDocument' && (
        <DialogEditDocument
          open={shortActionName === 'editDocument'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editDocumentAttachment' && (
        <DialogEditDocumentAttachment
          open={shortActionName === 'editDocumentAttachment'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editDocumentScope' && (
        <DialogEditDocumentScope
          open={shortActionName === 'editDocumentScope'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'editInvitation' && (
        <DialogEditInvitation
          open={shortActionName === 'editInvitation'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'notifyForProjectPlanningUpdated' && (
        <DialogNotifyForProjectPlanningUpdated
          open={shortActionName === 'notifyForProjectPlanningUpdated'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'setStateDocumentValidated' && (
        <DialogSetStateDocumentValidated
          open={shortActionName === 'setStateDocumentValidated'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteProject' && (
        <DialogDeleteProject
          open={shortActionName === 'deleteProject'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteDocument' && (
        <DialogDeleteDocument
          open={shortActionName === 'deleteDocument'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteDocumentAttachment' && (
        <DialogDeleteDocumentAttachment
          open={shortActionName === 'deleteDocumentAttachment'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteDocumentScope' && (
        <DialogDeleteDocumentScope
          open={shortActionName === 'deleteDocumentScope'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteProjectUserRel' && (
        <DialogDeleteProjectUserRel
          open={shortActionName === 'deleteProjectUserRel'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteMedia' && (
        <DialogDeleteMedia
          open={shortActionName === 'deleteMedia'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteSupportTicket' && (
        <DialogDeleteSupportTicket
          open={shortActionName === 'deleteSupportTicket'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteSupportTicketMessage' && (
        <DialogDeleteSupportTicketMessage
          open={shortActionName === 'deleteSupportTicketMessage'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'deleteUser' && (
        <DialogDeleteUser
          open={shortActionName === 'deleteUser'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'viewCharteQualite' && (
        <DialogCharteQualite
          open={shortActionName === 'viewCharteQualite'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'viewFilePDF' && (
        <DialogFilePDF
          open={shortActionName === 'viewFilePDF'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'viewFile' && (
        <DialogFile
          open={shortActionName === 'viewFile'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'viewSupportTicketMessageMedias' && (
        <DialogSupportTicketMessageMedias
          open={shortActionName === 'viewSupportTicketMessageMedias'}
          onError={onError}
          onSuccess={onSuccess}
          onClose={onClose}
          onSaved={onEnd}
          onDeleted={onEnd}
          {...shortActionArgs}
        />
      ) }

      { shortActionName === 'logout' && (
        <DialogLogOut
          open={shortActionName === 'logout'}
          onClose={onClose}
          onLoggedOut={onEnd}
          {...shortActionArgs}
        />
      ) }

    </React.Fragment>
  )
}
