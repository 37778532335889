import { Fragment } from 'react'
import PropTypes from 'prop-types'
import authService from './../../services/auth'

import ConfirmDialog from './../commons/ConfirmDialog'

function DialogLogOut (props) {

  const { open, onLoggedOut, onClose } = props

  return (
    <Fragment>
      <ConfirmDialog
        open={open}
        title="Déconnnexion"
        message="Vous souhaitez vous déconnecter ?"
        onConfirm={ (e) => {
          authService.logOut()
          onLoggedOut && onLoggedOut()
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogLogOut.propTypes = {
  open: PropTypes.bool,
  onLoggedOut: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DialogLogOut
