import { useEffect, useCallback } from 'react'
import { useMounted } from '../../hooks'
import { getStyled } from '../../services/styles'
import PropTypes from 'prop-types'
import clonedeep from 'lodash.clonedeep'
import isEmpty from 'lodash.isempty'

import { SearchIcon, CloseIcon, ArrowTopIcon } from './Icons'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

const SearchBox = getStyled(Box, ({theme}) => ({
  position: 'relative',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.bg.light}`,
  minHeight: theme.spacing(5),
}));

const SearchIconIcon = getStyled(SearchIcon, ({theme}) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  left: theme.spacing(1),
}));

const InnerSearchBox = getStyled(Box, ({theme}) => ({
  width: '100%',
}));

const ClearSearchBox = getStyled(Box, ({theme}) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1),
}));

const ClearIconButton = getStyled(IconButton, ({theme}) => ({
  padding: theme.spacing(0),
}));

const CloseBox = getStyled(Box, ({theme}) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
}));

function SearchController(props) {

  const { query, children, onChange, onFocus, focus, disabled } = props

  const isMounted = useMounted()

  const changeFocus = useCallback((focusState) => {
    onFocus && onFocus(focusState)
  }, [onFocus])

  const keydownEvent = useCallback((e) => {
    if (e.key === "Escape") {
      changeFocus(false)
    }
  }, [changeFocus])

  useEffect(() => {
    document.addEventListener("keydown", keydownEvent, false);
    return () => {
      document.removeEventListener("keydown", keydownEvent, false);
    }
  }, [keydownEvent])

  const clearQuery = () => {
    let clonedQuery = clonedeep(query)
    // on rend undefined tous les attributs de la recherche
    Object.keys(clonedQuery).forEach(key => clonedQuery[key] = undefined)
    onChange(clonedQuery)
    changeFocus(false)
  }

  const isQueryEmpty = () => {
    // Pour savoir si la query est vide, on en supprime toute entrée vide pour la tester ensuite
    let clonedQuery = clonedeep(query)
    Object.keys(clonedQuery).forEach(key => isEmpty(clonedQuery[key]) && delete clonedQuery[key])
    return isEmpty(clonedQuery);
  }

  return (
    <SearchBox>
      <SearchIconIcon />
      <InnerSearchBox onClick={(e) => changeFocus(true)} onFocus={(e) => changeFocus(true)}>
        { children }
      </InnerSearchBox>
      <ClearSearchBox>
        {!isQueryEmpty() && (
          <ClearIconButton
            disabled={disabled}
            aria-label="clear-search"
            onClick={(e) => isMounted() && clearQuery(e)}
            size="large">
            <CloseIcon />
          </ClearIconButton>
        )}
      </ClearSearchBox>
      { focus && (
        <CloseBox>
          <Button color="secondary" startIcon={<ArrowTopIcon />} onClick={() => {
            changeFocus(false)
          }}>Fermer</Button>
        </CloseBox>
      ) }
    </SearchBox>
  )
}

SearchController.propTypes = {
  query: PropTypes.object.isRequired, // critères de recherche, Search les modifie via onChange
  onChange: PropTypes.func.isRequired, // changement de query
  onFocus: PropTypes.func.isRequired, // changement du focus sur la recherche
  focus: PropTypes.bool.isRequired, // focus sur les critères de recherche
  disabled: PropTypes.bool,
};

export default SearchController;
