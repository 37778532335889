import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/documents/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/documents/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (data) {
    return API.post('/documents/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id, data) {
    return API.post('/documents/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id) {
    return API.delete('/documents/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async bulk_download (params) {
    return API.get('/documents/bulk-download', { params, responseType: 'arraybuffer' })
      .catch(error => Promise.reject(error.response));
  },
  async setState (id, state, state_comment = '') {
    return API.post('/documents/' + id + '/state', {state, state_comment})
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
