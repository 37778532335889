import { useState } from 'react'
import { getStyled } from '../../services/styles'
import { useSelector } from 'react-redux';
import { useMounted } from '../../hooks'
import schemaService from './../../services/schema'
import { RenewIcon } from './Icons'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const MyDialogTitle = getStyled(DialogTitle, ({ theme, styled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}))

function MaintenanceDialog (props) {

  const [ loading, setLoading ] = useState(false)
  const isMounted = useMounted()
  const { apiMaintenanceMode } = useSelector(state => ({
    apiMaintenanceMode: state.app.api_maintenance_mode
  }))

  const retry = () => {
    isMounted && setLoading(true)
    schemaService.get().then(() => {
      // rien à faire, la mise à jour du store est faite par l'API
    }).finally(() => {
      isMounted && setLoading(false)
    });
  }

  return (
    <Dialog open={apiMaintenanceMode} aria-labelledby="form-dialog-title">
      <MyDialogTitle id="form-dialog-title">
        Service en maintenance
      </MyDialogTitle>
      <DialogContent>
        <DialogContentText>Une opération de maintenance est en cours, nous faisons le maximum pour écourter cette coupure de service. Merci de votre compréhension.</DialogContentText>
        <DialogActions>
          <Button disabled={loading} onClick={ () => retry() } color="primary" variant="contained" startIcon={loading ? <CircularProgress size={20} /> : <RenewIcon />}>
            Tester le service
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default MaintenanceDialog;
