import { useState } from 'react'
import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import mediasService from './../../services/medias'

import Loading from '../commons/Loading'
import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import ConfirmDialog from './../commons/ConfirmDialog'
import SetFile from '../commons/SetFile'
import { PhotoIcon, SaveIcon, DeleteIcon } from './../commons/Icons'

import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const DeleteButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2)
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditMedia(props) {

  const { id, preset, fixedCtrl, onSaved, onDeleted, onError, id_project, id_project_monitoring, id_support_ticket_message } = props

  const [file, setFile] = useState()
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)

  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, isMutatingDel, mutate, mutateDel} = useEditEntity({
    initialData: {
      name: '',
    },
    id,
    queryKey: 'medias',
    presetData: preset,
    get: (id) => mediasService.get(id),
    create: (data) => mediasService.upload(data),
    update: (id, data) => mediasService.update(id, data),
    delete: (id) => mediasService.delete(id),
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listings de l'entité
      queryClient.invalidateQueries(['medias'])
    },
    onSaved,
    onDeleted,
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    // cas d'une modification
    if (!isNew) {
      mutate(formData)
      return false
    }
    // cas d'une création (on upload le fichier)
    if (!file) {
      onError('fichier manquant')
      return false
    }
    const finalFormData = new FormData()
    finalFormData.append('file', file.file)
    for (const property in formData) {
      finalFormData.append(property, formData[property])
    }
    if (id_project) {
      finalFormData.append('id_project', id_project)
    }
    if (id_project_monitoring) {
      finalFormData.append('id_project_monitoring', id_project_monitoring)
    }
    if (id_support_ticket_message) {
      finalFormData.append('id_support_ticket_message', id_support_ticket_message)
    }
    mutate(finalFormData)
    return false
  }

  // Suppression
  const remove = (e) => {
    e.preventDefault()
    mutateDel()
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating || isMutatingDel} />

      <Form onSubmit={(e) => save(e)} noValidate>

        { openConfirmRemove && (
          <ConfirmDialog
            open={openConfirmRemove}
            title="Vous confirmez la suppresssion de ce média ?"
            message="Cette action est irréversible."
            onConfirm={ (e) => {
              setOpenConfirmRemove(false)
              remove(e)
            } }
            onClose={ () => setOpenConfirmRemove(false) }
          />
        ) }

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="name"
                label="Nom"
                type="text"
                value={formData.name}
                onChange={(e) => mergeFormData({name: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          { isNew && (
            <FormField>
              <SetFile
                value={file}
                onChange={(file) => setFile(file)}
                onError={onError}
                label="Choisir une photo"
                labelDrag="Glisser une photo"
                accept="image/*"
                sx={{ width: '100%' }}
                startIcon={<PhotoIcon />}
              />
            </FormField>
          ) }

          <FormControllers fixed={fixedCtrl}>
            { !isNew && (
              <DeleteButton onClick={() => setOpenConfirmRemove(true)} color="primary" variant="outlined" startIcon={<DeleteIcon />}>
                Supprimer
              </DeleteButton>
            ) }
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>

    </Box>
  )
}

EditMedia.propTypes = {
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_project_monitoring: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_support_ticket_message: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

EditMedia.defaultProps = {
  fixedCtrl: false,
};

export default EditMedia;
