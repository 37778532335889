import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import projectsService from './../../services/projects'

import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormFieldDivider from './../commons/FormFieldDivider'
import FormControllers from './../commons/FormControllers'
import { SaveIcon } from './../commons/Icons'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Button from '@mui/material/Button'

import { MobileDatePicker } from '@mui/x-date-pickers'
import Loading from '../commons/Loading'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

const DatePickerTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

function EditProject(props) {

  const { id, preset, fixedCtrl, onSaved, onError } = props

  let datePicketMaxDate = new Date()
  datePicketMaxDate.setDate(datePicketMaxDate.getDate() + (365 * 10))

  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    id,
    initialData: {
      name: '',
      brand: 'hello-maison',
      date_begin: new Date().toISOString(),
      date_end: null,
      lat: '',
      lng: '',
      planning_google_sheet: '',
      planning_google_agenda: '',
      monitoring_version: '0.1.3'
    },
    presetData: preset,
    queryKey: 'projects',
    get: (id) => projectsService.get(id),
    create: (data) => projectsService.create(data),
    update: (id, data) => projectsService.update(id, data),
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listings de l'entité
      queryClient.invalidateQueries(['projects'])
    },
    onSaved,
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    mutate(formData)
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextField
                required
                id="name"
                label="Nom"
                type="text"
                value={formData.name}
                onChange={(e) => mergeFormData({name: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <InputLabel id="select-label-type">Marque *</InputLabel>
              <Select
                required
                labelId="select-label-type"
                label="Marque"
                id="brand"
                value={formData.brand}
                onChange={(e) => mergeFormData({brand: e.target.value})}
              >
                <MenuItem value={projectsService.BRAND_HELLO_MAISON}>{projectsService.getBrandLabel(projectsService.BRAND_HELLO_MAISON)}</MenuItem>
                <MenuItem value={projectsService.BRAND_VILLA_CREATIVE}>{projectsService.getBrandLabel(projectsService.BRAND_VILLA_CREATIVE)}</MenuItem>
                <MenuItem value={projectsService.BRAND_MAISON_STILY}>{projectsService.getBrandLabel(projectsService.BRAND_MAISON_STILY)}</MenuItem>
                <MenuItem value={projectsService.BRAND_LEXA}>{projectsService.getBrandLabel(projectsService.BRAND_LEXA)}</MenuItem>
                <MenuItem value={projectsService.BRAND_PANOSOL_R}>{projectsService.getBrandLabel(projectsService.BRAND_PANOSOL_R)}</MenuItem>
                <MenuItem value={projectsService.BRAND_PARKIMAO}>{projectsService.getBrandLabel(projectsService.BRAND_PARKIMAO)}</MenuItem>
                <MenuItem value={projectsService.BRAND_RIVAOM}>{projectsService.getBrandLabel(projectsService.BRAND_RIVAOM)}</MenuItem>
              </Select>
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <InputLabel id="select-label-type">Suivi de chantier *</InputLabel>
              <Select
                required
                labelId="select-label-monitoring-version"
                label="Suivi de chantier"
                id="monitoring-version"
                value={formData.monitoring_version}
                onChange={(e) => mergeFormData({monitoring_version: e.target.value})}
              >
                <MenuItem value="0.1.3">Version 0.1.3</MenuItem>
              </Select>
            </MyFormControl>
          </FormField>

          <FormFieldDivider />

          <FormField>
            <MyFormControl>
              <MobileDatePicker
                closeOnSelect={true}
                disablePast
                cancelText="Annuler"
                clearText="Effacer"
                okText="Valider"
                label="Date de début"
                value={formData.date_begin}
                onChange={(date) => mergeFormData({date_begin: date.toISOString()})}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <DatePickerTextField {...params} />}
                maxDate={datePicketMaxDate}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MobileDatePicker
                closeOnSelect={true}
                disablePast
                cancelText="Annuler"
                clearText="Effacer"
                okText="Valider"
                label="Date de fin estimée"
                value={formData.date_end}
                onChange={(date) => mergeFormData({date_end: date.toISOString()})}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <DatePickerTextField {...params} />}
                maxDate={datePicketMaxDate}
              />
            </MyFormControl>
          </FormField>

          <FormFieldDivider />

          <FormField>
            <MyFormControl>
              <MyTextField
                id="lat"
                label="Latitude"
                type="text"
                value={formData.lat || ''}
                onChange={(e) => {
                  const split_value = e.target.value.split(',')
                  if (split_value.length > 1) {
                    mergeFormData({lat: split_value[0].trim(), lng: split_value[1].trim()})
                  } else {
                    mergeFormData({lat: e.target.value.trim()})
                  }
                }}
                helperText="Vous pouvez directement coller une coordonnée au format Google Maps '45.700526, -1.012977'"
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="lng"
                label="Longitude"
                type="text"
                value={formData.lng || ''}
                onChange={(e) => mergeFormData({lng: e.target.value.trim()})}
              />
            </MyFormControl>
          </FormField>

          <FormFieldDivider />

          <FormField>
            <MyFormControl>
              <MyTextField
                id="planning_google_sheet"
                label="URL du planning Google Sheet"
                type="text"
                value={formData.planning_google_sheet}
                onChange={(e) => mergeFormData({planning_google_sheet: e.target.value})}
                helperText="Cette URL sera embarquée dans une iframe"
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextField
                id="planning_google_agenda"
                label="URL du planning Google Agenda"
                type="text"
                value={formData.planning_google_agenda}
                onChange={(e) => mergeFormData({planning_google_agenda: e.target.value})}
                helperText="Cette URL sera embarquée dans une iframe"
              />
            </MyFormControl>
          </FormField>

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              {isNew ? "Créer" : "Modifier"}
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>
    </Box>
  )
}

EditProject.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditProject.defaultProps = {
  fixedCtrl: false,
  preset: {}
};

export default EditProject;
