import PropTypes from 'prop-types'
import { getStyled } from '../../services/styles'

import { CloseIcon } from './../commons/Icons'

import Box from '@mui/material/Box'

const MainBox = getStyled(Box, ({ theme, styled }) => {
  let style = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  }
  if (styled?.cliquable) {
    style.cursor = 'pointer'
  }
  return style
})

const IconBox = getStyled(Box, ({ theme, styled }) => ({
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.54)',
}))

const LabelBox = getStyled(Box, ({ theme, styled }) => ({
  width: '100%',
}))

const IconDeleteBox = getStyled(Box, ({ theme, styled }) => ({
  marginLeft: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.54)',
}))

function SelectAsyncChip (props) {

  const { icon, iconDelete, label, onClick, onDelete } = props
  
  return (
    <MainBox styled={{cliquable: onClick ? true : false}}>
      {icon && (<IconBox onClick={onClick}>{icon}</IconBox>)}
      {label && (<LabelBox onClick={onClick}>{label}</LabelBox>)}
      {onDelete && (<IconDeleteBox onClick={onDelete}>{iconDelete || <CloseIcon />}</IconDeleteBox>)}
    </MainBox>
  )
}

SelectAsyncChip.propTypes = {
  icon: PropTypes.element,
  iconDelete: PropTypes.element,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
}

export default SelectAsyncChip
