import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id, params) {
    return API.get('/workerCats/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (params) {
    return API.get('/workerCats/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (data) {
    throw new Error('l\'API ne permet pas de créer une catégorie professionnelle')
  },
  async update (id, data) {
    throw new Error('l\'API ne permet pas de modifier une catégorie professionnelle')
  },
  async delete (id) {
    throw new Error('l\'API ne permet pas de supprimer une catégorie professionnelle')
  },
}

export default exportedObject;
