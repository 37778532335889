import { useState } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'

import MediasGallery from '../media/MediasGallery'
import DialogActionsSupportTicketMessageMedia from '../dialog/DialogActionsSupportTicketMessageMedia'
import Box from '@mui/material/Box'

const RootBox = getStyled(Box, ({theme}) => ({
  width: '100%',
  padding: theme.spacing(1)
}))

function SupportTicketMessageMedias (props) {

  const { styled, id, onError } = props

  const [media, setMedia] = useState(null)
  const [openDalogActions, setOpenDalogActions] = useState(false)

  return (
    <RootBox styled={{style: (styled && styled.root) ?? {}}}>
      <MediasGallery
        size="large"
        cols={1}
        query={{id_support_ticket_message: id}}
        onClick={(e, item) => {
          setMedia(item)
          setOpenDalogActions(true)
        }}
        onError={onError}
      />
      {openDalogActions && media && (
        <DialogActionsSupportTicketMessageMedia
          open={openDalogActions}
          media={media}
          onError={onError}
          onClose={() => setOpenDalogActions(false)}
        />
      )}
    </RootBox>
  )
}

SupportTicketMessageMedias.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onError: PropTypes.func.isRequired,
}

export default SupportTicketMessageMedias;