import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import projectsService from './../../services/projects'

import BackdropLoading from './../commons/BackdropLoading'
import ConfirmDeleteDialog from '../commons/ConfirmDeleteDialog'

function DialogDeleteProject (props) {

  const { id, open, onDeleted, onClose, onError } = props

  const queryClient = useQueryClient()

  // Mutation
  const { isLoading: isMutating, mutate } = useMutation(() => {
    return projectsService.delete(id)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError && onError(notices)
      // invalidation des queries
      queryClient.invalidateQueries(['projects'])
      onDeleted(id)
    },
    onError
  })

  return (
    <Fragment>
      <BackdropLoading open={isMutating} />
      <ConfirmDeleteDialog
        open={open}
        title="Suppression du chantier ?"
        message="Les invitations, les médias, les documents, les procédures de signatures, les tickets de support, les suivis de chantiers rattachés à ce chantier vont être supprimés. Cette action est irreversible."
        onConfirm={ (e) => {
          mutate()
        } }
        onClose={onClose}
      />
    </Fragment>
  )
}

DialogDeleteProject.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  open: PropTypes.bool,
  onDeleted: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default DialogDeleteProject;
