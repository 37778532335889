import { getStyled } from './../../services/styles'

import Divider from '@mui/material/Divider'

const MyDivider = getStyled(Divider, ({theme}) => ({
  width: '100%',
}));

export default function FormFieldDivider (props) {

  const { children, styled, ...others } = props;

  return (
      <MyDivider styled={{style: (styled && styled.root) ?? {}}} {...{...{light: true}, ...others}} />
  )
}
