import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'
import utilsService from '../../services/utils'

import FormField from './../commons/FormField'
import TextFieldLimited from './../commons/TextFieldLimited'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import { MobileDatePicker } from '@mui/x-date-pickers'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const MyTextFieldLimited = getStyled(TextFieldLimited, ({ theme }) => ({
  width: '100%',
}))

const DatePickerTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

function EditProjectMonitoringField(props) {

  const { field, formData, mergeFormData } = props
  
  if (field.type === 'checkbox') {
    const checked = formData ? utilsService.isTrue(formData[field.name]) : false
    return (
      <FormField>
        <MyFormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => mergeFormData({[field.name]: !checked})}
              />
            }
            label={field.label}
          />
        </MyFormControl>
        {field.helper && <FormHelperText>{field.helper}</FormHelperText> }
      </FormField>
    )
  }
  if (field.type === 'text') {
    return (
      <FormField>
        <MyFormControl>
          <MyTextField
            required
            id={field.name}
            label={field.label}
            type="text"
            value={formData && formData[field.name] ? formData[field.name] : ''}
            onChange={(e) => mergeFormData({[field.name]: e.target.value})}
          />
        </MyFormControl>
        {field.helper && <FormHelperText>{field.helper}</FormHelperText> }
      </FormField>
    )
  }
  if (field.type === 'textarea') {
    return (
      <FormField>
        <MyFormControl>
          <MyTextFieldLimited
            required
            id={field.name}
            label={field.label}
            type="text"
            value={formData && formData[field.name] ? formData[field.name] : ''}
            onChange={(value) => mergeFormData({[field.name]: value})}
            limit={1000}
            multiline={true}
          />
        </MyFormControl>
        {field.helper && <FormHelperText>{field.helper}</FormHelperText> }
      </FormField>
    )
  }
  if (field.type === 'date') {
    let datePicketMaxDate = new Date()
    datePicketMaxDate.setDate(datePicketMaxDate.getDate() + (365 * 10))
    return (
      <FormField>
        <MyFormControl>
          <MobileDatePicker
            id={field.name}
            closeOnSelect={true}
            disablePast
            cancelText="Annuler"
            clearText="Effacer"
            okText="Valider"
            label={field.label}
            value={formData && formData[field.name] ? formData[field.name] : null}
            onChange={(date) => mergeFormData({[field.name]: date.toISOString()})}
            inputFormat="dd/MM/yyyy"
            renderInput={(params) => <DatePickerTextField {...params} />}
            maxDate={datePicketMaxDate}
          />
        </MyFormControl>
        {field.helper && <FormHelperText>{field.helper}</FormHelperText> }
      </FormField>
    )
  }

  return <Box>Type de champs non pris en charge : '{field.type}'</Box>
}

EditProjectMonitoringField.propTypes = {
  field: PropTypes.object.isRequired,
  formData: PropTypes.object,
  mergeFormData: PropTypes.func.isRequired,
}

export default EditProjectMonitoringField
