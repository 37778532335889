import { getStyled } from './../../services/styles'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import documentsService from './../../services/documents'
import utilsService from './../../services/utils'

import BackdropLoading from './../commons/BackdropLoading'
import Loading from '../commons/Loading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import TextFieldLimited from '../commons/TextFieldLimited'
import { SaveIcon } from './../commons/Icons'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const MyTextField = getStyled(TextField, ({ theme }) => ({
  width: '100%',
}))

const MyTextFieldLimited = getStyled(TextFieldLimited, ({ theme }) => ({
  width: '100%',
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditDocument(props) {

  const { id, id_project, preset, fixedCtrl, onSaved, onError } = props

  const {formData, mergeFormData, isFetching, isError, isMutating, mutate} = useEditEntity({
    initialData: {
      name: '',
      description: '',
      type: documentsService.TYPE_CONTRACT,
      is_endorsment: false,
      is_acknowledgment: false,
      endorsment_options: {grouped: true},
      acknowledgment_options: {},
    },
    id,
    presetData: preset,
    queryKey: 'documents',
    get: (id) => documentsService.get(id),
    create: (data) => documentsService.create(data),
    update: (id, data) => documentsService.update(id, data),
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries des listing de l'entité
      queryClient.invalidateQueries(['documents'])
    },
    onSaved,
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    if (!formData?.name || formData.name === '') {
      onError('Nom manquant')
      return false
    }
    const finalFormData = id_project ? {...formData, ...{id_project}} : {...formData}
    mutate(finalFormData)
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <MyFormControl>
              <MyTextField
                required
                id="name"
                label="Nom"
                type="text"
                name="document-name"
                value={formData.name}
                onChange={(e) => mergeFormData({name: e.target.value})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <MyTextFieldLimited
                id="description"
                label="Description"
                type="text"
                value={formData.description}
                onChange={(description) => mergeFormData({description})}
              />
            </MyFormControl>
          </FormField>

          <FormField>
            <MyFormControl>
              <InputLabel id="select-label-type">Type *</InputLabel>
              <Select
                required
                labelId="select-label-type"
                label="Type"
                id="type"
                value={formData.type}
                onChange={(e) => mergeFormData({type: e.target.value})}
              >
                <MenuItem value={documentsService.TYPE_CONTRACT}>{documentsService.getTypeLabel(documentsService.TYPE_CONTRACT)}</MenuItem>
                <MenuItem value={documentsService.TYPE_INVOICE}>{documentsService.getTypeLabel(documentsService.TYPE_INVOICE)}</MenuItem>
                <MenuItem value={documentsService.TYPE_DEAL}>{documentsService.getTypeLabel(documentsService.TYPE_DEAL)}</MenuItem>
                <MenuItem value={documentsService.TYPE_OTHER}>{documentsService.getTypeLabel(documentsService.TYPE_OTHER)}</MenuItem>
              </Select>
            </MyFormControl>
          </FormField>

          <FormField>
            <FormControlLabel
              control={
                <Switch
                  disabled={!!id}
                  checked={utilsService.isTrue(formData.is_endorsment)}
                  onChange={ (e) => {
                    mergeFormData({is_endorsment: e.target.checked})
                  } }
                  inputProps={{ 'aria-label': 'procédure de signature' }}
                />
              }
              label={
                <Typography component="div" variant="body2">Document à signer (utilise Yousign)</Typography>
              }
            />
          </FormField>

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>

    </Box>
  )
}

EditDocument.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_project: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditDocument.defaultProps = {
  fixedCtrl: false,
};

export default EditDocument;
