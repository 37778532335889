import SupportTicketMessages_API from './api/supportTicketMessages'

/**************************************************************
 Interface avec l'API relative aux messages tickets de support
***************************************************************/

const exportedObject = {

  /**************** support ticket messages ************************************/
  /**
   * Récupère les messages de ticket de support
   * @return Promise(data, notices) : les relations
   */
  getAll (id_support_ticket, args) {
    return new Promise((resolve, reject) => {
      SupportTicketMessages_API.getAll(id_support_ticket, args).then(({data, notices}) => {
        console.log('Messages de ticket récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un message de ticket de support
   * @return Promise(data, notices) : le message de ticket de support
   */
  get (id_support_ticket, id, args) {
    return new Promise((resolve, reject) => {
      SupportTicketMessages_API.get(id_support_ticket, id, args).then(({data, notices}) => {
        console.log('Message de ticket récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un message de ticket de support
   * @return Promise(data, notices) : l'identifiant du message de ticket de support créé
   */
  create (id_support_ticket, data) {
    return new Promise((resolve, reject) => {
      SupportTicketMessages_API.create(id_support_ticket, data).then(({data, notices}) => {
        console.log('Message de ticket créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un message de ticket de support
   * @return Promise(data, notices) : l'identifiant du message de ticket de support mis à jour
   */
  update (id_support_ticket, id, data) {
    return new Promise((resolve, reject) => {
      SupportTicketMessages_API.update(id_support_ticket, id, data).then(({data, notices}) => {
        console.log('Message de ticket mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un message de ticket de support
   * @return Promise(id, data, notices) : l'identifiant du message de ticket de support supprimé
   */
  delete (id_support_ticket, id) {
    return new Promise((resolve, reject) => {
      SupportTicketMessages_API.delete(id_support_ticket, id).then(({data, notices}) => {
        console.log('Message de ticket supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Retourne l'avatar de la relation
   * @param {object} project 
   * @returns 
   */
  getAvatar (project) {
    return null
  },
};
export default exportedObject;
