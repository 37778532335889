import Schema_API from './api/schema';

/****************************************************
 Interface avec l'API racine
****************************************************/

const exportedObject = {
  /**
   * Récupère le schema de l'API
   * @return Promise(data, notices) : le schema
   */
  get (args) {
    return new Promise((resolve, reject) => {
      Schema_API.get(args).then(({data, notices}) => {
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
export default exportedObject;
