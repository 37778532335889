import { useState } from 'react'
import { getStyled } from './../../services/styles'
import { useQuery } from '@tanstack/react-query'
import { useEditEntity } from './../../hooks'
import PropTypes from 'prop-types'
import documentsService from './../../services/documents'
import documentsScopesService from './../../services/documents-scopes'
import projectUserRelsService from './../../services/projectUserRels'

import Loading from '../commons/Loading'
import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import ConfirmDialog from './../commons/ConfirmDialog'
import SelectUser from '../commons/SelectUser'
import { SaveIcon, DeleteIcon, InfoIcon, AddIcon } from './../commons/Icons'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import SelectWorkerCat from '../commons/SelectWorkerCat'
import Typography from '@mui/material/Typography'
import DialogEditInvitation from '../dialog/DialogEditInvitation'

const MyFormControl = getStyled(FormControl, ({ theme }) => ({
  width: '100%',
}))

const DeleteButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2)
}))

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

const InfoCardContent = getStyled(CardContent, ({ theme }) => ({
  display: 'flex',
}))

function EditDocumentScope(props) {

  const { id, preset, fixedCtrl, onSaved, onDeleted, onError, id_document } = props

  const [type, setType] = useState('role')
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)
  const [selectUserQuery, setSelectUserQuery] = useState({})
  const [selectWorkerCatQuery, setSelectWorkerCatQuery] = useState({})
  const [dialogEditInvitationOpened, setDialogEditInvitationOpened] = useState(false)

  const {isNew, formData, mergeFormData, isFetching, isError, isMutating, isMutatingDel, mutate, mutateDel} = useEditEntity({
    initialData: {
      project_role: '',
      id_user: null,
      id_worker_cat: null
    },
    mergeFormDataFilter: (key, data) => {
      if (key === 'project_role' && !data) {
        return ''
      }
      return data
    },
    id,
    queryKey: 'documents-' + id_document + '-scopes',
    presetData: preset,
    get: (id) => documentsScopesService.get(id_document, id),
    create: (data) => documentsScopesService.create(id_document, data),
    update: (id, data) => documentsScopesService.update(id_document, id, data),
    delete: (id) => documentsScopesService.delete(id_document, id),
    onSaved,
    onDeleted,
    onError,
    afterMutation: ({queryClient, queryKeys}) => {
      // invalide les queries de l'entité
      queryClient.invalidateQueries(queryKeys)
      // invalide les queries du document parent
      queryClient.invalidateQueries(['documents-' + id_document + '-scopes'])
      queryClient.invalidateQueries(['documents', id_document])
    },
  })

  // Chargement du document
  const { isFetching: isFetchingDocument, isError: isErrorDocument, refetch: refetchDocument, data: document } = useQuery(['documents', id_document], async () => {
    return documentsService.get(id_document).then(([item]) => item)
  }, { onError, enabled: !!id_document })

  // Chargement des projectUserRels du projet du document en fonction de l'utilisateur sélectionné
  const projectUserRelsLimit = 1
  const projectUserRelsQuery = document?.id_project && formData?.id_user ? {id_project: document?.id_project, id_user: formData.id_user} : undefined
  const projectUserRelsQueryKey = ['project-user-rels', projectUserRelsQuery || 'all', `limit_${projectUserRelsLimit}`]
  const { isFetching: isFetchingProjectUserRels, isError: isErrorProjectUserRels, refetch: refetchProjectUserRels, data: projectUserRels } = useQuery(projectUserRelsQueryKey, async () => {
    return projectUserRelsService.getAll({ ...projectUserRelsQuery, ...{ limit: projectUserRelsLimit, offset: 0 } }).then(([items]) => items)
  }, { onError, enabled: !!projectUserRelsQuery })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    mutate(formData)
    return false
  }

  // Suppression
  const remove = (e) => {
    e.preventDefault()
    mutateDel()
    return false
  }

  if (isFetchingDocument || isErrorDocument) {
    return <Loading linear={true} error={isErrorDocument} refetch={refetchDocument} />
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating || isMutatingDel} />

      { (isFetchingProjectUserRels || isErrorProjectUserRels) && (
        <Loading linear={true} error={isErrorProjectUserRels} refetch={refetchProjectUserRels} />
      ) }

      <Form onSubmit={(e) => save(e)} noValidate>

        { openConfirmRemove && (
          <ConfirmDialog
            open={openConfirmRemove}
            title="Vous confirmez la suppresssion de cette cible ?"
            message="Cette action est irréversible."
            onConfirm={ (e) => {
              setOpenConfirmRemove(false)
              remove(e)
            } }
            onClose={ () => setOpenConfirmRemove(false) }
          />
        ) }

        <FormFields>

          <Card elevation={1}>
            <InfoCardContent>
              <InfoIcon color="primary" sx={{marginRight: 1}} />
              <Box>
                <Typography variant="body2" component="div" sx={{marginBottom: 0.5}}>Les cibles utilisateurs permettent de définir qui verra le document. Si le document est soumis à signature YouSign, les cibles définissent aussi qui devra signer.</Typography>
                <Typography variant="body2" component="div">Certains utilisateurs qui ont un rôle de haut niveau dans le chantier, comme l'administrateur, l'assistant ou encore le conducteur etc., voient le document sans faire partie des cibles.</Typography>
              </Box>
            </InfoCardContent>
          </Card>

          <FormField>
            <MyFormControl>
              <InputLabel id="select-label-role">Type de ciblage</InputLabel>
              <Select
                required
                labelId="select-label-type"
                label="Type de ciblage"
                id="type"
                value={type}
                onChange={(e) => {
                  mergeFormData({id_user: null, project_role: '', id_worker_cat: null}) // raz des valeurs déjà sélectionnées
                  setType(e.target.value)
                }}
              >
                <MenuItem value="role">Cibler des utilisateurs par rôle</MenuItem>
                <MenuItem value="user">Cibler un utilisateur spécifique</MenuItem>
              </Select>
            </MyFormControl>
          </FormField>

          { type === 'user' && (
            <>
              <FormField>
                <SelectUser
                  title="Cibler un utilisateur spécifique"
                  query={selectUserQuery}
                  onQueryChange={(query) => setSelectUserQuery(query)}
                  value={formData.id_user}
                  onChange={(value) => mergeFormData({id_user: value})}
                  onError={onError}
                />
              </FormField>

              <Box>
                { projectUserRelsQuery && projectUserRels && projectUserRels.length < 1 && (
                  <Card elevation={1}>
                    <InfoCardContent>
                      <InfoIcon color="primary" sx={{marginRight: 1}} />
                      <Box>
                        <Typography variant="body2" component="div">L'utilisateur sélectionné n'a aucun rôle dans le projet, vous pouvez l'inviter pour que cette cible de document puisse être enregistrée.</Typography>
                      </Box>
                    </InfoCardContent>
                    <CardActions>
                      <Button variant="outlined" startIcon={<AddIcon />} onClick={() => setDialogEditInvitationOpened(true)}>Inviter cet utilisateur</Button>
                    </CardActions>
                  </Card>
                ) }
              </Box>
            </>
          ) }

          { type === 'role' && (
            <FormField>
              <MyFormControl>
                <InputLabel id="select-label-role">Cibler les utilisateurs ayant le rôle suivant</InputLabel>
                <Select
                  required
                  labelId="select-label-role"
                  label="Cibler les utilisateurs ayant le rôle suivant"
                  id="project_role"
                  value={formData.project_role}
                  onChange={(e) => mergeFormData({project_role: e.target.value})}
                >
                  <MenuItem value="">Pas de ciblage par rôle</MenuItem>
                  <MenuItem value={projectUserRelsService.ROLE_ADMINISTRATOR}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_ADMINISTRATOR)}</MenuItem>
                  <MenuItem value={projectUserRelsService.ROLE_ASSISTANT}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_ASSISTANT)}</MenuItem>
                  <MenuItem value={projectUserRelsService.ROLE_MANAGER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_MANAGER)}</MenuItem>
                  <MenuItem value={projectUserRelsService.ROLE_WORKER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_WORKER)}</MenuItem>
                  <MenuItem value={projectUserRelsService.ROLE_CUSTOMER}>{projectUserRelsService.getRoleLabel(projectUserRelsService.ROLE_CUSTOMER)}</MenuItem>
                </Select>
              </MyFormControl>
            </FormField>
          ) }

          { type === 'role' && formData.project_role === projectUserRelsService.ROLE_WORKER && (
            <SelectWorkerCat
              title="Cibler une catégorie pro. spécifique"
              label="Aucune catégorie pro. spécifique"
              query={selectWorkerCatQuery}
              onQueryChange={(query) => setSelectWorkerCatQuery(query)}
              value={formData.id_worker_cat}
              onChange={(value) => mergeFormData({id_worker_cat: value})}
              onError={onError}
            />
          ) }

          <FormControllers fixed={fixedCtrl}>
            { !isNew && (
              <DeleteButton onClick={() => setOpenConfirmRemove(true)} color="primary" variant="outlined" startIcon={<DeleteIcon />}>
                Supprimer
              </DeleteButton>
            ) }
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              Enregistrer
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>

      { dialogEditInvitationOpened && (
        <DialogEditInvitation
          open={dialogEditInvitationOpened}
          onError={onError}
          onClose={() => setDialogEditInvitationOpened(false)}
          onSaved={() => setDialogEditInvitationOpened(false)}
          id_project={document?.id_project}
          preset={{
            id_user: formData?.id_user
          }}
        />
      ) }

    </Box>
  )
}

EditDocumentScope.propTypes = {
  preset: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id_document: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

EditDocumentScope.defaultProps = {
  fixedCtrl: false,
};

export default EditDocumentScope;
