import Documents_API from './api/documents'

/****************************************************
 Interface avec l'API relative aux documents
****************************************************/

const exportedObject = {
  /**
   * Constantes
   */
  STATE_DRAFT: 'draft', // le document est en brouillon (il est modifiable)
  STATE_VALIDATED: 'validated', // le document est validé (il n'est plus modifiable)

  TYPE_CONTRACT: 'contract', // le document est un contrat
  TYPE_INVOICE: 'invoice', // le document est une facture
  TYPE_DEAL: 'deal', // le document est un marché
  TYPE_OTHER: 'other', // le document est autre

  /**************** documents ************************************/
  /**
   * Récupère les documents du serveur
   * @return Promise(data, notices) : les documents
   */
  getAll (args) {
    return new Promise((resolve, reject) => {
      Documents_API.getAll(args).then(({data, notices}) => {
        console.log('Documents récupérés : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Récupère un document du serveur
   * @return Promise(data, notices) : le document
   */
  get (id, args) {
    return new Promise((resolve, reject) => {
      Documents_API.get(id, args).then(({data, notices}) => {
        console.log('Document récupéré : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Créé un nouveau document
   * @return Promise(data, notices) : l'identifiant du document créé
   */
  create (data) {
    return new Promise((resolve, reject) => {
      Documents_API.create(data).then(({data, notices}) => {
        console.log('Document créé : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Met à jour un document
   * @return Promise(data, notices) : l'identifiant du document mis à jour
   */
  update (id, data) {
    return new Promise((resolve, reject) => {
      Documents_API.update(id, data).then(({data, notices}) => {
        console.log('Document mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Supprime un document
   * @return Promise(id, data, notices) : l'identifiant du document supprimée
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      Documents_API.delete(id).then(({data, notices}) => {
        console.log('Document supprimé : ', id, data, 'avertissements : ', notices);
        resolve([id, data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** documents download ********************************/
  /**
   * Télécharge des document
   * @return Promise(data, notices) : le document
   */
  bulk_download (params) {
    return new Promise((resolve, reject) => {
      Documents_API.bulk_download(params).then((response) => {
        // console.log('Documents téléchargé - response : ', response);
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**************** document state ************************************/
  /**
   * Met à jour le statut d'un document
   * @return Promise(data, notices) : l'identifiant du document mis à jour
   */
  setState (id, state, state_comment = '') {
    return new Promise((resolve, reject) => {
      Documents_API.setState(id, state, state_comment).then(({data, notices}) => {
        console.log('Statut de document mis à jour : ', data, 'avertissements : ', notices);
        resolve([data, notices]);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  /**
   * Affiche le libellé du statut
   * @return string
   */
  getStateLabel (slug) {
    if (!slug) {
      return 'aucun';
    }
    switch (slug) {
      case this.STATE_DRAFT: {
        return 'brouillon';
      }
      case this.STATE_VALIDATED: {
        return 'publié';
      }
      default: {
        return slug;
      }
    }
  },
  /**
   * Renvoi le slug de la couleur du statut du document
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getStateColor (slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.STATE_DRAFT: {
        return 'document_state_draft';
      }
      case this.STATE_VALIDATED: {
        return 'document_state_validated';
      }
      default: {
        return null;
      }
    }
  },
  /**
   * Affiche le libellé du statut d'un document
   * @return string
   */
  getDocumentStateLabel (document) {
    if (!document) {
      return 'aucun';
    }
    return this.getStateLabel(document.state);
  },
  /**
   * Indique si la document est en statut 'draft'
   * @return boolean
   */
  isStateDraft(document) {
    if (document && !document.state) {
      console.warn("isStateDraft - la document n'a pas de statut");
    }
    return document && document.state && document.state === this.STATE_DRAFT ? true : false;
  },
  /**
   * Indique si la document est en statut 'validé'
   * @return boolean
   */
  isStateValidated(document) {
    if (document && !document.state) {
      console.warn("isStateValidated - la document n'a pas de statut");
    }
    return document && document.state && document.state === this.STATE_VALIDATED ? true : false;
  },
  /**
   * Renvoi le slug de la couleur du statut des signatures du document
   * (ce slug correspond à une définition de couleur de la pallette MUI)
   * NOTE : la couleur peur être null dans certains cas.
   * @return string
   */
  getEndorsmentStateColor (document) {
    if (!document?.count_document_endorsment || document?.count_document_endorsment === 0) {
      return 'document_endorsment_state_pending'
    }
    if (document?.count_document_endorsment_done === document?.count_document_endorsment) {
      return 'document_endorsment_state_done'
    }
    if (document?.count_document_endorsment_error > 0) {
      return 'document_endorsment_state_error'
    }
    return 'document_endorsment_state_processing'
  },
  /**
   * Affiche le libellé du statut d'un document
   * @return string
   */
  getEndorsmentStateLabel (document) {
    if (!document?.count_document_endorsment || document?.count_document_endorsment === 0) {
      return 'aucune signature'
    }
    if (document?.count_document_endorsment_done === document?.count_document_endorsment) {
      return 'signé'
    }
    if (document?.count_document_endorsment_done > 0) {
      return 'partiellement signé'
    }
    if (document?.count_document_endorsment_error === document?.count_document_endorsment) {
      return 'signature en erreur'
    }
    if (document?.count_document_endorsment_error > 0) {
      return 'signature partiellement en erreur'
    }
    return 'en attente des signatures'
  },
  /**
   * Renvoi l'avatar du document
   * @param {object} document 
   * @returns 
   */
  getAvatar (document) {
    return null
  },
  /**
   * Renvoi le libellé du type de document
   * @param {string} slug 
   */
  getTypeLabel(slug) {
    if (!slug) {
      return null;
    }
    switch (slug) {
      case this.TYPE_CONTRACT: {
        return 'Contrat';
      }
      case this.TYPE_INVOICE: {
        return 'Facture';
      }
      case this.TYPE_DEAL: {
        return 'Marché';
      }
      case this.TYPE_OTHER: {
        return 'Autre';
      }
      default: {
        return slug;
      }
    }
  },
};
export default exportedObject;
