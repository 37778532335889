import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import appReducer from './features/appSlice'
import authReducer from './features/authSlice'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    'app',
    /* other not persitent storeSlice or which just have some not persitent (blacklisted) attributes */
  ]
}

const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: [
    'online',
    'webpush_notifications_supported',
    'webpush_notifications_enabled',
    'shortAction',
    /* other blacklisted attributes */
  ]
}

const authPersistConfig = {
  key: 'auth',
  storage: storage
}

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
})

export const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false, // disable serializable action type
  }),
})

export const persistor = persistStore(store)
