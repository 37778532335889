import { Fragment } from 'react'
import PropTypes from 'prop-types'
import workerCatsService from './../../services/workerCats'

import { WorkerCatIcon } from './../commons/Icons'
import ListItemContent from './../commons/ListItemContent'
import ListItemContentSummary1 from './../commons/ListItemContentSummary1'
import ListItemContentSummary2 from './../commons/ListItemContentSummary2'
import ListItemContentSummary3 from './../commons/ListItemContentSummary3'

function WorkerCatListItem (props) {

  const { workerCat, ...others } = props

  const avatar = workerCatsService.getAvatar(workerCat)

  return (
    <ListItemContent
      avatar={avatar ? avatar.content : <WorkerCatIcon />}
      avatarProps={avatar ? avatar.props : null}
      summary={(
        <Fragment>
          <ListItemContentSummary1>
            #{workerCat.ID} {workerCat.name}
          </ListItemContentSummary1>
          { workerCat.contact && workerCat.contact.address_city && (
            <ListItemContentSummary2>
              { workerCat.contact.address_city }
            </ListItemContentSummary2>
          ) }
          { workerCat.email && (
            <ListItemContentSummary3>
              { workerCat.email }
            </ListItemContentSummary3>
          ) }
        </Fragment>
      )}
      {...others}
    />
  )
}

WorkerCatListItem.propTypes = {
  workerCat: PropTypes.object,
};

export default WorkerCatListItem
