import API from './api';

/**************************************************
 IMPORTANT : ne pas utiliser directement les API
 vous devez utiliser les services à la place
 **************************************************/

const exportedObject = {

  async get (id_document, id, params) {
    return API.get('/documents/' + id_document + '/scopes/' + id, { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async getAll (id_document, params) {
    return API.get('/documents/' + id_document + '/scopes/', { params })
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async create (id_document, data) {
    return API.post('/documents/' + id_document + '/scopes/', data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async update (id_document, id, data) {
    return API.post('/documents/' + id_document + '/scopes/' + id, data)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
  async delete (id_document, id) {
    return API.delete('/documents/' + id_document + '/scopes/' + id)
      .then(response => response.data)
      .catch(error => Promise.reject(error.response));
  },
}

export default exportedObject;
