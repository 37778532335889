import React, {lazy} from 'react'

const Home = lazy(() => import('./../views/Home'))
const Informations = lazy(() => import('./../views/Informations'))
const InvitationAccept = lazy(() => import('./../views/InvitationAccept'))
const LostPassword = lazy(() => import('./../views/LostPassword'))
const UserPlanning = lazy(() => import('./../views/UserPlanning'))
const Projects = lazy(() => import('./../views/Projects'))
const Project = lazy(() => import('./../views/Project'))
const ProjectUserRel = lazy(() => import('./../views/ProjectUserRel'))
const ProjectUserRels = lazy(() => import('./../views/ProjectUserRels'))
const ProjectMap = lazy(() => import('./../views/ProjectMap'))
const ProjectPlanningLong = lazy(() => import('./../views/ProjectPlanningLong'))
const ProjectPlanningShort = lazy(() => import('./../views/ProjectPlanningShort'))
const ProjectMonitorings = lazy(() => import('./../views/ProjectMonitorings'))
const ProjectMonitoring = lazy(() => import('./../views/ProjectMonitoring'))
const SupportTicket = lazy(() => import('./../views/SupportTicket'))
const SupportTickets = lazy(() => import('./../views/SupportTickets'))
const ProjectSupportTickets = lazy(() => import('./../views/ProjectSupportTickets'))
const ProjectContacts = lazy(() => import('./../views/ProjectContacts'))
const ProjectDocuments = lazy(() => import('./../views/ProjectDocuments'))
const ProjectMedias = lazy(() => import('./../views/ProjectMedias'))
const ProjectNotebooks = lazy(() => import('./../views/ProjectNotebooks'))
const Documents = lazy(() => import('./../views/Documents'))
const Document = lazy(() => import('./../views/Document'))
const DocumentAttachments = lazy(() => import('./../views/DocumentAttachments'))
const User = lazy(() => import('./../views/User'))
const Users = lazy(() => import('./../views/Users'))
const Tests = lazy(() => import('./../views/Tests'))

const routes = [
  // Home
  {
    key: 'home',
    path: "/",
    exact: true,
    component: <Home />,
    loggedIn: true,
  },
  // Lost password
  {
    key: 'lost-password',
    path: "/lost-password",
    component: <LostPassword />,
  },
  // Informations
  {
    key: 'informations',
    path: "/informations",
    component: <Informations />,
  },
  // Invitation accept
  {
    key: 'invitation-accept',
    path: "/invitation-accept/:token",
    component: <InvitationAccept />,
  },
  // User Planning
  {
    key: 'user-planning',
    path: "/users/:id/planning",
    component: <UserPlanning />,
    loggedIn: true,
  },
  // Users
  {
    key: 'user',
    path: "/users/:id",
    component: <User />,
    loggedIn: true,
  },
  {
    key: 'users',
    path: "/users",
    component: <Users />,
    loggedIn: true,
  },
  // SupportTicket
  {
    key: 'support-ticket',
    path: "/support-tickets/:id",
    component: <SupportTicket />,
    loggedIn: true,
  },
  // SupportTickets
  {
    key: 'support-tickets',
    path: "/support-tickets/",
    component: <SupportTickets />,
    loggedIn: true,
  },
  // Project User Rels
  {
    key: 'project-user-rel',
    path: "/project-user-rels/:id",
    component: <ProjectUserRel />,
    loggedIn: true,
  },
  // Project's Project User Rels
  {
    key: 'projects-project-user-rels',
    path: "/projects/:id/user-rels",
    component: <ProjectUserRels />,
    loggedIn: true,
  },
  // Project's Map
  {
    key: 'project-map',
    path: "/projects/:id/map",
    component: <ProjectMap />,
    loggedIn: true,
  },
  // Project's Planning Long Term
  {
    key: 'project-planning-long',
    path: "/projects/:id/planning-long",
    component: <ProjectPlanningLong />,
    loggedIn: true,
  },
  // Project's Planning Short Term
  {
    key: 'project-planning-short',
    path: "/projects/:id/planning-short",
    component: <ProjectPlanningShort />,
    loggedIn: true,
  },
  // Project's Monitoring's Item
  {
    key: 'project-monitorings',
    path: "/projects/:id/monitorings/:slug",
    component: <ProjectMonitoring />,
    loggedIn: true,
  },
  // Project's Monitorings
  {
    key: 'project-monitorings',
    path: "/projects/:id/monitorings",
    component: <ProjectMonitorings />,
    loggedIn: true,
  },
  // Project's SupportTickets
  {
    key: 'project-support-tickets',
    path: "/projects/:id/support-tickets",
    component: <ProjectSupportTickets />,
    loggedIn: true,
  },
  // Project's Contacts
  {
    key: 'project-contacts',
    path: "/projects/:id/contacts",
    component: <ProjectContacts />,
    loggedIn: true,
  },
  // Project's Documents
  {
    key: 'project-documents',
    path: "/projects/:id/documents",
    component: <ProjectDocuments />,
    loggedIn: true,
  },
  // Project's Medias
  {
    key: 'project-medias',
    path: "/projects/:id/medias",
    component: <ProjectMedias />,
    loggedIn: true,
  },
  // Project's Notebooks
  {
    key: 'project-notebooks',
    path: "/projects/:id/notebooks",
    component: <ProjectNotebooks />,
    loggedIn: true,
  },
  // Projects
  {
    key: 'project',
    path: "/projects/:id",
    component: <Project />,
    loggedIn: true,
  },
  {
    key: 'projects',
    path: "/projects",
    component: <Projects />,
    loggedIn: true,
  },
  // Document's Attachments
  {
    key: 'document-attachments',
    path: "/documents/:id/attachments",
    component: <DocumentAttachments />,
    loggedIn: true,
  },
  // Documents
  {
    key: 'document',
    path: "/documents/:id",
    component: <Document />,
    loggedIn: true,
  },
  {
    key: 'documents',
    path: "/documents",
    component: <Documents />,
    loggedIn: true,
  },
  // Tests
  {
    key: 'tests',
    path: "/tests/",
    component: <Tests />,
    loggedIn: true,
  },
];
export default routes;
