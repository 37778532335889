import { getStyled } from './../../services/styles'
import PropTypes from 'prop-types'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

const MyContainer = getStyled(Container, ({theme}) => ({
  display: 'flex',
}));

const MyAppBar = getStyled(AppBar, ({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  top: 'auto',
  bottom: 0,
  borderTop: '1px solid ' + theme.palette.tiertiary.main,
}));

const MyToolbar = getStyled(Toolbar, ({theme}) => ({
  padding: 0,
}));

const MyBox = getStyled(Box, ({theme}) => ({}));

function FormControllers (props) {

  const { styled, fixed, children } = props

  if (fixed) {
    return (
      <MyAppBar color="inherit" position="fixed" styled={{style: (styled && styled.appbar) ?? {}}}>
        <MyToolbar styled={{style: (styled && styled.toolbar) ?? {}}}>
          <MyContainer maxWidth="sm" styled={{style: (styled && styled.container) ?? {}}}>
            { children }
          </MyContainer>
        </MyToolbar>
      </MyAppBar>
    )
  }
  return (
    <MyBox styled={{style: (styled && styled.container) ?? {}}}>
      { children }
    </MyBox>
  )
}

FormControllers.propTypes = {
  fixed: PropTypes.bool,
};

FormControllers.defaultProps = {
  fixed: false,
};

export default FormControllers;
