import { Fragment } from 'react'
import PropTypes from 'prop-types'
import usersService from './../../services/users'

import ListItemContent from './../commons/ListItemContent'
import ListItemContentSummary1 from './../commons/ListItemContentSummary1'
import ListItemContentSummary2 from './../commons/ListItemContentSummary2'
import ListItemContentSummary3 from './../commons/ListItemContentSummary3'

function UsersListItem (props) {

  const { user, ...others } = props

  const userAvatar = usersService.getAvatar(user)

  return (
    <ListItemContent
      avatar={userAvatar.content}
      avatarProps={userAvatar.props}
      summary={(
        <Fragment>
          <ListItemContentSummary1>
            #{user.ID} {user.name}
          </ListItemContentSummary1>
          { user.contact && user.contact.address_city && (
            <ListItemContentSummary2>
              { user.contact.address_city }
            </ListItemContentSummary2>
          ) }
          { user.email && (
            <ListItemContentSummary3>
              { user.email }
            </ListItemContentSummary3>
          ) }
        </Fragment>
      )}
      {...others}
    />
  )
}

UsersListItem.propTypes = {
  user: PropTypes.object,
};

export default UsersListItem
