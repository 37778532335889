import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './services/store'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'
import { frFR } from '@mui/material/locale';
import { BrowserRouter } from "react-router-dom";

import AppUpdateController from './components/main/AppUpdateController';
import WebPushNotificationsController from './components/main/WebPushNotificationsController';
import CssBaseline from '@mui/material/CssBaseline';

// Date provider
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import frLocale from 'date-fns/locale/fr';

import Main from './views/Main';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: parseInt(process.env.REACT_APP_QUERY_STALE_TIME_DEFAULT),
      retry: false,
    },
  }
})

const colors = {
  purple_light: '#793ece',
  purple: '#6821b8',
  purple_dark: '#401289',
  blue_light: '#42a5f5',
  blue: '#1976d2',
  blue_dark: '#1565c0',
  yellow: '#f7ca02',
  orange: '#fa7832',
  green: '#25cc8c',
  red: '#e81a1a',
  black: '#000000',
  white: '#ffffff',
  grey: '#555555',
  grey_dark: '#222222',
  grey_medium: '#aaaaaa',
  grey_light: '#cccccc'
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.purple,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.grey,
    },
    tiertiary: {
      main: colors.grey_light,
      contrastText: colors.black,
    },
    black: {
      main: colors.black,
    },
    white: {
      main: colors.white,
    },
    info: {
      main: colors.blue,
      dark: colors.blue_dark,
    },
    success: {
      main: colors.green,
      contrastText: colors.white,
    },
    warning: {
      main: colors.yellow,
    },
    danger: {
      main: colors.orange,
      contrastText: colors.white,
    },
    error: {
      main: colors.red,
      contrastText: colors.white,
    },
    bg: {
      white: colors.white,
      main: '#e1e1e1',
      light: '#f1f1f1',
      dark: '#b4b4b4',
    },
    divider: colors.purple,
    /****** Project state ******/
    project_state_initial: {
      main: colors.grey_medium,
      contrastText: colors.white,
    },
    project_state_validated: {
      main: colors.yellow,
      contrastText: colors.black,
    },
    project_state_processing: {
      main: colors.blue,
      contrastText: colors.white,
    },
    project_state_done: {
      main: colors.green,
      contrastText: colors.white,
    },
    project_state_closed: {
      main: colors.black,
      contrastText: colors.white,
    },
    /****** Document state ******/
    document_state_draft: {
      main: colors.grey_medium,
      contrastText: colors.white,
    },
    document_state_validated: {
      main: colors.green,
      contrastText: colors.white,
    },
    /****** Document Endorsment ******/
    document_endorsment_state_pending: {
      main: colors.grey_medium,
      contrastText: colors.white,
    },
    document_endorsment_state_processing: {
      main: colors.blue,
      contrastText: colors.white,
    },
    document_endorsment_state_refused: {
      main: colors.red,
      contrastText: colors.white,
    },
    document_endorsment_state_expired: {
      main: colors.red,
      contrastText: colors.white,
    },
    document_endorsment_state_deleted: {
      main: colors.red,
      contrastText: colors.white,
    },
    document_endorsment_state_done: {
      main: colors.green,
      contrastText: colors.white,
    },
    document_endorsment_state_error: {
      main: colors.red,
      contrastText: colors.white,
    },
    /****** Document Endorsment Member ******/
    document_endorsment_member_state_pending: {
      main: colors.grey_medium,
      contrastText: colors.white,
    },
    document_endorsment_member_state_processing: {
      main: colors.blue,
      contrastText: colors.white,
    },
    document_endorsment_member_state_refused: {
      main: colors.red,
      contrastText: colors.white,
    },
    document_endorsment_member_state_done: {
      main: colors.green,
      contrastText: colors.white,
    },
    /****** Support Ticket state ******/
    support_ticket_state_opened: {
      main: colors.blue,
      contrastText: colors.white,
    },
    support_ticket_state_closed: {
      main: colors.green,
      contrastText: colors.white,
    },
    /****** Notification state ******/
    notification_state_unread: {
      main: colors.blue,
      contrastText: colors.white,
    },
    notification_state_error: {
      main: colors.red,
      contrastText: colors.white,
    },
    mode: 'light',
  },
  shape: {
    // borderRadius: 0,
  },
  spacing: 10,
  typography: {
    h1: {
      fontSize: '1.875rem',
      letterSpacing: '-0.021em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      letterSpacing: '-0.019em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.25rem',
      letterSpacing: '-0.017em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      letterSpacing: '-0.014em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.125rem',
      letterSpacing: '-0.014em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
    },
    h6: {
      fontSize: '1rem',
      letterSpacing: '-0.011em',
      lineHeight: 1.4,
      marginBottom: '0.6em',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '-0.006em',
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0',
    },
    subtitle3: {
      fontSize: '0.625rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.01em',
    },
    body1: {
      fontSize: '1rem',
      letterSpacing: '-0.011em',
      lineHeight: 1.5,
      hyphens: 'auto',
    },
    body2: {
      fontSize: '0.875rem',
      letterSpacing: '0',
      lineHeight: 1.5,
      hyphens: 'auto',
    },
    body3: {
      fontSize: '0.75rem',
      letterSpacing: '0',
      lineHeight: 1.5,
      hyphens: 'auto',
    },
    button: {
      fontWeight: 400,
      textTransform: 'none',
    },
    caption: {
      lineHeight: 1.6,
      letterSpacing: '0em',
    },
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  },
}, frFR);

export default function App () {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                <AppUpdateController>
                  <BrowserRouter>
                    <WebPushNotificationsController>
                      <CssBaseline />
                      <Main />
                    </WebPushNotificationsController>
                  </BrowserRouter>
                </AppUpdateController>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}
