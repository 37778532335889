import { useState } from 'react'
import PropTypes from 'prop-types'
import { getStyled } from './../../services/styles'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import supportTicketsService from './../../services/supportTickets'

import BackdropLoading from './../commons/BackdropLoading'
import Form from './../commons/Form'
import FormFields from './../commons/FormFields'
import FormField from './../commons/FormField'
import FormControllers from './../commons/FormControllers'
import { SaveIcon } from './../commons/Icons'
import SelectUser from '../commons/SelectUser'
import Loading from '../commons/Loading'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

const SubmitButton = getStyled(Button, ({ theme }) => ({
  width: '100%',
}))

function EditSupportTicketTarget(props) {

  const { id, fixedCtrl, onSaved, onError } = props
  
  const [idUser, setIdUser] = useState(null)
  const [selectUserQuery, setSelectUserQuery] = useState({})

  const queryClient = useQueryClient()
  const queryKeys = ['support-tickets', id]

  // Récupération du ticket
  const { isFetching, isError, refetch } = useQuery(queryKeys, async () => {
    return supportTicketsService.get(id).then(([item]) => item)
  }, {
    enabled: !!id,
    staleTime: 0, // IMPORTANT : on garde toujours un staleTime à 0 car en édition on doit avoir des données fraiches (de plus sinon cela génère un bug de rafraichissement des données dans react-query)
    onSuccess: (item) => {
      setIdUser(item.id_user)
      setSelectUserQuery({
        id_project: item.id_project // on ne liste que les utilisateurs du projet du ticket
      })
    },
    onError
  })

  // Mutation (create/update)
  const { isLoading: isMutating, mutate } = useMutation((data) => {
    return supportTicketsService.setTarget(id, idUser)
  }, {
    onSuccess: ([id, notices]) => {
      notices && notices.length > 0 && onError(notices)
      queryClient.invalidateQueries(queryKeys)
      onSaved(id)
    },
    onError
  })

  // Sauvegarde
  const save = (e) => {
    e.preventDefault()
    mutate()
    return false
  }

  if (isFetching || isError) {
    return <Loading linear={true} error={isError} refetch={refetch} />
  }

  return (
    <Box>
      <BackdropLoading open={isMutating} />

      <Form onSubmit={(e) => save(e)} noValidate>

        <FormFields>

          <FormField>
            <SelectUser
              title="Sélectionner un utilisateur"
              query={selectUserQuery}
              onQueryChange={(query) => setSelectUserQuery(query)}
              value={idUser}
              onChange={ (value) => setIdUser(value) }
              onError={onError}
            />
          </FormField>

          <FormControllers fixed={fixedCtrl}>
            <SubmitButton type="submit" onClick={(e) => save(e)} color="primary" variant="contained" startIcon={<SaveIcon />}>
              Assigner
            </SubmitButton>
          </FormControllers>

        </FormFields>

      </Form>
    </Box>
  )
}

EditSupportTicketTarget.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSaved: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  fixedCtrl: PropTypes.bool,
};

EditSupportTicketTarget.defaultProps = {
  fixedCtrl: false,
};

export default EditSupportTicketTarget;
